import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import React from 'react'
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid'
import { Container } from 'react-bootstrap'

export function PromoCodeTable({ promoCodes }) {
  const dataSource = new DataSource({
    store: new ArrayStore({
      type: 'array',
      key: 'promoCodeId',
      data: promoCodes,
    }),
    reshapeOnPush: true,
  })

  return (
    <Container>
      <DataGrid
        dataSource={dataSource}
        repaintChangesOnly={true}
        columnAutoWidth={true}
        showBorders={true}
        remoteOperations={{
          paging: true,
        }}
      >
        <Column dataField={'promoCodeId'} />
        <Column dataField={'promoCode'} />
        <Column dataField={'customerId'} />
        <Column dataField={'active'} />
        <Column
          dataField={'expiration'}
          dataType={'date'}
          format={'yyyy/MM/dd'}
        />
        <Paging defaultPageSize={50} />
        <Pager tot visible showPageSizeSelector={false} showInfo={true} />
      </DataGrid>
    </Container>
  )
}
