import React, { useCallback, useEffect, useState } from 'react'
import DataGrid, { Column } from 'devextreme-react/data-grid'
import CustomStore from 'devextreme/data/custom_store'
import { Modal } from 'react-bootstrap'

import { getUserSecurityGroups } from '../../services/security_groups'
import SecurityGroupForm from '../users/security_group_form'

export default function SecurityGroups({
  user,
  securityGroups,
  getSecurityGroupsAll,
  getSecurityGroupsByUser,
  addUserToSecurityGroup,
  removeUserFromSecurityGroup,
}) {
  const getSecurityGroups = useCallback(() => {
    return {
      store: new CustomStore({
        load: function () {
          return getUserSecurityGroups(user.user.userId)
        },
      }),
    }
  }, [user.user.userId])

  const [availableSecurityGroups, setAvailableSecurityGroups] = useState([])
  const [userSecurityGroups, setUserSecurityGroups] =
    useState(getSecurityGroups())
  const [showUserSecurityGroupForm, setShowUserSecurityGroupForm] =
    useState(false)
  const [submitting, setSubmitting] = useState(false)

  const createUserSecurityGroup = useCallback(
    (formData) => {
      setSubmitting(true)
      addUserToSecurityGroup(user.user.userId, formData.securityGroupId)
    },
    [user.user.userId, addUserToSecurityGroup]
  )

  const removeUserSecurityGroup = useCallback(
    ({
      row: {
        data: { sgid },
      },
    }) => {
      removeUserFromSecurityGroup(user.user.userId, sgid)
    },
    [user.user.userId, removeUserFromSecurityGroup]
  )

  const toggleUserSecurityGroupForm = useCallback(() => {
    setShowUserSecurityGroupForm(!showUserSecurityGroupForm)
  }, [showUserSecurityGroupForm])

  useEffect(() => {
    setShowUserSecurityGroupForm(false)
    setUserSecurityGroups(getSecurityGroups())
    setSubmitting(false)
  }, [securityGroups.byUser, getSecurityGroups])

  useEffect(() => {
    getSecurityGroupsAll()
  }, [getSecurityGroupsAll])

  useEffect(() => {
    getSecurityGroupsByUser(user.user.userId)
  }, [user.user.userId, getSecurityGroupsByUser])

  useEffect(() => {
    const userSecurityGroupIds = (
      securityGroups.byUser[user.user.userId] || []
    ).map(({ sgid }) => sgid)

    if (securityGroups.all) {
      setAvailableSecurityGroups(
        securityGroups.all.filter(
          ({ sgid }) => !userSecurityGroupIds.includes(sgid)
        )
      )
    }
  }, [user.user.userId, securityGroups])

  return (
    <>
      <button
        className="btn btn-primary mb-3"
        onClick={toggleUserSecurityGroupForm}
      >
        Add User to Security Group
      </button>
      <DataGrid dataSource={userSecurityGroups} showBorders>
        <Column dataField="insertDate" dataType="date" format="yyyy/MM/dd" />
        <Column dataField="name" />
        <Column
          type="buttons"
          buttons={[
            {
              hint: 'Remove',
              text: 'Remove',
              onClick: removeUserSecurityGroup,
            },
          ]}
        />
      </DataGrid>

      <Modal
        show={showUserSecurityGroupForm}
        onHide={toggleUserSecurityGroupForm}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create User Security Group</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={toggleUserSecurityGroupForm}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <SecurityGroupForm
              availableSecurityGroups={availableSecurityGroups}
              onSubmit={createUserSecurityGroup}
              isSubmitting={submitting}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}
