import { Field, reduxForm } from 'redux-form'
import React from 'react'

let PersonalInfoForm = (props) => {
  const { handleSubmit, submitting } = props
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <Field
          name="email"
          placeholder="Email"
          component="input"
          type="text"
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="firstName">First Name</label>
        <Field
          name="firstName"
          placeholder="First Name"
          component="input"
          type="text"
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="lastName">Last Name</label>
        <Field
          name="lastName"
          placeholder="Last Name"
          component="input"
          type="text"
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="phone">Phone Number</label>
        <Field
          name="phone"
          placeholder="Phone Number"
          component="input"
          type="text"
          maxLength={25}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="additionalInfo">Additional Info</label>
        <Field
          name="additionalInfo"
          placeholder="Additional Info"
          component="input"
          type="text"
          className="form-control"
          maxLength={255}
        />
      </div>
      <button className="btn btn-primary" disabled={submitting}>
        {submitting ? 'Updating' : 'Update'}
      </button>
    </form>
  )
}

PersonalInfoForm = reduxForm({
  form: 'personalInfoForm',
})(PersonalInfoForm)

export default PersonalInfoForm
