import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import React from 'react'
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid'

export function FreeAccountsTable({ freeAccounts, onEdit }) {
  const dataSource = new DataSource({
    store: new ArrayStore({
      type: 'array',
      key: 'customerId',
      data: freeAccounts || [],
    }),
    reshapeOnPush: true,
  })

  return (
    <React.Fragment>
      <h4>Free Accounts</h4>
      <DataGrid
        dataSource={dataSource}
        repaintChangesOnly={true}
        columnAutoWidth={false}
        showBorders={true}
        remoteOperations={{
          paging: true,
        }}
      >
        <Column dataField={'customerId'} width={80} />
        <Column dataField={'customerName'} width={140} />
        <Column dataField={'customerEmail'} />
        <Column dataField={'customerType'} width={80} />
        <Column dataField={'customerDescription'} />
        <Column
          dataField={'expiration'}
          dataType={'date'}
          format={'yyyy/MM/dd'}
          width={80}
        />
        <Column dataField={'staffId'} caption="Owner ID" width={80} />
        <Column dataField={'staffEmail'} caption="Owner Email" />
        <Column
          type={'buttons'}
          buttons={[
            {
              hint: 'Edit',
              text: 'Edit',
              onClick: onEdit,
            },
          ]}
          width={60}
        />
        <Paging defaultPageSize={100} />
        <Pager
          tot
          visible
          allowedPageSizes={[25, 50, 100, 250, 500, 1000, 2000]}
          showPageSizeSelector={true}
          showInfo={true}
        />
      </DataGrid>
    </React.Fragment>
  )
}
