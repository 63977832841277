import auth from './authReducer'
import authToken from './authTokenReducer'
import banner from './bannerReducer'
import crate from './crateReducer'
import downloadHistory from './downloadHistoryReducer'
import feature from './featureReducer'
import freeAccounts from './freeAccountsReducer'
import localization from './localizationReducer'
import pages from './pageReducer'
import promos from './promoReducer'
import promoCodes from './promoCodeReducer'
import securityGroups from './securityGroupsReducer'
import user from './userReducer'
import users from './usersReducer'

const reducers = {
  auth,
  authToken,
  banner,
  crate,
  downloadHistory,
  feature,
  freeAccounts,
  localization,
  pages,
  promoCodes,
  promos,
  securityGroups,
  user,
  users,
}

export default reducers
