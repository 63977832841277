import * as CacheService from '../services/cache'
import { createAction } from '@reduxjs/toolkit'
import { handleActionError, handleActionPayload } from './utils'
import { toast } from 'react-toastify'
import { NOTIFICATIONS } from '../helpers/utils'
export const CACHE_ACTIONS = {
  REQUEST_CLEAR_CACHE: 'REQUEST_CLEAR_CACHE',
  RECEIVE_CLEAR_CACHE: 'RECEIVE_CLEAR_CACHE',
  ERROR_CLEAR_CACHE: 'ERROR_CLEAR_CACHE',
}

// GET BANNERS
const clearingCache = createAction(CACHE_ACTIONS.REQUEST_CLEAR_CACHE)
const clearedCache = createAction(
  CACHE_ACTIONS.RECEIVE_CLEAR_CACHE,
  handleActionPayload
)
const errorClearingCache = createAction(
  CACHE_ACTIONS.ERROR_CLEAR_CACHE,
  handleActionError
)

export function clearCache(key) {
  return (dispatch) => {
    dispatch(clearingCache())
    return CacheService.clearCache(key)
      .then((response) => {
        toast.success(`${key ? key : 'Full'} cache has been cleared.`, {
          containerId: NOTIFICATIONS.BANNER_NOTIFICATION,
        })
        return dispatch(clearedCache({ message: response.message }))
      })
      .catch((error) => {
        toast.error(error.message, {
          containerId: NOTIFICATIONS.BANNER_NOTIFICATION,
        })
        return dispatch(errorClearingCache(error))
      })
  }
}
