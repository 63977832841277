let defaultState = {
  loading: false,
  userInfo: {},
}

const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        loading: false,
        userInfo: action.auth.user,
      }

    case 'GET_USER_INFO':
      return {
        loading: false,
        userInfo: action.auth,
      }

    case 'REQUEST_USER_INFO':
      return {
        ...state,
        loading: true,
      }

    case 'REQUEST_LOGIN':
      return {
        ...state,
        loading: true,
      }

    case 'LOGOUT':
      return {
        loading: false,
        userInfo: {},
      }

    default:
      return state
  }
}

export default authReducer
