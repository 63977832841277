import { createAction } from '@reduxjs/toolkit'
import * as SongService from '../services/song'

export const DOWNLOAD_HISTORY_ACTIONS = {
  // Download history
  REQUEST_DOWNLOAD_HISTORY: 'REQUEST_DOWNLOAD_HISTORY',
  RECEIVE_DOWNLOAD_HISTORY: 'RECEIVE_DOWNLOAD_HISTORY',
  ERROR_RECEIVING_DOWNLOAD_HISTORY: 'ERROR_RECEIVING_DOWNLOAD_HISTORY',

  // Reset download count
  REQUEST_RESET_DOWNLOAD_COUNT: 'REQUEST_RESET_DOWNLOAD_COUNT',
  RECEIVE_RESET_DOWNLOAD_COUNT: 'REQUEST_RESET_DOWNLOAD_COUNT',
  ERROR_RESETTING_DOWNLOAD_COUNT: 'ERROR_RESETTING_DOWNLOAD_COUNT',
}

// Download history actions
const requestDownloadHistory = createAction(
  DOWNLOAD_HISTORY_ACTIONS.REQUEST_DOWNLOAD_HISTORY
)
const receiveDownloadHistory = createAction(
  DOWNLOAD_HISTORY_ACTIONS.RECEIVE_DOWNLOAD_HISTORY
)
const errorReceivingDownloadHistory = createAction(
  DOWNLOAD_HISTORY_ACTIONS.ERROR_RECEIVING_DOWNLOAD_HISTORY
)

export function getDownloadHistory(user, page, pageSize, name = undefined) {
  return async (dispatch) => {
    dispatch(requestDownloadHistory())
    try {
      const result = await SongService.getSongDownloadHistory(
        user,
        page,
        pageSize,
        name,
        false
      )
      dispatch(receiveDownloadHistory(result))

      return result
    } catch (error) {
      return dispatch(errorReceivingDownloadHistory(error))
    }
  }
}

export function getDownloadHistoryXLSX(user) {
  return async () => {
    const result = await SongService.getSongDownloadHistory(
      user,
      1,
      50,
      null,
      true,
      'xlsx'
    )
    const blob = await result.blob()
    const blobUrl = window.URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.style.display = 'none'
    a.download = 'DownloadHistory.xlsx'
    a.href = blobUrl
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(blobUrl)
  }
}

// Reset download action
const requesResetDownloadCount = createAction(
  DOWNLOAD_HISTORY_ACTIONS.REQUEST_RESET_DOWNLOAD_COUNT
)
const receiveResetDownloadCount = createAction(
  DOWNLOAD_HISTORY_ACTIONS.RECEIVE_RESET_DOWNLOAD_COUNT
)
const errorResettingDownloadCount = createAction(
  DOWNLOAD_HISTORY_ACTIONS.ERROR_RESETTING_DOWNLOAD_COUNT
)

export function resetDownloadCount(user, rid, ttid) {
  return async (dispatch) => {
    dispatch(requesResetDownloadCount())
    try {
      const result = await SongService.resetSongDownloadCount(user, rid, ttid)
      dispatch(receiveResetDownloadCount())

      return result
    } catch (error) {
      return dispatch(errorResettingDownloadCount(error))
    }
  }
}
