import * as React from 'react'

const Input = ({
  input,
  disabled,
  label,
  step,
  type,
  className,
  inputClassName,
  placeholder,
  meta: { touched, error },
}) => (
  <div className={className}>
    {label && <label>{label}</label>}
    <div>
      <div className="form-group">
        <input
          step={step}
          disabled={disabled}
          {...input}
          placeholder={placeholder || label}
          type={type}
          className="form-control"
        />
        {touched && error && (
          <span className="text-danger text-sm-left">{error}</span>
        )}
      </div>
    </div>
  </div>
)

export default Input
