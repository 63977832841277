import { CRATE_ACTIONS } from '../actions/crate'

const defaultState = {
  items: [],
  pageSize: 10,
  skipItems: 0,
  totalItems: 0,
  loading: false,
}

function crateReducer(state = defaultState, { type, payload }) {
  switch (type) {
    case CRATE_ACTIONS.REQUEST_CRATE:
      return { ...state, loading: true }
    case CRATE_ACTIONS.RECEIVE_CRATE:
      return {
        items: payload.items,
        pageSize: payload.pageSize,
        skipItems: payload.skipItems,
        totalItems: payload.totalItems,
        loading: false,
      }
    case CRATE_ACTIONS.ERROR_RECEIVING_CRATE:
      return { ...defaultState }
    default:
      return state
  }
}

export default crateReducer
