import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

export function useQueryParameters(defaultParams = {}) {
  const history = useHistory()
  const { search, pathname } = useLocation()
  const queryParams = useMemo(
    () => ({
      ...defaultParams,
      ...queryString.parse(search),
    }),
    [defaultParams, search]
  )

  const setQueryParams = useCallback(
    (params) => {
      const newParams = { ...defaultParams, ...params }
      Object.keys(defaultParams).forEach((key) => {
        if (String(newParams[key]) === defaultParams[key]) {
          delete newParams[key]
        }
      })

      history.push(`${pathname}?${queryString.stringify(newParams)}`)
    },
    [defaultParams, pathname, history]
  )

  return [queryParams, setQueryParams]
}
