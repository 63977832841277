import {
  getAllSecurityGroups,
  getUserSecurityGroups,
  createUserSecurityGroup,
  removeUserSecurityGroup,
} from '../services/security_groups'

export const SECURITY_GROUP_ACTIONS = {
  // VIEW
  REQUEST_SECURITY_GROUPS: 'REQUEST_SECURITY_GROUPS',
  RECEIVE_SECURITY_GROUPS_SUCCESS: 'RECEIVE_SECURITY_GROUPS_SUCCESS',
  RECEIVE_SECURITY_GROUPS_ERROR: 'RECEIVE_SECURITY_GROUPS_ERROR',
  REQUEST_USER_SECURITY_GROUPS: 'REQUEST_USER_SECURITY_GROUPS',
  RECEIVE_USER_SECURITY_GROUPS_SUCCESS: 'RECEIVE_USER_SECURITY_GROUPS_SUCCESS',
  RECEIVE_USER_SECURITY_GROUPS_ERROR: 'RECEIVE_USER_SECURITY_GROUPS_ERROR',
  // CREATE
  REQUEST_USER_SECURITY_GROUP_CREATE: 'REQUEST_USER_SECURITY_GROUP_CREATE',
  RECEIVE_USER_SECURITY_GROUP_CREATE_SUCCESS:
    'RECEIVE_USER_SECURITY_GROUP_CREATE_SUCCESS',
  RECEIVE_USER_SECURITY_GROUP_CREATE_ERROR:
    'RECEIVE_USER_SECURITY_GROUP_CREATE_ERROR',
  // DELETE
  REQUEST_USER_SECURITY_GROUP_DELETE: 'REQUEST_USER_SECURITY_GROUP_DELETE',
  RECEIVE_USER_SECURITY_GROUP_DELETE_SUCCESS:
    'RECEIVE_USER_SECURITY_GROUP_DELETE_SUCCESS',
  RECEIVE_USER_SECURITY_GROUP_DELETE_ERROR:
    'RECEIVE_USER_SECURITY_GROUP_DELETE_ERROR',
}

// VIEW
export function requestSecurityGroups() {
  return {
    type: SECURITY_GROUP_ACTIONS.REQUEST_SECURITY_GROUPS,
  }
}

export function receiveSecurityGroups(securityGroups) {
  return {
    type: SECURITY_GROUP_ACTIONS.RECEIVE_SECURITY_GROUPS_SUCCESS,
    securityGroups,
  }
}

export function receiveSecurityGroupsError(error) {
  return {
    type: SECURITY_GROUP_ACTIONS.RECEIVE_SECURITY_GROUPS_ERROR,
    error,
  }
}

export function requestUserSecurityGroups() {
  return {
    type: SECURITY_GROUP_ACTIONS.REQUEST_USER_SECURITY_GROUPS,
  }
}

export function receiveUserSecurityGroups(userId, userSecurityGroups) {
  return {
    type: SECURITY_GROUP_ACTIONS.RECEIVE_USER_SECURITY_GROUPS_SUCCESS,
    userId,
    userSecurityGroups,
  }
}

export function receiveUserSecurityGroupsError(error) {
  return {
    type: SECURITY_GROUP_ACTIONS.RECEIVE_USER_SECURITY_GROUPS_ERROR,
    error,
  }
}

// CREATE
export function requestUserSecurityGroupCreate() {
  return {
    type: SECURITY_GROUP_ACTIONS.REQUEST_USER_SECURITY_GROUP_CREATE,
  }
}

export function receiveUserSecurityGroupCreate(userId, securityGroup) {
  return {
    type: SECURITY_GROUP_ACTIONS.RECEIVE_USER_SECURITY_GROUP_CREATE_SUCCESS,
    userId,
    securityGroup,
  }
}

export function receiveUserSecurityGroupCreateError(error) {
  return {
    type: SECURITY_GROUP_ACTIONS.RECEIVE_USER_SECURITY_GROUP_CREATE_ERROR,
    error,
  }
}

// DELETE
export function requestUserSecurityGroupDelete() {
  return {
    type: SECURITY_GROUP_ACTIONS.REQUEST_USER_SECURITY_GROUP_DELETE,
  }
}

export function receiveUserSecurityGroupDelete(userId, securityGroupId) {
  return {
    type: SECURITY_GROUP_ACTIONS.RECEIVE_USER_SECURITY_GROUP_DELETE_SUCCESS,
    userId,
    securityGroupId,
  }
}

export function receiveUserSecurityGroupDeleteError(error) {
  return {
    type: SECURITY_GROUP_ACTIONS.RECEIVE_USER_SECURITY_GROUP_DELETE_ERROR,
    error,
  }
}

// GET
export function getSecurityGroupsAll() {
  return async (dispatch) => {
    dispatch(requestSecurityGroups())
    try {
      const securityGroups = await getAllSecurityGroups()
      dispatch(receiveSecurityGroups(securityGroups))
    } catch (error) {
      dispatch(receiveSecurityGroupsError(error))
    }
  }
}

export function getSecurityGroupsByUser(userId) {
  return async (dispatch) => {
    dispatch(requestUserSecurityGroups())
    try {
      const userSecurityGroups = await getUserSecurityGroups(userId)
      dispatch(receiveUserSecurityGroups(userId, userSecurityGroups))
    } catch (error) {
      dispatch(receiveUserSecurityGroupsError(error))
    }
  }
}

// POST
export function addUserToSecurityGroup(userId, securityGroupId) {
  return async (dispatch) => {
    dispatch(requestUserSecurityGroupCreate())
    try {
      const securityGroup = await createUserSecurityGroup(
        userId,
        securityGroupId
      )
      dispatch(receiveUserSecurityGroupCreate(userId, securityGroup))
    } catch (error) {
      dispatch(receiveUserSecurityGroupCreateError())
    }
  }
}

// DELETE
export function removeUserFromSecurityGroup(userId, securityGroupId) {
  return async (dispatch) => {
    dispatch(requestUserSecurityGroupDelete())
    try {
      await removeUserSecurityGroup(userId, securityGroupId)
      dispatch(receiveUserSecurityGroupDelete(userId, securityGroupId))
    } catch (error) {
      dispatch(receiveUserSecurityGroupDeleteError())
    }
  }
}
