import _ from 'lodash'

const defaultState = {
  users: null,
  loading: false,
}

const usersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'REQUEST_USERS':
      return {
        ...state,
        loading: true,
      }
    case 'RECEIVE_USERS':
      return {
        ...state,
        users: action.users,
        loading: false,
      }
    case 'UPDATED_FEATURE_USERS':
      return {
        ...state,
        users: state.users
          ? state.users.map((user) => {
              if (action.userIds.includes(user.userId)) {
                return {
                  features: user.features.push(action.featureId),
                  ...user,
                }
              }
              return user
            })
          : null,
      }
    case 'UPDATED_USER_FEATURES':
      return {
        ...state,
        users: state.users
          ? state.users.map((user) => {
              if (action.userId === user.userId) {
                return {
                  features: _.pull(user.features, action.featureId),
                  ...user,
                }
              }
              return user
            })
          : null,
      }
    default:
      return state
  }
}

export default usersReducer
