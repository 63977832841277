import React from 'react'
import DataGrid, { Column, Paging } from 'devextreme-react/data-grid'
import ArrayStore from 'devextreme/data/array_store'
import DataSource from 'devextreme/data/data_source'
import PropTypes from 'prop-types'

class FeatureTableComponent extends React.Component {
  constructor(props) {
    super(props)
    this.getFeatures = this.getFeatures.bind(this)
  }

  getFeatures() {
    const { features } = this.props
    return new DataSource({
      store: new ArrayStore({
        type: 'array',
        key: 'featureId',
        data: features,
      }),
      reshapeOnPush: true,
    })
  }

  render() {
    const { loading, editFeature } = this.props
    return (
      <React.Fragment>
        <h4>Features</h4>
        {!loading && (
          <DataGrid
            dataSource={this.getFeatures()}
            repaintChangesOnly={true}
            columnAutoWidth={true}
            showBorders={true}
          >
            <Paging pageSize={20} />
            <Column
              dataField={'appName'}
              caption={'App'}
              groupIndex={0}
              dataType={'string'}
            />
            <Column dataField={'name'} dataType={'string'} />
            <Column dataField={'enabledForEveryone'} dataType={'boolean'} />
            <Column
              type={'buttons'}
              buttons={[
                {
                  hint: 'Edit',
                  text: 'Edit',
                  onClick: editFeature,
                },
              ]}
            />
          </DataGrid>
        )}
      </React.Fragment>
    )
  }
}

FeatureTableComponent.propTypes = {
  features: PropTypes.any,
  loading: PropTypes.bool,
  editFeature: PropTypes.func,
}

export default FeatureTableComponent
