import React from 'react'
import DataGrid, {
  Column,
  Paging,
  RemoteOperations,
  Scrolling,
} from 'devextreme-react/data-grid'
import CustomStore from 'devextreme/data/custom_store'
import PropTypes from 'prop-types'
import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'

class PageTableComponent extends React.Component {
  constructor(props) {
    super(props)

    this.handleOnDelete = this.handleOnDelete.bind(this)
    this.handleOnEdit = this.handleOnEdit.bind(this)
    this.getPages = this.getPages.bind(this)
    this.load = this.load.bind(this)
  }

  load(loadOptions) {
    return this.props.getPages(loadOptions)
  }

  getPages() {
    return (this.dataSource = {
      store: new CustomStore({
        load: this.load,
      }),
    })
  }

  handleOnDelete(event) {
    this.props.deletePage(event.row.data.id)
  }

  handleOnEdit(event) {
    this.props.editPage(event.row.data.id)
  }

  getDataSource() {
    const { pages } = this.props
    return new DataSource({
      store: new ArrayStore({
        type: 'array',
        key: 'id',
        data: pages,
      }),
      reshapeOnPush: true,
    })
  }

  render() {
    return (
      <React.Fragment>
        <h4>Pages</h4>
        <DataGrid
          dataSource={this.getDataSource()}
          repaintChangesOnly={true}
          columnAutoWidth={true}
          showBorders={true}
        >
          <Scrolling mode={'infinite'} />
          <Paging pageSize={20} />
          <Column dataField={'name'} />
          <Column dataField={'url'} />
          <Column dataField={'external_link'} />
          <Column
            type={'buttons'}
            buttons={[
              {
                hint: 'Edit',
                text: 'Edit',
                onClick: this.handleOnEdit,
              },
            ]}
          />
          <Column
            type={'buttons'}
            buttons={[
              {
                hint: 'Delete',
                text: 'Delete',
                onClick: this.handleOnDelete,
              },
            ]}
          />
        </DataGrid>
        <RemoteOperations sorting={false} paging={false} />
      </React.Fragment>
    )
  }
}

PageTableComponent.propTypes = {
  editPage: PropTypes.func,
  deletePage: PropTypes.func,
}

export default PageTableComponent
