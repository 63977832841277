import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import styles from './styles.module.sass'

export const NO_SORTING = 0
export const SORT_ASCENDING = 1
export const SORT_DESCENDING = 2

export const TableHeader = ({
  children,
  className,
  sort = NO_SORTING,
  onClick,
}) => {
  const HeaderComponent = onClick ? 'button' : 'span'

  let sortIcon
  switch (sort) {
    case SORT_ASCENDING:
      sortIcon = '▲'
      break

    case SORT_DESCENDING:
      sortIcon = '▼'
      break

    default:
      sortIcon = null
      break
  }

  const clickCallback = useCallback(() => {
    onClick((sort + 1) % 3)
  }, [onClick, sort])

  return (
    <HeaderComponent
      className={classNames(styles.tableHeader, className, {
        [styles.clickable]: onClick,
      })}
      onClick={onClick ? clickCallback : null}
    >
      {children}
      {sortIcon}
    </HeaderComponent>
  )
}

TableHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  sort: PropTypes.oneOf([SORT_ASCENDING, NO_SORTING, SORT_DESCENDING]),
}
