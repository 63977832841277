import { PROMO_ACTIONS } from '../actions/promos'
import * as _ from 'lodash'

let promosState = {
  data: [],
  error: null,
  isFetching: false,
}

const promosReducer = (state = promosState, action) => {
  switch (action.type) {
    case PROMO_ACTIONS.REQUEST_PROMOS:
      return {
        ...state,
        isFetching: true,
      }
    case PROMO_ACTIONS.RECEIVE_PROMOS:
      return {
        ...state,
        isFetching: false,
        data: _.keyBy(action.payload, 'id'),
      }
    case PROMO_ACTIONS.UPDATED_PROMO:
      return {
        ...state,
        isFetching: false,
        data: _.keyBy(action.payload, 'id'),
      }
    default:
      return state
  }
}
export default promosReducer
