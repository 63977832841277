import React, { useState } from 'react'
import { Field, reduxForm } from 'redux-form'
import { isProd } from '../../helpers/utils'

const LocalizationForm = reduxForm({
  form: 'localeForm',
})((props) => {
  const noop = () => {}
  const { handleSubmit, locales, submitting, nameLocked, isReadyForProd } =
    props
  const items = locales.map((locale) => {
    return (
      <div className="form-group">
        <label htmlFor={locale}>{locale}</label>
        <Field
          name={locale}
          component="input"
          type="text"
          className="form-control"
          contentEditable={!isProd()}
        />
      </div>
    )
  })
  const [isReady, setIsReady] = useState(isReadyForProd)
  const handleCheck = () => setIsReady(!isReady)
  return (
    <form onSubmit={isProd() ? noop : handleSubmit}>
      <div className="form-group">
        <label htmlFor="name">Name</label>
        <Field
          readOnly={nameLocked}
          name="name"
          placeholder="djcity."
          component="input"
          type="text"
          className="form-control"
        />
      </div>
      {items}
      {!isProd() && (
        <div className="form-group">
          <label htmlFor="isReadyForProd" className="space">
            Ready for Prod
          </label>
          <Field
            name="isReadyForProd"
            component="input"
            type="checkbox"
            checked={isReady}
            onChange={handleCheck}
          />
        </div>
      )}
      <button className="btn btn-primary" disabled={isProd() || submitting}>
        {submitting ? 'Updating' : 'Submit'}
      </button>
    </form>
  )
})

export default LocalizationForm
