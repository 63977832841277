import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles.module.sass'

export function Pagination({
  className = null,
  label = null,
  onPageSelect = null,
  page,
  totalPages,
}) {
  const nextPage = () => {
    if (page < totalPages && onPageSelect) onPageSelect(page + 1)
  }

  const previousPage = () => {
    if (page > 1 && onPageSelect) onPageSelect(page - 1)
  }

  const lastPage = () => {
    if (onPageSelect) onPageSelect(totalPages)
  }

  const firstPage = () => {
    if (onPageSelect) onPageSelect(1)
  }

  useEffect(() => {
    if (page > totalPages && totalPages > 0 && onPageSelect) {
      onPageSelect(totalPages)
    }
  }, [page, totalPages, onPageSelect])

  return (
    <div className={classNames(styles.pagination, className)}>
      <span className={styles.label}>{label}</span>
      <button className="btn btn-primary" onClick={() => firstPage()}>
        First
      </button>
      <button className="btn btn-primary" onClick={() => previousPage()}>
        Previous
      </button>
      <button className="btn btn-primary" onClick={() => nextPage()}>
        Next
      </button>
      <button className="btn btn-primary" onClick={() => lastPage()}>
        Last
      </button>
    </div>
  )
}

Pagination.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onPageSelect: PropTypes.func,
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
}
