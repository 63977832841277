import React, { useCallback, useState } from 'react'
import { Field, reduxForm } from 'redux-form'
import PropTypes from 'prop-types'
import FieldFileInput from '../input/fieldFileInput'
import { required } from '../input/validators'
import Input from '../input/inputField'
import './style.css'

function BannerDetail({
  handleSubmit,
  initialValues: { baid, mobileAssetUrl, webAssetUrl },
  onDelete,
  submitting = false,
}) {
  const [changeWebImage, setChangeWebImage] = useState(false)
  const [changeMobileImage, setChangeMobileImage] = useState(false)

  const onChangeWebImage = useCallback(
    (e) => {
      e.preventDefault()
      setChangeWebImage(!changeWebImage)
    },
    [changeWebImage, setChangeWebImage]
  )

  const onChangeMobileImage = useCallback(
    (e) => {
      e.preventDefault()
      setChangeMobileImage(!changeMobileImage)
    },
    [changeMobileImage, setChangeMobileImage]
  )

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div>
          <div className="row">
            <div className="col-lg-6">
              <div className="card mb-2">
                <div className="card-header">Web</div>
                <div className="card-body">
                  {baid && !changeWebImage ? (
                    <img
                      alt="Web banner"
                      className={'bannerImage'}
                      src={webAssetUrl}
                    />
                  ) : (
                    <div className="mt-2 mb-2">
                      <Field
                        name="webFile"
                        label="Upload File"
                        component={FieldFileInput}
                        type="file"
                      />
                      <small className="text-sm-left text-muted">
                        Web requirements: WIDTH from 1000 to 3000 x HEIGHT from
                        333 to 999
                      </small>
                      <div>
                        <small className="text-sm-left text-muted">
                          Aspect ratio: 3:1
                        </small>
                      </div>
                    </div>
                  )}
                  {baid && (
                    <button
                      className="mt-2 mb-2 btn btn-info btn-sm btn"
                      onClick={onChangeWebImage}
                    >
                      {changeWebImage ? 'Cancel Change' : 'Change Banner'}
                    </button>
                  )}
                  <div className="form-group">
                    <Field name="active" component="input" type="checkbox" />
                    <label htmlFor="active">Active</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card mb-2">
                <div className="card-header">Mobile</div>
                <div className="card-body">
                  {baid && !changeMobileImage ? (
                    <img
                      alt="Mobile banner"
                      className={'bannerImage'}
                      src={mobileAssetUrl}
                    />
                  ) : (
                    <div className="mt-2 mb-2">
                      <Field
                        name="mobileFile"
                        label="Upload File"
                        component={FieldFileInput}
                        type="file"
                      />
                      <small className="text-sm-left text-muted">
                        Mobile requirements: WIDTH from 444 to 888 x HEIGHT from
                        253 to 506
                      </small>
                      <div>
                        <small className="text-sm-left text-muted">
                          Aspect ratio: 1,75:1
                        </small>
                      </div>
                    </div>
                  )}
                  {baid && (
                    <button
                      className="mt-2 mb-2 btn btn-info btn-sm btn"
                      onClick={onChangeMobileImage}
                    >
                      {changeMobileImage ? 'Cancel Change' : 'Change Banner'}
                    </button>
                  )}
                  <div className="form-group">
                    <Field
                      name="mobileActive"
                      component="input"
                      type="checkbox"
                    />
                    <label htmlFor="mobileActive">Mobile Active</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Field
            label={'Alt Tag'}
            name="altTag"
            placeholder=""
            component={Input}
            type="text"
            validate={[required]}
          />
          <div className="form-group">
            <Field name="targetBlank" component="input" type="checkbox" />
            <label htmlFor="targetBlank">&nbsp;Open In New Window</label>
          </div>
          <button className="btn btn-primary" disabled={submitting}>
            {submitting ? 'Updating' : 'Submit'}
          </button>
        </div>
      </form>
      {baid && (
        <button
          className="mt-2 btn btn-outline-danger"
          disabled={submitting}
          onClick={onDelete}
        >
          {submitting ? 'Deleting' : 'Delete'}
        </button>
      )}
    </>
  )
}

const BannerDetailForm = reduxForm({
  form: 'bannerDetailForm',
})(BannerDetail)

BannerDetailForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  onDelete: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
}

export default BannerDetailForm
