import React from 'react'

import { Field, reduxForm } from 'redux-form'
import { AVAILABLE_REGIONS } from '../../helpers/utils'
import { DatePickerInput } from '../input/datePickerInput'

let BannerRegionForm = (props) => {
  const { handleSubmit, submitting } = props
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="regionId">Region</label>
        <Field name="regionId" component="select" className="form-control">
          <option></option>
          {Object.keys(AVAILABLE_REGIONS).map((value) => {
            return <option value={value}>{AVAILABLE_REGIONS[value]}</option>
          })}
        </Field>
      </div>
      <div className="form-group">
        <label htmlFor="startDate">Start Date</label>
        <Field
          name="startDate"
          placeholder=""
          component={DatePickerInput}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="endDate">End Date</label>
        <Field
          name="endDate"
          placeholder=""
          component={DatePickerInput}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="order">Order</label>
        <Field
          name="order"
          component="input"
          type="text"
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="order">Web Action URL</label>
        <Field
          name="webAction"
          component="input"
          type="text"
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="order">Mobile Action URL</label>
        <Field
          name="mobileAction"
          component="input"
          type="text"
          className="form-control"
        />
      </div>
      <div className="form-group">
        <Field name="active" component="input" type="checkbox" />
        <label htmlFor="active">Active</label>
      </div>
      <button className="btn btn-primary" disabled={submitting}>
        {submitting ? 'Updating' : 'Submit'}
      </button>
    </form>
  )
}

BannerRegionForm = reduxForm({
  form: 'localeForm',
})(BannerRegionForm)

export default BannerRegionForm
