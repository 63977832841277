import { createAction } from '@reduxjs/toolkit'
import { handleActionError, handleActionPayload } from './utils'
import * as PromoCodeService from '../services/promoCodes'

export const PROMO_CODE_ACTIONS = {
  // GET PROMO CODES
  REQUEST_PROMO_CODES: 'REQUEST_PROMO_CODES',
  RECEIVE_PROMO_CODES: 'RECEIVE_PROMO_CODES',
  ERROR_RECEIVING_PROMO_CODES: 'ERROR_RECEIVING_PROMO_CODES',
  // UPDATE PROMO CODES
  UPDATING_PROMO_CODES: 'UPDATING_PROMO_CODES',
  UPDATED_PROMO_CODES: 'UPDATED_PROMO_CODES',
  ERROR_UPDATING_PROMO_CODES: 'ERROR_UPDATING_PROMO_CODES',
}

// GET PROMO CODES
const requestPromoCodes = createAction(
  PROMO_CODE_ACTIONS.REQUEST_PROMO_CODES,
  handleActionPayload
)
const receivePromoCodes = createAction(
  PROMO_CODE_ACTIONS.RECEIVE_PROMO_CODES,
  handleActionPayload
)
const errorReceivingPromoCodes = createAction(
  PROMO_CODE_ACTIONS.ERROR_RECEIVING_PROMO_CODES,
  handleActionError
)

// UPDATE PROMO CODES
const updatingPromoCode = createAction(
  PROMO_CODE_ACTIONS.UPDATING_PROMO_CODES,
  handleActionPayload
)
const updatedPromoCode = createAction(
  PROMO_CODE_ACTIONS.UPDATED_PROMO_CODES,
  handleActionPayload
)
const errorUpdatingPromoCode = createAction(
  PROMO_CODE_ACTIONS.ERROR_UPDATING_PROMO_CODES,
  handleActionError
)

export function getPromoCodes(filters) {
  return async (dispatch) => {
    dispatch(requestPromoCodes({ filters }))
    try {
      const promoCodes = await PromoCodeService.getPromoCodes(filters)
      return dispatch(receivePromoCodes(promoCodes))
    } catch (e) {
      return dispatch(errorReceivingPromoCodes(e))
    }
  }
}

export function updatePromoCode(promoCodeId, request) {
  return async (dispatch) => {
    dispatch(updatingPromoCode({ promoCodeId }))
    try {
      const response = await PromoCodeService.updatePromoCode(
        promoCodeId,
        request
      )
      return dispatch(updatedPromoCode({ promoCode: response.promoCode }))
    } catch (e) {
      return dispatch(errorUpdatingPromoCode(e))
    }
  }
}
