export const REQUEST_USER = 'REQUEST_USER'
export const REQUEST_REACTIVATE_USER = 'REQUEST_REACTIVATE_USER'
export const REQUEST_DEACTIVATE_USER = 'REQUEST_DEACTIVATE_USER'
export const REQUEST_IMPERSONATE_USER = 'REQUEST_IMPERSONATE_USER'
export const REQUEST_CHANGE_ON_RENEWAL_STATUS =
  'REQUEST_CHANGE_ON_RENEWAL_STATUS'
export const REQUEST_DEACTIVATE_IMMEDIATELY = 'REQUEST_DEACTIVATE_IMMEDIATELY'
export const UPDATING_USER_INFO = 'UPDATING_USER_INFO'
export const UPDATED_USER_INFO = 'UPDATE_USER_INFO'
export const ERROR_UPDATING_USER_INFO = 'ERROR_UPDATING_USER_INFO'
export const RECEIVE_USER = 'RECEIVE_USER'
export const REACTIVATE_USER = 'REACTIVATE_USER'
export const DEACTIVATE_USER = 'DEACTIVATE_USER'
export const IMPERSONATE_USER = 'IMPERSONATE_USER'
export const RECEIVE_CHANGE_ON_RENEWAL_STATUS =
  'RECEIVE_CHANGE_ON_RENEWAL_STATUS'
export const DEACTIVATE_IMMEDIATELY = 'DEACTIVATE_IMMEDIATELY'
