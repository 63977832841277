import { createAction } from '@reduxjs/toolkit'
import { handleActionError, handleActionPayload } from './utils'
import * as FreeAccountService from '../services/freeAccounts'

export const FREE_ACCOUNT_ACTIONS = {
  // CREATE FREE ACCOUNT
  CREATE_FREE_ACCOUNT: 'CREATE_FREE_ACCOUNT',
  RECEIVE_FREE_ACCOUNT: 'RECEIVE_FREE_ACCOUNT',
  ERROR_CREATING_FREE_ACCOUNT: 'ERROR_CREATING_FREE_ACCOUNT',
  // GET FREE ACCOUNTS
  REQUEST_FREE_ACCOUNTS: 'REQUEST_FREE_ACCOUNTS',
  RECEIVE_FREE_ACCOUNTS: 'RECEIVE_FREE_ACCOUNTS',
  ERROR_RECEIVING_FREE_ACCOUNTS: 'ERROR_RECEIVING_FREE_ACCOUNTS',
  // UPDATE FREE ACCOUNT
  UPDATING_FREE_ACCOUNT: 'UPDATING_FREE_ACCOUNT',
  UPDATED_FREE_ACCOUNT: 'UPDATED_FREE_ACCOUNT',
  ERROR_UPDATING_FREE_ACCOUNT: 'ERROR_UPDATING_FREE_ACCOUNT',
}

// CREATE FREE ACCOUNT
const creatingFreeAccount = createAction(
  FREE_ACCOUNT_ACTIONS.CREATE_FREE_ACCOUNT,
  handleActionPayload
)
const receivedFreeAccount = createAction(
  FREE_ACCOUNT_ACTIONS.RECEIVE_FREE_ACCOUNT,
  handleActionPayload
)
const errorCreatingFreeAccount = createAction(
  FREE_ACCOUNT_ACTIONS.ERROR_CREATING_FREE_ACCOUNT,
  handleActionError
)

// GET FREE ACCOUNTS
const requestFreeAccounts = createAction(
  FREE_ACCOUNT_ACTIONS.REQUEST_FREE_ACCOUNTS,
  handleActionPayload
)
const receiveFreeAccounts = createAction(
  FREE_ACCOUNT_ACTIONS.RECEIVE_FREE_ACCOUNTS,
  handleActionPayload
)
const errorReceivingFreeAccounts = createAction(
  FREE_ACCOUNT_ACTIONS.ERROR_RECEIVING_FREE_ACCOUNTS,
  handleActionError
)

// UPDATE FREE ACCOUNT
const updatingFreeAccount = createAction(
  FREE_ACCOUNT_ACTIONS.UPDATING_FREE_ACCOUNT,
  handleActionPayload
)
const updatedFreeAccount = createAction(
  FREE_ACCOUNT_ACTIONS.UPDATED_FREE_ACCOUNT,
  handleActionPayload
)
const errorUpdatingFreeAccount = createAction(
  FREE_ACCOUNT_ACTIONS.ERROR_UPDATING_FREE_ACCOUNT,
  handleActionError
)

export function createFreeAccount(data) {
  return async (dispatch) => {
    dispatch(creatingFreeAccount({ customerId: data.customerId }))
    try {
      const response = await FreeAccountService.createFreeAccount(data)
      return dispatch(receivedFreeAccount(response))
    } catch (e) {
      return dispatch(errorCreatingFreeAccount(e))
    }
  }
}

export function getFreeAccounts() {
  return async (dispatch) => {
    dispatch(requestFreeAccounts())
    try {
      const res = await FreeAccountService.getFreeAccounts()
      return dispatch(receiveFreeAccounts(res))
    } catch (e) {
      return dispatch(errorReceivingFreeAccounts(e))
    }
  }
}

export function updateFreeAccount(data) {
  return async (dispatch) => {
    dispatch(updatingFreeAccount({ customerId: data.customerId }))
    try {
      const response = await FreeAccountService.updateFreeAccount(data)
      return dispatch(updatedFreeAccount(response))
    } catch (e) {
      return dispatch(errorUpdatingFreeAccount(e))
    }
  }
}
