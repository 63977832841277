import swal from 'sweetalert'
import _ from 'lodash'
import { store } from '../store'
import { logout as logoutAction } from '../actions/auth'

export function getStripeUrl() {
  return process.env.REACT_APP_STRIPE_URL
}

export function isProd() {
  return process.env.REACT_APP_SR === 'false'
}

export async function fetchWithHeaders(route, body, inferContentType = false) {
  const headers = new Headers()
  const { authToken } = store.getState()

  if (authToken) {
    headers.append('Authorization', `JWT ${authToken}`)
  }

  if (!inferContentType) {
    headers.append('Content-Type', 'application/json')
  }
  headers.append('x-djc-app-id', 'CA92FEA5-7EB5-4C02-AF8F-3ED79325F738')
  headers.append('x-djc-app-version', '3.0.1')

  const result = await fetch(`${process.env.REACT_APP_API_URL}${route}`, {
    ...body,
    headers,
  })

  // If the response status is 401 (unauthorized) or 403 (forbidden),
  // we must log out the user
  if (result.status === 401 || result.status === 403) {
    store.dispatch(logoutAction())
  }

  return result
}

export const getQueryParams = (params) => {
  return Object.keys(params)
    .filter((key) => params[key] !== null && params[key] !== undefined)
    .map((key) => {
      if (typeof params[key] === 'string') {
        return key + '=' + encodeURIComponent(params[key].trim())
      }
      return key + '=' + encodeURIComponent(params[key])
    })
    .join('&')
}

export const getLoadOptionParameters = (loadOptions) => {
  const params = new URLSearchParams()
  const { skip, take: pageSize } = loadOptions

  params.set('page', Math.floor(skip / pageSize) + 1)
  params.set('pageSize', pageSize)

  if (loadOptions.filter) {
    params.set('name', loadOptions.filter[loadOptions.filter.length - 1])
  }

  return `&${params}`
}

export const parseResponse = async (response) => {
  try {
    const contentType = response.headers.get('Content-Type')
    const contentJson = contentType && contentType.includes('application/json')

    let content
    if (contentJson) {
      content = await response.json()
    } else {
      content = response
    }

    if (!response.ok) {
      throw content
    }

    return content
  } catch (ex) {
    throw parseErrorResponse(response, ex)
  }
}

function parseErrorResponse(response, content) {
  const message =
    content.error && content.error.userDescription
      ? content.error.userDescription
      : 'Unable to process your request. Please try again later'

  return {
    message: message,
    status: response.status,
    statusText: response.statusText,
  }
}

export const confirmationMessage = async (title, subtitle) => {
  // eslint-disable-next-line new-cap
  return Promise.resolve(
    await swal({
      title: title,
      text: subtitle,
      type: 'warning',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      buttons: ['No', 'Yes'],
    })
  )
}

export const handleServerError = (err) => {
  const error = _.get(err, 'response.data.error')
  error
    ? defaultErrorMessage(error.userTitle, error.userDescription)
    : defaultErrorMessage()
}

export const defaultErrorMessage = (
  title = 'Error',
  description = 'There was an error with this request.'
) => swal(title, description, 'error')

export const NOTIFICATIONS = {
  USER_NOTIFICATIONS: 'USER_NOTIFICATIONS',
  BANNER_NOTIFICATION: 'BANNER_NOTIFICATION',
  PROMO_NOTIFICATIONS: 'PROMO_NOTIFICATIONS',
  PROMO_CODE_NOTIFICATIONS: 'PROMO_CODE_NOTIFICATIONS',
  FREE_ACCOUNT_NOTIFICATIONS: 'FREE_ACCOUNT_NOTIFICATIONS',
}

export const AVAILABLE_REGIONS = {
  1: 'en-US',
  2: 'ja-JP',
  3: 'en-GB',
  4: 'de-DE',
  6: 'es-ES',
}

export const AVAILABLE_REGIONS_SHORT = {
  1: 'US',
  2: 'JP',
  3: 'GB',
  4: 'DE',
  6: 'ES',
}
