import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DatePicker from 'react-datepicker'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import {
  getCrate,
  restoreDeletedCrate,
  restoreQueuedCrate,
} from '../../actions/crate'
import { Table } from '../table'
import { Pagination } from '../pagination'

import styles from './crate.module.sass'

function CrateComponent({ user }) {
  const crate = useSelector((state) => state.crate)
  const [deletedDate, setDeletedDate] = useState(new Date())
  const [queuedDate, setQueuedDate] = useState(new Date())
  const dispatch = useDispatch()
  const [skipItems, setSkipItems] = useState(crate.skipItems)
  const userId = user ? user.user.userId : null

  const onRestoreDeletedClick = useCallback(() => {
    if (userId) {
      dispatch(restoreDeletedCrate(userId, deletedDate))
    }
  }, [userId, deletedDate, dispatch])

  const onRestoreQueuedClick = useCallback(() => {
    if (userId) {
      dispatch(restoreQueuedCrate(userId, queuedDate))
    }
  }, [userId, queuedDate, dispatch])

  useEffect(() => {
    if (!userId) {
      return
    }

    dispatch(getCrate(userId, skipItems, crate.pageSize))
  }, [userId, crate.pageSize, skipItems, dispatch])

  const page = Math.floor(crate.skipItems / crate.pageSize) + 1
  const totalPages = Math.ceil(crate.totalItems / crate.pageSize)

  const onPageSelect = useCallback(
    (newPage) => setSkipItems((newPage - 1) * crate.pageSize),
    [crate.pageSize, setSkipItems]
  )

  return (
    <>
      <div className={styles.crate_date_form}>
        <div className={styles.date_row}>
          Restore items <span className={styles.highlight}>deleted</span> after
          <DatePicker
            selected={deletedDate}
            className={styles.date_picker}
            onChange={(newDate) => setDeletedDate(newDate)}
          />
          <button
            className="mt-2 mb-2 btn btn-info btn-sm btn"
            onClick={onRestoreDeletedClick}
          >
            Restore Deleted Items
          </button>
        </div>
        <div className={styles.date_row}>
          Restore items <span className={styles.highlight}>enqueued</span> after
          <DatePicker
            selected={queuedDate}
            className={styles.date_picker}
            onChange={(newDate) => setQueuedDate(newDate)}
          />
          <button
            className="mt-2 mb-2 btn btn-info btn-sm btn"
            onClick={onRestoreQueuedClick}
          >
            Restore Enqueued Items
          </button>
        </div>
      </div>
      <Table
        className={classNames({ [styles.loading]: crate.loading })}
        columns={[
          'ID',
          'Title',
          'Artist',
          'Track type',
          'Added On',
          'Deleted On',
          'Queued On',
        ]}
      >
        {crate.items.map((item) => [
          <div key="ID">{item.id}</div>,
          <div key="Title">{item.track.record.title}</div>,
          <div key="Artist">{item.track.record.artist}</div>,
          <div key="Track type">{item.track.trackType}</div>,
          <div key="Added On">
            {item.addedOn ? moment.utc(item.addedOn).format('YYYY/MM/DD') : ''}
          </div>,
          <div key="Deleted On">
            {item.deletedOn
              ? moment.utc(item.deletedOn).format('YYYY/MM/DD')
              : ''}
          </div>,
          <div key="Queued On">
            {item.queuedOn
              ? moment.utc(item.queuedOn).format('YYYY/MM/DD')
              : ''}
          </div>,
        ])}
      </Table>
      <Pagination
        className={classNames(styles.pagination, {
          [styles.loading]: crate.loading,
        })}
        label={`Page ${page} of ${totalPages} (${crate.totalItems} items)`}
        onPageSelect={onPageSelect}
        page={page}
        totalPages={totalPages}
      />
    </>
  )
}

CrateComponent.propTypes = {
  user: PropTypes.object,
}

CrateComponent.defaultProps = {
  user: null,
}

export default CrateComponent
