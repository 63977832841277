import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Form, Row, Col } from 'react-bootstrap'
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import { debounce, omit } from 'lodash'
import swal from 'sweetalert'

import {
  PROMO_ACTIONS,
  getPromos,
  upsertPromos,
  deletePromo,
} from '../actions/promos'
import { PromoTable } from '../components/promos/table'

const defaultPricing = {
  'pricing.USD': 0,
  'pricing.EUR': 0,
  'pricing.GBP': 0,
  'pricing.JPY': 0,
}
const defaultRequirements = {
  'requirements.checkCooldownPeriod': true,
  'requirements.checkCreditCard': true,
  'requirements.checkPaymentFingerprint': true,
  'requirements.checkPreviousPayments': '2',
  'requirements.checkSamePromo': true,
}
const defaultStrings = {
  'messages.success': 'djcity.billing.payment.paymentdetails.promocode.success',
  'messages.error': 'djcity.billing.payment.paymentdetails.promocode.error',
  'messages.paymentButton':
    'djcity.billing.payment.paymentdetails.netnew_promo.button',
  'messages.paymentHeader':
    'djcity.billing.payment.paymentdetails.netnew_promo.title',
  'messages.paymentPrice':
    'djcity.common.payment.subscriptions.net_new_applied',
  'messages.previousPaymentError':
    'djcity.billing.payment.paymentdetails.promocode.error',
  'messages.samePromoError': 'djcity.common.promo.trial.previously_used.error',
}

const PromoContainer = () => {
  // GLOBAL STATE
  const dispatch = useDispatch()
  const { data: promos, isFetching } = useSelector((s) => s.promos)

  // LOCAL STATE
  const [filter, setFilter] = useState('ALL')

  // MODAL FORM
  const [isCreate, setIsCreate] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [form, setForm] = useState({})

  const setFormValue = debounce((val) => {
    if (!val) return
    setForm(val)
  }, 250)

  // EVENT HANDLERS
  const handleCreate = (e) => {
    setForm({
      duration: 0,
      durationType: 'M',
      isPaid: false,
      ...defaultStrings,
      ...defaultRequirements,
    })
    setIsCreate(true)
    setIsOpen(true)
  }

  const generateForm = (data) => {
    let {
      isPaid,
      messages,
      prices,
      requirements,
      startDate,
      endDate,
      ...rest
    } = data

    startDate = toDateString(startDate || new Date.UTC())
    endDate = toDateString(endDate || new Date.UTC())
    requirements = JSON.parse(requirements)[0]
    prices = prices && JSON.parse(prices)
    messages = messages && JSON.parse(messages)

    let msgs = defaultStrings
    let pricing
    let reqs = {}

    if (isPaid && prices) {
      pricing = {}
      prices.forEach((p) => (pricing['pricing.' + p.currency] = p.price))
    }

    if (messages) {
      messages.forEach(({ type, name }) => {
        msgs['messages.' + type] = name
      })
    }

    Object.entries(requirements).forEach(([key, value]) => {
      if (key === 'promoId') return
      else reqs['requirements.' + key] = value
    })

    if (typeof requirements.checkPreviousPayments === 'undefined') {
      reqs['requirements.checkPreviousPayments'] = 2
    } else if (requirements.checkPreviousPayments === true) {
      reqs['requirements.checkPreviousPayments'] = 1
    } else {
      reqs['requirements.checkPreviousPayments'] = 0
    }

    return { ...rest, msgs, pricing, reqs, isPaid, startDate, endDate }
  }

  const handleEdit = ({ row }) => {
    const { msgs, pricing, reqs, isPaid, startDate, endDate, ...rest } =
      generateForm(row.data)

    setForm({
      ...rest,
      ...msgs,
      ...pricing,
      ...reqs,
      isPaid,
      startDate,
      endDate,
    })
    setIsCreate(false)
    setIsOpen(true)
  }

  const handleClone = ({ row }) => {
    const { msgs, pricing, reqs, isPaid, startDate, endDate, ...rest } = omit(
      generateForm(row.data),
      'id',
      'name',
      'description'
    )

    setForm({
      ...rest,
      ...msgs,
      ...pricing,
      ...reqs,
      isPaid,
      startDate,
      endDate,
    })
    setIsCreate(true)
    setIsOpen(true)
  }

  const handleDelete = async ({ row }) => {
    const { id, startDate } = row.data
    let action

    if (Date.parse(startDate).valueOf() < Date.now()) {
      action = await swal({
        title: 'Are you sure you want to delete this promo?',
        text: 'The promo is currently active and there might be users who redeemed it. Deleting it might cause database issues.',
        icon: 'warning',
        buttons: {
          cancel: 'Close',
          delete: 'Delete Promo',
        },
      })
    } else {
      action = 'delete'
    }

    if (action && action === 'delete') {
      const res = await deletePromo(id)(dispatch)
      if (res.type === PROMO_ACTIONS.DELETED_PROMOS) {
        return swal({ title: 'PROMO DELETED', icon: 'success' })
      }
      if (res.type === PROMO_ACTIONS.ERROR_DELETING_PROMOS) {
        return swal({
          title: 'ERROR',
          text: 'There was an error while processing this request',
          icon: 'error',
        })
      }
    }

    dispatch(getPromos())
  }

  const handleForm = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { id, value, type, checked } = e.target
    if (type === 'checkbox') {
      if (id === 'isPaid' && checked) {
        setFormValue({ ...form, ...defaultPricing, [id]: !form[id] })
      } else {
        Object.keys(defaultPricing).forEach((p) => delete form[p])
        setFormValue({ ...form, [id]: !form[id] })
      }
    } else {
      setFormValue({ ...form, [id]: value })
    }
  }

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      if (!form.isPaid) {
        form['messages.paymentButton'] =
          'djcity.billing.payment.paymentdetails.trial_promo.button'
        form['messages.paymentHeader'] =
          'djcity.billing.payment.paymentdetails.trialdate'
        form['messages.paymentPrice'] =
          'djcity.common.payment.subscriptions.trial_applied'
        form['requirements.checkPreviousPayments'] = parseInt(
          form['requirements.checkPreviousPayments']
        )
      }
      dispatch(upsertPromos(form))
      setIsCreate(false)
      setIsOpen(false)
      dispatch(getPromos())
    },
    [form]
  )

  // EVENT LISTENERS
  useEffect(() => {
    dispatch(getPromos())
  }, [])

  // UTILS
  const filterPromos = (p) => {
    let data = Object.values(p).filter((p) => p.id !== 0)

    if (filter === 'PAST')
      data = data.filter((p) => Date.parse(p.endDate).valueOf() < Date.now())
    if (filter === 'PRESENT')
      data = data.filter(
        (p) =>
          Date.parse(p.startDate).valueOf() < Date.now() &&
          Date.parse(p.endDate).valueOf() >= Date.now()
      )
    if (filter === 'FUTURE')
      data = data.filter((p) => Date.parse(p.startDate).valueOf() > Date.now())

    return data
  }

  const toDateString = (date) =>
    typeof date === 'undefined' ? '1970-01-01' : date.split('T')[0]

  const promotions = useMemo(() => filterPromos(promos), [promos, filter])

  return isFetching || !promos ? (
    <h6>Loading</h6>
  ) : (
    <React.Fragment>
      <div className="container">
        <div className="row px-3">
          <div className="col">
            <h4>Promos</h4>
          </div>
        </div>
        <div className="row px-3">
          <div className="col d-flex justify-content-between">
            <ButtonToolbar className="my-3">
              <ButtonGroup onClick={(e) => setFilter(e.target.value)}>
                <Button
                  className={classNames({ active: filter === 'ALL' })}
                  value="ALL"
                >
                  All
                </Button>
                <Button
                  className={classNames({ active: filter === 'PAST' })}
                  value="PAST"
                >
                  Past
                </Button>
                <Button
                  className={classNames({ active: filter === 'PRESENT' })}
                  value="PRESENT"
                >
                  Present
                </Button>
                <Button
                  className={classNames({ active: filter === 'FUTURE' })}
                  value="FUTURE"
                >
                  Future
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
            <Button className="my-3" onClick={handleCreate}>
              Create New
            </Button>
          </div>
        </div>
        <div className="row px-3">
          <PromoTable
            onDelete={handleDelete}
            onClone={handleClone}
            onEdit={handleEdit}
            promos={promotions}
          />
        </div>
      </div>
      <Modal show={isOpen} size="lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {isCreate ? 'Create' : 'Update'} Promo
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => setIsOpen(false)}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="name">
                <Form.Label>Promo Name</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={form.name}
                  onChange={handleForm}
                />
              </Form.Group>
              <Form.Group controlId="description">
                <Form.Label>Promo Description</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={form.description}
                  onChange={handleForm}
                />
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group controlId="startDate">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      type="date"
                      min="2020-01-01"
                      max="2050-12-31"
                      value={form.startDate}
                      onChange={handleForm}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="endDate">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      type="date"
                      min="2020-01-01"
                      max="2050-12-31"
                      value={form.endDate}
                      onChange={handleForm}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="isPaid">
                    <Form.Label>Requires Initial Payment?</Form.Label>
                    <Form.Check
                      type="checkbox"
                      checked={form.isPaid}
                      onChange={handleForm}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="isUserSpecific">
                    <Form.Label>Requires individual codes?</Form.Label>
                    <Form.Check
                      type="checkbox"
                      checked={form.isUserSpecific}
                      onChange={handleForm}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {form.isUserSpecific && (
                <Form.Group controlId="codesAvailable">
                  <Form.Label>How many codes shall be generated ?</Form.Label>
                  <Form.Control
                    type="number"
                    defaultValue={form.codesAvailable}
                    onChange={handleForm}
                    min={-1}
                  />
                </Form.Group>
              )}
              <Row>
                <Col>
                  <Form.Group controlId="duration">
                    <Form.Label>Duration</Form.Label>
                    <Form.Control
                      type="number"
                      defaultValue={form.duration}
                      min={1}
                      max={12}
                      onChange={handleForm}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="durationType">
                    <Form.Label>Duration Type</Form.Label>
                    <Form.Control
                      disabled={true}
                      as="select"
                      aria-label="Duration Type"
                      defaultValue={form.durationType}
                      onChange={handleForm}
                    >
                      <option value="D">Days</option>
                      <option value="M">Months</option>
                      <option value="Y">Years</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              {form.isPaid ? (
                <>
                  <Row>
                    <Col>
                      <h6>Pricing</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group controlId="pricing.USD">
                        <Form.Label>Price (USD)</Form.Label>
                        <Form.Control
                          type="number"
                          defaultValue={form['pricing.USD']}
                          onChange={handleForm}
                          min={1}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="pricing.EUR">
                        <Form.Label>Price (EUR)</Form.Label>
                        <Form.Control
                          type="number"
                          defaultValue={form['pricing.EUR']}
                          onChange={handleForm}
                          min={1}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="pricing.GBP">
                        <Form.Label>Price (GBP)</Form.Label>
                        <Form.Control
                          type="number"
                          defaultValue={form['pricing.GBP']}
                          onChange={handleForm}
                          min={1}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="pricing.JPY">
                        <Form.Label>Price (JPY)</Form.Label>
                        <Form.Control
                          type="number"
                          defaultValue={form['pricing.JPY']}
                          onChange={handleForm}
                          min={100}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              ) : null}
              <Row>
                <Col>
                  <h6>Requirements</h6>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="requirements.checkSamePromo">
                    <Form.Label>Can be redeemed multiple times?</Form.Label>
                    <Form.Check
                      type="checkbox"
                      checked={!form['requirements.checkSamePromo']}
                      onChange={handleForm}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="requirements.checkPreviousPayments">
                    <Form.Label>Check previous payments ?</Form.Label>
                    <Form.Control
                      as="select"
                      defaultValue={form['requirements.checkPreviousPayments']}
                      onChange={handleForm}
                    >
                      <option value="0">
                        User must have NOT made payments before
                      </option>
                      <option value="1">
                        User must have made payments before
                      </option>
                      <option value="2">Don't Check</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <button className="mt-2 mb-2 btn btn-info btn-sm btn">
                Submit
              </button>
            </Form>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(PromoContainer)
