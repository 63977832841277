import {
  fetchWithHeaders,
  parseResponse,
  getQueryParams,
} from '../helpers/utils'
import swal from 'sweetalert'

export const getCrate = (userId, skipItems, pageSize) => {
  return fetchWithHeaders(
    `crate/${userId}?${getQueryParams({ skipItems, pageSize })}`,
    {
      method: 'GET',
    }
  )
    .then(parseResponse)
    .then((data) => {
      if (data.error) {
        swal(data.error.userTitle, data.error.userDescription, 'error')
      } else {
        return data
      }
    })
    .catch(() => {
      throw new Error('Error loading users.')
    })
}

export const restoreCrate = (userId, date, itemType) => {
  const body = JSON.stringify({
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
    userId: userId,
  })

  return fetchWithHeaders(`crate/restore/${itemType}`, {
    method: 'POST',
    body,
  })
    .then(parseResponse)
    .then((data) => {
      if (data.error) {
        swal(data.error.userTitle, data.error.userDescription, 'error')
      } else {
        const { restoredItems } = data
        swal(
          'Success!',
          `${restoredItems} restored item${restoredItems > 1 ? 's' : ''}!`
        )
        return data
      }
    })
    .catch(() => {
      throw new Error('Error loading users.')
    })
}
