import * as FeatureService from '../services/feature'

export const FEATURE_ACTIONS = {
  // Actions for feature details
  REQUEST_FEATURES: 'REQUEST_FEATURES',
  RECEIVE_FEATURES: 'RECEIVE_FEATURES',
  ERROR_RECEIVING_FEATURES: 'ERROR_RECEIVING_FEATURES',
  UPDATING_FEATURE: 'UPDATING_FEATURE',
  UPDATED_FEATURE: 'UPDATED_FEATURE',
  ERROR_UPDATING_FEATURE: 'ERROR_UPDATING_FEATURE',
  // Actions for user's associated with features
  REQUEST_FEATURE_USERS: 'REQUEST_FEATURE_USERS',
  RECEIVE_FEATURE_USERS: 'RECEIVE_FEATURE_USERS',
  ERROR_RECEIVING_FEATURE_USERS: 'ERROR_RECEIVING_FEATURE_USERS',
  UPDATING_FEATURE_USERS: 'UPDATING_FEATURE_USERS',
  UPDATED_FEATURE_USERS: 'UPDATED_FEATURE_USERS',
  ERROR_UPDATING_FEATURE_USERS: 'ERROR_UPDATING_FEATURE_USERS',
  // Actions for individual users
  REQUEST_USER_FEATURES: 'REQUEST_USER_FEATURES',
  RECEIVE_USER_FEATURES: 'RECEIVE_USER_FEATURES',
  ERROR_RECEIVING_USER_FEATURES: 'ERROR_RECEIVING_USER_FEATURES',
  UPDATING_USER_FEATURES: 'UPDATING_USER_FEATURES',
  UPDATED_USER_FEATURES: 'UPDATED_USER_FEATURES',
  ERROR_UPDATING_USER_FEATURES: 'ERROR_UPDATING_USER_FEATURES',
}

// Feature Details
export function requestFeatures() {
  return {
    type: FEATURE_ACTIONS.REQUEST_FEATURES,
  }
}

export function receiveFeatures(features) {
  return {
    type: FEATURE_ACTIONS.RECEIVE_FEATURES,
    features,
  }
}

export function errorRetrievingFeatures(error) {
  return {
    type: FEATURE_ACTIONS.ERROR_RECEIVING_FEATURES,
    error,
  }
}

export function getFeatures() {
  return (dispatch) => {
    dispatch(requestFeatures())
    return FeatureService.getFeatures()
      .then((features) => {
        dispatch(receiveFeatures(features))
      })
      .catch((error) => {
        dispatch(errorRetrievingFeatures(error))
      })
  }
}

function updatingFeature() {
  return {
    type: FEATURE_ACTIONS.UPDATING_FEATURE,
  }
}

function updatedFeatures(feature) {
  return {
    type: FEATURE_ACTIONS.UPDATED_FEATURE,
    feature,
  }
}

function errorUpdatingFeatures(error) {
  return {
    type: FEATURE_ACTIONS.ERROR_UPDATING_FEATURE,
    error,
  }
}

export function updateFeatures(featureId, enableForEveryone) {
  return (dispatch) => {
    dispatch(updatingFeature())
    return FeatureService.updateFeature(featureId, enableForEveryone)
      .then((feature) => {
        dispatch(updatedFeatures(feature))
      })
      .catch((error) => errorUpdatingFeatures(error))
  }
}

// Feature Users
function requestFeatureUsers(featureId) {
  return {
    type: FEATURE_ACTIONS.REQUEST_FEATURE_USERS,
    featureId,
  }
}

function receiveFeatureUsers(featureId, users) {
  return {
    type: FEATURE_ACTIONS.RECEIVE_FEATURE_USERS,
    featureId,
    users,
  }
}

function errorReceivingFeatureUsers(featureId, error) {
  return {
    type: FEATURE_ACTIONS.ERROR_RECEIVING_FEATURE_USERS,
    featureId,
    error,
  }
}

export function getFeatureUsers(
  featureId,
  params = { page: '1', pageSize: '25' }
) {
  return (dispatch) => {
    dispatch(requestFeatureUsers(featureId))
    return FeatureService.getFeatureUsers(featureId, params)
      .then((users) => {
        dispatch(receiveFeatureUsers(featureId, users))
      })
      .catch((error) => errorReceivingFeatureUsers(featureId, error))
  }
}

function updatingFeatureUsers(featureId) {
  return {
    type: FEATURE_ACTIONS.UPDATING_FEATURE_USERS,
    featureId,
  }
}

function updatedFeatureUsers(featureId, userIds) {
  return {
    type: FEATURE_ACTIONS.UPDATED_FEATURE_USERS,
    featureId,
    userIds,
  }
}

function errorUpdatingFeatureUsers(featureId, error) {
  return {
    type: FEATURE_ACTIONS.ERROR_UPDATING_FEATURE_USERS,
    featureId,
    error,
  }
}

export function updateFeatureUsers(featureId, userIds, replaceAll) {
  return (dispatch) => {
    dispatch(updatingFeatureUsers(featureId))
    return FeatureService.updateFeatureUsers(featureId, userIds, replaceAll)
      .then((users) => {
        dispatch(updatedFeatureUsers(featureId, userIds))
      })
      .catch((error) => errorUpdatingFeatureUsers(featureId, error))
  }
}

// User's Features

function requestUserFeatures(userId) {
  return {
    type: FEATURE_ACTIONS.REQUEST_USER_FEATURES,
    userId,
  }
}

function receiveUserFeatures(userId, features) {
  return {
    type: FEATURE_ACTIONS.RECEIVE_USER_FEATURES,
    userId,
    features,
  }
}

function errorReceivingUserFeatures(userId, error) {
  return {
    type: FEATURE_ACTIONS.ERROR_RECEIVING_USER_FEATURES,
    userId,
    error,
  }
}

export function getUserFeatures(userId) {
  return (dispatch) => {
    dispatch(requestUserFeatures(userId))
    return FeatureService.getUsersFeatures(userId)
      .then((features) => {
        dispatch(receiveUserFeatures(userId, features))
      })
      .catch((error) => errorReceivingUserFeatures(userId, error))
  }
}

function updatingUserFeatures(userId) {
  return {
    type: FEATURE_ACTIONS.UPDATING_USER_FEATURES,
    userId,
  }
}

function updatedUserFeatures(userId, featureId) {
  return {
    type: FEATURE_ACTIONS.UPDATED_USER_FEATURES,
    userId,
    featureId,
  }
}

function errorUpdatingUserFeatures(userId, error) {
  return {
    type: FEATURE_ACTIONS.ERROR_UPDATING_USER_FEATURES,
    userId,
    error,
  }
}

export function updateUserFeatures(userId, featureIds) {
  return (dispatch) => {
    dispatch(updatingUserFeatures(userId))
    return FeatureService.updateUserFeatures(userId, featureIds)
      .then((features) => {
        dispatch(updatedUserFeatures(userId, features))
      })
      .catch((error) => errorUpdatingUserFeatures(userId, error))
  }
}

export function toggleFeatureForUser(userId, featureId, enabled) {
  return (dispatch) => {
    dispatch(updatingUserFeatures(userId))
    return FeatureService.toggleUserFeature(userId, featureId, enabled)
      .then(() => {
        dispatch(updatedUserFeatures(userId, featureId))
      })
      .catch((error) => errorUpdatingUserFeatures(userId, error))
  }
}
