import { fetchWithHeaders, parseResponse } from '../helpers/utils'

export const createFreeAccount = async (data) => {
  const response = await fetchWithHeaders(`users/free`, {
    method: 'POST',
    body: JSON.stringify(data),
  })
  return parseResponse(response)
}

export const getFreeAccounts = async () => {
  const response = await fetchWithHeaders(`users/free`, {
    method: 'GET',
  })
  return parseResponse(response)
}

export const updateFreeAccount = async (data) => {
  const response = await fetchWithHeaders(`users/free`, {
    method: 'PUT',
    body: JSON.stringify(data),
  })
  return parseResponse(response)
}
