import { Field, reduxForm } from 'redux-form'
import React from 'react'

let SecurityGroupForm = (props) => {
  const { availableSecurityGroups, handleSubmit, isSubmitting } = props
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="securityGroupId">Security Group</label>
        <Field
          name="securityGroupId"
          placeholder="Security Group ID"
          component="select"
          type="text"
          className="form-control"
          required
        >
          <option />
          {availableSecurityGroups.map(({ sgid, name }) => {
            return <option value={sgid}>{name}</option>
          })}
        </Field>
      </div>
      <button className="btn btn-primary" disabled={isSubmitting}>
        {isSubmitting ? 'Saving' : 'Save'}
      </button>
    </form>
  )
}

SecurityGroupForm = reduxForm({
  form: 'securityGroupForm',
})(SecurityGroupForm)

export default SecurityGroupForm
