import { createAction } from '@reduxjs/toolkit'
import { handleActionError, handleActionPayload } from './utils'

import * as PromoService from '../services/promos'

export const PROMO_ACTIONS = {
  // CREATE / UPDATE PROMO
  UPSERT_PROMO: 'CREATE_PROMO',
  UPSERTED_PROMO: 'RECEIVE_PROMO',
  ERROR_UPSERTING_PROMO: 'ERROR_CREATING_PROMO',
  // GET PROMOS
  REQUEST_PROMOS: 'REQUEST_PROMOS',
  RECEIVE_PROMOS: 'RECEIVE_PROMOS',
  ERROR_RECEIVING_PROMOS: 'ERROR_RECEIVING_PROMOS',
  // DELETE PROMOS
  DELETE_PROMOS: 'DELETE_PROMOS',
  DELETED_PROMOS: 'DELETED_PROMOS',
  ERROR_DELETING_PROMOS: 'ERROR_DELETING_PROMOS',
}

// UPSERT PROMO
const upsertPromo = createAction(
  PROMO_ACTIONS.UPSERT_PROMO,
  handleActionPayload
)
const upsertedPromo = createAction(
  PROMO_ACTIONS.UPSERTED_PROMO,
  handleActionPayload
)
const errorUpsertingPromo = createAction(
  PROMO_ACTIONS.ERROR_UPSERTING_PROMO,
  handleActionPayload
)

// GET PROMOS
const requestPromos = createAction(
  PROMO_ACTIONS.REQUEST_PROMOS,
  handleActionPayload
)
const receivePromos = createAction(
  PROMO_ACTIONS.RECEIVE_PROMOS,
  handleActionPayload
)
const errorReceivingPromos = createAction(
  PROMO_ACTIONS.ERROR_RECEIVING_PROMOS,
  handleActionError
)

// DELETE PROMOS
const deletePromos = createAction(
  PROMO_ACTIONS.REQUEST_PROMOS,
  handleActionPayload
)
const deletedPromos = createAction(
  PROMO_ACTIONS.RECEIVE_PROMOS,
  handleActionPayload
)
const errorDeletingPromos = createAction(
  PROMO_ACTIONS.ERROR_RECEIVING_PROMOS,
  handleActionError
)

export function getPromos() {
  return async (dispatch) => {
    dispatch(requestPromos())
    try {
      const res = await PromoService.getPromos()
      return dispatch(receivePromos(res))
    } catch (e) {
      return dispatch(errorReceivingPromos(e))
    }
  }
}

export function upsertPromos(form) {
  return async (dispatch) => {
    dispatch(upsertPromo())
    try {
      const res = await PromoService.upsertPromos(form)
      return dispatch(upsertedPromo(res))
    } catch (e) {
      return dispatch(errorUpsertingPromo(e))
    }
  }
}

export function deletePromo(promoId) {
  return async (dispatch) => {
    dispatch(deletePromos())
    try {
      const res = await PromoService.deletePromos({ promoId })
      return dispatch(deletedPromos(res))
    } catch (e) {
      return dispatch(errorDeletingPromos(e))
    }
  }
}
