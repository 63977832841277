import React from 'react'
import './user-details.css'
import moment from 'moment'
import PersonalInfoForm from '../users/info_form'
import AddressForm from '../users/address_form'
import { Modal } from 'react-bootstrap'
import { getStripeUrl } from '../../helpers/utils'

const inactive = {
  color: 'red',
  fontWeight: 'bold',
}

const active = {
  color: 'green',
  fontWeight: 'bold',
}

class UserDetailsComponent extends React.Component {
  constructor(props) {
    super(props)
    this.onUserUpdate = this.onUserUpdate.bind(this)
    this.onAddressUpdate = this.onAddressUpdate.bind(this)
    this.state = {
      showUserForm: false,
      showAddressForm: false,
    }
  }

  onUserUpdate(values) {
    const {
      user: {
        user: { userId },
      },
    } = this.props
    this.setState({ showUserForm: false })
    this.props.updateUser({
      userId,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phone,
      additionalInfo: values.additionalInfo,
    })
  }

  onAddressUpdate(values) {
    const {
      user: {
        user: { userId },
      },
    } = this.props
    this.setState({ showAddressForm: false })
    this.props.updateUser({
      userId,
      billingAddress: {
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2 || '',
        city: values.city,
        state: values.STATE,
        STATE: values.STATE,
        zip: values.zip,
        suite: values.suite || '',
        country: values.country,
      },
    })
  }

  render() {
    const {
      user: { user },
      loading,
    } = this.props
    const { billingAddress, subscription } = user
    const {
      currentGateway,
      currentPlan,
      currentPlanStart,
      currentPlanEnd,
      nextPlan,
      nextPlanStart,
      nextPlanEnd,
    } = subscription || {}

    const address = []
    if (billingAddress) {
      const { addressLine1, addressLine2, city, STATE, zip, country } =
        billingAddress
      const { firstName, lastName } = user
      address.push(`${firstName} ${lastName}\r\n`)
      if (addressLine1) address.push(`${addressLine1}\r\n`)
      if (addressLine2) address.push(`${addressLine2}\r\n`)
      address.push(`${city}, ${STATE} ${zip}\r\n`)
      address.push(country)
    }

    const PLANS = {
      MONTHLY: '1-MONTH',
      QUARTERLY: '3-MONTHS',
      BIANNUALLY: '6-MONTHS',
      ANNUALLY: '12-MONTHS',
    }
    return (
      <div>
        {user && !loading && (
          <div>
            <div className="row">
              <div className={'col-md-4'}>
                <small className="text-muted">Customer Id</small>
                <h6>{user.userId}</h6>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-md-4'}>
                <small className="text-muted">Email</small>
                <h6>{user.email}</h6>
              </div>
              <div className={'col-md-4'}>
                <small className="text-muted">First Name</small>
                <h6>{user.firstName}</h6>
              </div>
              <div className={'col-md-4'}>
                <small className="text-muted">Last Name</small>
                <h6>{user.lastName}</h6>
              </div>
              <div className={'col-md-4'}>
                <small className="text-muted">Additional Info</small>
                <h6>{user.additionalInfo}</h6>
              </div>
              {currentGateway === 'STRIPE' && (
                <div className={'col-md-4'}>
                  <small className="text-muted">Stripe Accounts</small>
                  {(user.paymentAccounts || []).map((pa) => (
                    <>
                      <br />
                      <small key={pa.StripeCustomerID}>
                        <a
                          href={`${getStripeUrl()}/customers/${pa.StripeCustomerID}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {pa.StripeCustomerID}
                        </a>
                        &nbsp; | Currency : {pa.currency}
                      </small>
                    </>
                  ))}
                  <h6>{user.additionalInfo}</h6>
                </div>
              )}
            </div>
            <div className={'row'}>
              <div>
                <button
                  onClick={() => this.setState({ showUserForm: true })}
                  className={'btn btn-secondary btn-sm mt-2 ml-3 mb-2'}
                >
                  Update Personal Info
                </button>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-md-4'}>
                <small className="text-muted">Account Type</small>
                <h6>{user.accountType}</h6>
              </div>
              <div className={'col-md-4'}>
                <small className="text-muted">Member Since</small>
                <h6>{moment.utc(user.memberSince).format('YYYY/MM/DD')}</h6>
              </div>
              <div className={'col-md-4'}>
                <small className="text-muted">Account Status</small>
                <h6 style={user.activeSubscription ? active : inactive}>
                  {user.activeSubscription ? 'Active' : 'Inactive'}
                </h6>
              </div>
            </div>
            <div className={'row'}>
              <div className={'col-md-4'}>
                <small className="text-muted">Phone Number</small>
                <h6>{user.phone}</h6>
              </div>
              <div className={'col-md-4'}>
                <small className="text-muted">Locale</small>
                <h6>{user.locale}</h6>
              </div>
              <div className={'col-md-4'}>
                <small className="text-muted">Gateway</small>
                <h6>{currentGateway}</h6>
              </div>
            </div>
            <div className="row">
              <div className={'col-md-4'}>
                <small className="text-muted">Current Payment Plan</small>
                <h6>
                  {currentPlan && currentPlan.includes('_')
                    ? PLANS[currentPlan.split('_')[0]]
                    : currentPlan || '-'}
                </h6>
              </div>
              <div className={'col-md-4'}>
                <small className="text-muted">
                  Current Billing Period Start
                </small>
                <h6>
                  {currentPlanStart
                    ? moment.utc(currentPlanStart).format('YYYY/MM/DD')
                    : '-'}
                </h6>
              </div>
              <div className={'col-md-4'}>
                <small className="text-muted">Current Billing Period End</small>
                <h6>
                  {currentPlanStart
                    ? moment.utc(currentPlanEnd).format('YYYY/MM/DD')
                    : '-'}
                </h6>
              </div>
            </div>
            <div className="row">
              <div className={'col-md-4'}>
                <small className="text-muted">Next Payment Plan</small>
                <h6>
                  {!user.cancellationIsScheduled &&
                  nextPlan &&
                  nextPlan.includes('_')
                    ? PLANS[nextPlan.split('_')[0]]
                    : nextPlan || '-'}
                </h6>
              </div>
              <div className={'col-md-4'}>
                <small className="text-muted">Next Billing Period Start</small>
                <h6>
                  {!user.cancellationIsScheduled &&
                  nextPlan &&
                  nextPlanStart !== currentPlanStart
                    ? moment.utc(nextPlanStart).format('YYYY/MM/DD')
                    : '-'}
                </h6>
              </div>
              <div className={'col-md-4'}>
                <small className="text-muted">Next Billing Period End</small>
                <h6>
                  {!user.cancellationIsScheduled &&
                  nextPlan &&
                  nextPlanEnd !== currentPlanEnd
                    ? moment.utc(nextPlanEnd).format('YYYY/MM/DD')
                    : '-'}
                </h6>
              </div>
            </div>
            <hr />
            {user.billingAddress && (
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <div className={'card'}>
                    <div className={'card-header'}>Address</div>
                    <textarea
                      rows="5"
                      value={address.join('')}
                      className="form-control"
                      readOnly={true}
                    />
                  </div>
                  <div>
                    <button
                      onClick={() => this.setState({ showAddressForm: true })}
                      data-toggle="modal"
                      data-target="#update-address-info-form"
                      className={'btn btn-secondary btn-sm mt-2 mb-2'}
                    >
                      Update Address
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <Modal show={this.state.showUserForm}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Personal Info</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => this.setState({ showUserForm: false })}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <PersonalInfoForm
                initialValues={user}
                onSubmit={this.onUserUpdate}
              />
            </div>
          </div>
        </Modal>
        <Modal show={this.state.showAddressForm}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Address</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => this.setState({ showAddressForm: false })}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AddressForm
                initialValues={user.billingAddress}
                onSubmit={this.onAddressUpdate}
              />
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default UserDetailsComponent
