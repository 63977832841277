import {
  fetchWithHeaders,
  getQueryParams,
  getLoadOptionParameters,
  parseResponse,
} from '../helpers/utils'
import swal from 'sweetalert'

export const getUsers = (params, loadOptions) => {
  return fetchWithHeaders(
    `users?${getQueryParams(params)}${getLoadOptionParameters(loadOptions)}`,
    {
      method: 'GET',
    }
  )
    .then(parseResponse)
    .then((data) => {
      if (data.error) {
        swal(data.error.userTitle, data.error.userDescription, 'error')
      } else
        return {
          data: data.users,
          totalCount: data.metadata[0].totalItems,
        }
    })
    .catch(() => {
      throw new Error('Error loading users.')
    })
}

export const getUserById = (id) => {
  return fetchWithHeaders(`users/${id}`, {
    method: 'GET',
  }).then(parseResponse)
}

export const getUser = (email) => {
  return fetchWithHeaders(`users?email=${email}`, {
    method: 'GET',
  })
    .then(parseResponse)
    .then((data) => {
      if (data.error) {
        swal(data.error.userTitle, data.error.userDescription, 'error')
      } else
        return {
          data: data.users,
          totalCount: data.metadata[0].totalItems,
        }
    })
    .catch(() => {
      throw new Error('Error loading users.')
    })
}

export const updateUser = (userInfo) => {
  return fetchWithHeaders('users', {
    method: 'PUT',
    body: JSON.stringify(userInfo),
  }).then(parseResponse)
}

export const unsubscribeUser = (customerId) => {
  return fetchWithHeaders(`subscriptions/unsubscribe?user=${customerId}`, {
    method: 'POST',
  }).then(parseResponse)
}

export const reactivateSubscription = (customerId) => {
  return fetchWithHeaders(`subscriptions/reactivate?user=${customerId}`, {
    method: 'POST',
  }).then(parseResponse)
}
