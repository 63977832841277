import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getBanners } from '../actions/banner'
import { BannerTable } from '../components/banner/bannerTable'
import _ from 'lodash'

class BannerContainer extends React.Component {
  componentDidMount() {
    this.props.getBanners()
  }

  onEdit = (id) => {
    this.props.history.push(`banner/${id}`)
  }

  onCreate = () => {
    this.props.history.push(`banner`)
  }

  render() {
    const { isFetching } = this.props
    return isFetching ? (
      <h6>Loading</h6>
    ) : (
      <React.Fragment>
        <BannerTable
          onEdit={this.onEdit}
          banners={_.values(this.props.banners)}
        />
        <div className="row ml-1 mt-3 mb-3 ">
          <button className="btn btn-primary" onClick={this.onCreate}>
            Add
          </button>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    banners: state.banner.data,
    isFetching: state.banner.isFetching,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBanners: () => {
      return dispatch(getBanners())
    },
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BannerContainer)
)
