import { fetchWithHeaders, parseResponse } from '../helpers/utils'
import queryString from 'query-string'

export const getSongDownloadHistory = (
  user,
  page,
  pageSize,
  name = undefined,
  exportAll = false,
  extension = undefined
) => {
  const params = {
    page,
    pageSize,
    name: name || undefined,
    exportAll: exportAll || undefined,
  }
  let url
  if (extension) {
    url = `v1/songs/download/history.${extension}?user=${user}&${queryString.stringify(params)}`
  } else {
    url = `v1/songs/download/history?user=${user}&${queryString.stringify(params)}`
  }
  return fetchWithHeaders(url, {
    method: 'GET',
  }).then(parseResponse)
}

export const resetSongDownloadCount = (user, rid, ttid) => {
  return fetchWithHeaders('v1/songs/download/history/reset_count', {
    method: 'POST',
    body: JSON.stringify({
      userId: user,
      rid,
      ttid,
    }),
  })
}
