import { fetchWithHeaders, parseResponse } from '../helpers/utils'

export const setMaintenanceMode = (enabled) => {
  return fetchWithHeaders('maintenance', {
    method: 'POST',
    body: JSON.stringify({
      enabled,
    }),
  }).then(parseResponse)
}

export const getMaintenanceMode = () => {
  return fetchWithHeaders('maintenance', {
    method: 'GET',
  }).then(parseResponse)
}
