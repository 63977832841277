import { fetchWithHeaders, parseResponse } from '../helpers/utils'

export const getPayments = (user, loadOptions) => {
  return fetchWithHeaders(`payments?user=${user}`, {
    method: 'GET',
  })
    .then(parseResponse)
    .then((data) => {
      return {
        data: data.payments,
      }
    })
    .catch(() => {
      throw new Error('Data Loading Error')
    })
}
