import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { getUsers } from '../../services/user'
import { useQueryParameters } from '../../hooks/use_query_parameters'
import DataGrid, { Column, Paging, Pager } from 'devextreme-react/data-grid'
import CustomStore from 'devextreme/data/custom_store'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.compact.css'
import 'devextreme/data/odata/store'
import './users.css'
import 'whatwg-fetch'
import moment from 'moment'

const cellRender = (data) => <Link to={`/user/${data.value}`}>Details</Link>

const defaultQueryParams = {
  email: '',
  first_name: '',
  last_name: '',
  phone: '',
  active: '',
}

function UsersComponent() {
  // To read & change the query parameters
  const [params, setParams] = useQueryParameters(defaultQueryParams)

  // Stores the form data in states
  const [email, setEmail] = useState(params.email)
  const [first_name, setFirstName] = useState(params.first_name)
  const [last_name, setLastName] = useState(params.last_name)
  const [phone, setPhone] = useState(params.phone)
  const [active, setActive] = useState(params.active)

  // Query parameters are more important than inner state,
  // so any change to the query parameters must be replicated
  // to the states
  useEffect(() => {
    setEmail(params.email)
    setFirstName(params.first_name)
    setLastName(params.last_name)
    setPhone(params.phone)
    setActive(params.active)
  }, [params])

  // The store that handles the data shown in the DataGrid
  // It's rebuilt when any of the params change, forcing the
  // reload of the data
  const dataSource = useMemo(() => {
    return {
      store: new CustomStore({
        load: function (loadOptions) {
          return getUsers(params, loadOptions)
        },
      }),
    }
  }, [params])

  // When there are no query parameters, we dispose the DataGrid
  // We're ignoring "active" here to avoid queries where all the users
  // or all the active/inactive users are queried
  const showDataGrid = !!(
    params.email ||
    params.first_name ||
    params.last_name ||
    params.phone
  )

  // When the form is submitted, we update the query parameters,
  // which rebuilds the data store and triggers a new query
  const submit = useCallback(
    (e) => {
      e.preventDefault()
      setParams({
        email,
        first_name,
        last_name,
        phone,
        active,
      })
    },
    [email, first_name, last_name, phone, active, setParams]
  )

  // Anytime the "active" radio buttons are clicked and the DataGrid
  // is displayed, we should immediately change the query parameter
  const onActiveChange = (e) => {
    setActive(e.target.value)
    setParams({
      ...params,
      active: e.target.value,
    })
  }

  return (
    <div className={'row'}>
      <div className={'col-md-4'}>
        <form onSubmit={submit}>
          <h4>Users Status</h4>
          <div className="form-inline">
            <label className="space">
              <input
                type="radio"
                checked={active === ''}
                name="active"
                className="form-control"
                value=""
                onChange={onActiveChange}
              />
              <span className="space">All</span>
            </label>
            <label className="space">
              <input
                type="radio"
                checked={active === 'true'}
                name="active"
                className="form-control"
                value="true"
                onChange={onActiveChange}
              />
              <span className="space">Active</span>
            </label>
            <label className="space">
              <input
                type="radio"
                checked={active === 'false'}
                name="active"
                className="form-control"
                value="false"
                onChange={onActiveChange}
              />
              <span className="space">Inactive</span>
            </label>
          </div>
          <hr />
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Email</label>
            <input
              type="text"
              className="form-control"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <p>- OR -</p>
          <div className="form-group">
            <label>
              First Name
              <input
                type="text"
                className="form-control"
                name="first_name"
                value={first_name}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </label>
            <label>
              Last Name
              <input
                type="text"
                className="form-control"
                name="last_name"
                value={last_name}
                onChange={(e) => setLastName(e.target.value)}
              />
            </label>
          </div>
          <p>- OR -</p>
          <div className="form-group">
            <label>
              Phone Number
              <input
                type="text"
                className="form-control"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </label>
          </div>
          <input type="submit" className="btn btn-primary" value="Submit" />
        </form>
      </div>
      <div className={'col-md-8'}>
        <div>
          {showDataGrid && (
            <DataGrid
              dataSource={dataSource}
              showBorders
              remoteOperations
              allowColumnResizing
              columnAutoWidth={false}
              columnResizingMode={'nextColumn'}
            >
              <Column dataField={'email'} />
              <Column dataField={'firstName'} />
              <Column dataField={'lastName'} />
              <Column
                caption={'Account Status'}
                dataField={'activeSubscription'}
              />
              <Column dataField={'gateway'} />
              <Column
                dataField={'lastRenewal'}
                dataType={'date'}
                calculateCellValue={(d) =>
                  moment.utc(d.lastRenewal).format('YYYY/MM/DD')
                }
              />
              <Column dataField={'userId'} cellRender={cellRender} />
              <Paging defaultPageSize={15} />
              <Pager showInfo showPageSizeSelector={false} />
            </DataGrid>
          )}
        </div>
      </div>
    </div>
  )
}

export default UsersComponent
