import React from 'react'
import DataGrid, { Column, Scrolling } from 'devextreme-react/data-grid'
import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import { isProd } from '../../helpers/utils'

export class LocalizationTableComponent extends React.Component {
  constructor(props) {
    super(props)
    this.handleOnEdit = this.handleOnEdit.bind(this)
  }

  getDataSource() {
    const { localizations } = this.props
    return new DataSource({
      store: new ArrayStore({
        type: 'array',
        key: 'id',
        data: localizations,
      }),
      reshapeOnPush: true,
    })
  }

  handleOnEdit(event) {
    this.props.onEdit(event.row.data.name)
  }

  render() {
    return (
      <React.Fragment>
        <br />
        <h4>Localization for Promo Pages</h4>
        <p>
          To add custom text for a specific promo page we need to follow the
          below example
        </p>
        <p>
          - We make sure we have created a promo (example: SERATO) in the DB and
          the page <b>/offer/serato</b> is accessible
        </p>
        <p>
          - Let's say we want to override the text for this key
          <p>
            <b>
              id: djcity.common.marketing.promo.title, defaultMessage: The
              record pool for the world's best DJs
            </b>
          </p>
          We just have to add a new key with id{' '}
          <p>
            djcity.common.marketing.promo.title<b>._serato</b>
          </p>
          So by just appending <b>._</b> and the promo name, in this case{' '}
          <b>serato</b>, we can override the default text for that page.
        </p>
        <br />
        <h4>Localization</h4>
        <DataGrid
          dataSource={this.getDataSource()}
          repaintChangesOnly={true}
          columnAutoWidth={true}
          showBorders={true}
        >
          <Scrolling mode={'infinite'} />
          <Column width="15%" dataField={'name'} />
          <Column
            width="45%"
            dataField={'value'}
            caption={'Default (US) Value'}
          />
          <Column
            width="5%"
            dataField={'availableLocales.GB'}
            caption=""
            alignment="center"
          />
          <Column
            width="5%"
            dataField={'availableLocales.JP'}
            caption=""
            alignment="center"
          />
          <Column
            width="5%"
            dataField={'availableLocales.ES'}
            caption=""
            alignment="center"
          />
          <Column
            width="5%"
            dataField={'availableLocales.DE'}
            caption=""
            alignment="center"
          />
          <Column
            width="10%"
            dataField={'createdOn'}
            dataType="date"
            format="yyyy/MM/dd"
            caption="Created At"
            alignment="center"
          />
          <Column
            width="10%"
            dataField={'lastModified'}
            dataType="date"
            format="yyyy/MM/dd"
            caption="Last Modified"
            alignment="center"
          />
          <Column
            width="10%"
            type={'buttons'}
            buttons={[
              {
                hint: isProd() ? 'View' : 'Edit',
                text: isProd() ? 'View' : 'Edit',
                onClick: this.handleOnEdit,
              },
            ]}
          />
        </DataGrid>
      </React.Fragment>
    )
  }
}
