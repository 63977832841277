import {
  NO_SORTING,
  SORT_ASCENDING,
  SORT_DESCENDING,
  TableHeader,
} from '../table_header'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles.module.sass'

export function Table({
  children,
  className,
  columns = [],
  headerClassName,
  onHeaderClick = [],
  sort = [],
}) {
  const headers = columns.map((columnHeader, i) => (
    <TableHeader
      className={headerClassName}
      key={i}
      onClick={onHeaderClick[i]}
      sort={sort[i]}
    >
      {columnHeader}
    </TableHeader>
  ))

  const style = {
    gridTemplateColumns: `repeat(${columns.length}, auto)`,
  }

  return (
    <div className={classNames(styles.table, className)} style={style}>
      {headers}
      {children}
    </div>
  )
}

Table.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.node),
  headerClassName: PropTypes.string,
  onHeaderClick: PropTypes.arrayOf(PropTypes.func),
  sort: PropTypes.arrayOf(
    PropTypes.oneOf([NO_SORTING, SORT_ASCENDING, SORT_DESCENDING])
  ),
}
