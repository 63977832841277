import { createAction } from '@reduxjs/toolkit'
import * as BannerService from '../services/banner'
import { toast } from 'react-toastify'
import { NOTIFICATIONS } from '../helpers/utils'
import { handleActionError, handleActionPayload } from './utils'

export const BANNER_ACTIONS = {
  // GET
  REQUEST_BANNERS: 'REQUEST_BANNERS',
  RECEIVE_BANNERS: 'RECEIVE_BANNERS',
  ERROR_RECEIVING_BANNERS: 'ERROR_RECEIVING_BANNERS',
  // CREATE
  CREATING_BANNER: 'CREATING_BANNER',
  CREATED_BANNER: 'CREATED_BANNER',
  ERROR_CREATING_BANNER: 'ERROR_CREATING_BANNER',
  // UPDATE
  UPDATING_BANNER: 'UPDATING_BANNER',
  UPDATED_BANNER: 'UPDATED_BANNER',
  ERROR_UPDATING_BANNER: 'ERROR_UPDATING_BANNER',
  // DELETE
  DELETING_BANNER: 'DELETING_BANNER',
  DELETED_BANNER: 'DELETED_BANNER',
  ERROR_DELETING_BANNER: 'ERROR_DELETING_BANNER',
  // GET REGIONS
  REQUEST_BANNER_REGIONS: 'REQUEST_BANNER_REGIONS',
  RECEIVE_BANNER_REGIONS: 'RECEIVE_BANNER_REGIONS',
  ERROR_RECEIVING_BANNER_REGIONS: 'ERROR_RECEIVING_BANNER_REGIONS',
  // UPDATE REGIONS
  UPDATING_BANNER_REGIONS: 'UPDATING_BANNER_REGIONS',
  UPDATED_BANNER_REGIONS: 'UPDATED_BANNER_REGIONS',
  ERROR_UPDATING_BANNER_REGIONS: 'ERROR_UPDATING_BANNER_REGIONS',
  // REMOVE REGIONS
  DELETING_BANNER_REGIONS: 'DELETING_BANNER_REGIONS',
  DELETED_BANNER_REGIONS: 'DELETED_BANNER_REGIONS',
  ERROR_DELETING_BANNER_REGIONS: 'ERROR_DELETING_BANNER_REGIONS',
}

// GET BANNERS
const requestBanners = createAction(BANNER_ACTIONS.REQUEST_BANNERS)
const receiveBanners = createAction(
  BANNER_ACTIONS.RECEIVE_BANNERS,
  handleActionPayload
)
const errorReceivingBanners = createAction(
  BANNER_ACTIONS.ERROR_RECEIVING_BANNERS,
  handleActionError
)
// CREATE BANNER
const creatingBanner = createAction(BANNER_ACTIONS.CREATING_BANNER)
const createdBanner = createAction(
  BANNER_ACTIONS.CREATED_BANNER,
  handleActionPayload
)
const errorCreatingBanner = createAction(
  BANNER_ACTIONS.ERROR_CREATING_BANNER,
  handleActionError
)
// UPDATE BANNER
const updatingBanner = createAction(BANNER_ACTIONS.UPDATING_BANNER)
const updatedBanner = createAction(
  BANNER_ACTIONS.UPDATED_BANNER,
  handleActionPayload
)
const errorUpdatingBanner = createAction(
  BANNER_ACTIONS.ERROR_UPDATING_BANNER,
  handleActionError
)

// DELETE BANNER
const deletingBanner = createAction(BANNER_ACTIONS.DELETING_BANNER)
const deletedBanner = createAction(
  BANNER_ACTIONS.DELETED_BANNER,
  handleActionPayload
)
const errorDeletingBanner = createAction(
  BANNER_ACTIONS.ERROR_DELETING_BANNER,
  handleActionError
)
// GET BANNER REGIONS
const getBannerRegion = createAction(
  BANNER_ACTIONS.REQUEST_BANNER_REGIONS,
  handleActionPayload
)
const receiveBannerRegions = createAction(
  BANNER_ACTIONS.RECEIVE_BANNER_REGIONS,
  handleActionPayload
)
const errorReceivingBannerRegions = createAction(
  BANNER_ACTIONS.ERROR_RECEIVING_BANNER_REGIONS,
  handleActionError
)

// UPDATE BANNER REGION
const updatingBannerRegion = createAction(
  BANNER_ACTIONS.UPDATING_BANNER_REGIONS
)
const updatedBannerRegion = createAction(
  BANNER_ACTIONS.UPDATED_BANNER_REGIONS,
  handleActionPayload
)
const errorUpdatingBannerRegion = createAction(
  BANNER_ACTIONS.ERROR_UPDATING_BANNER_REGIONS,
  handleActionError
)

// DELETE BANNER REGION
const deletingBannerRegion = createAction(
  BANNER_ACTIONS.DELETING_BANNER_REGIONS
)
const deletedBannerRegion = createAction(
  BANNER_ACTIONS.DELETED_BANNER_REGIONS,
  handleActionPayload
)
const errorDeletingBannerRegion = createAction(
  BANNER_ACTIONS.ERROR_DELETING_BANNER_REGIONS,
  handleActionError
)

export function getBanners() {
  return (dispatch) => {
    dispatch(requestBanners())
    return BannerService.getAllBannerAssets()
      .then((banners) => {
        return dispatch(receiveBanners(banners))
      })
      .catch((error) => dispatch(errorReceivingBanners(error)))
  }
}

export function createBanner(bannerAsset) {
  return (dispatch) => {
    dispatch(creatingBanner())
    return BannerService.createBannerAsset(bannerAsset)
      .then((banner) => {
        return dispatch(createdBanner({ banner }))
      })
      .catch((error) => {
        if (error instanceof TypeError) {
          toast.error('Banner file exceeds maximum size.', {
            containerId: NOTIFICATIONS.BANNER_NOTIFICATION,
          })
        } else {
          toast.error(error.message, {
            containerId: NOTIFICATIONS.BANNER_NOTIFICATION,
          })
        }
        return dispatch(errorCreatingBanner(error))
      })
  }
}

export function updateBanner(id, bannerAsset) {
  return (dispatch) => {
    dispatch(updatingBanner())
    return BannerService.updateBannerAsset(id, bannerAsset)
      .then((banner) => {
        return dispatch(updatedBanner({ banner }))
      })
      .catch((error) => {
        if (error instanceof TypeError) {
          toast.error('Banner file exceeds maximum size.', {
            containerId: NOTIFICATIONS.BANNER_NOTIFICATION,
          })
        } else {
          toast.error(error.message, {
            containerId: NOTIFICATIONS.BANNER_NOTIFICATION,
          })
        }
        dispatch(errorUpdatingBanner(error))
      })
  }
}

export function deleteBanner(bannerId) {
  return (dispatch) => {
    dispatch(deletingBanner({ bannerId }))
    return BannerService.deleteBannerAsset(bannerId)
      .then(() => {
        return dispatch(deletedBanner({ bannerId }))
      })
      .catch((error) => dispatch(errorDeletingBanner(error)))
  }
}

export function getBannerRegions(bannerId) {
  return (dispatch) => {
    dispatch(getBannerRegion({ bannerId }))
    return BannerService.getBannerAssetRegions(bannerId)
      .then((regions) => {
        dispatch(receiveBannerRegions({ regions, bannerId }))
      })
      .catch((error) => dispatch(errorReceivingBannerRegions(error)))
  }
}

export function updateBannerRegion(bannerId, regionId, info) {
  return (dispatch) => {
    dispatch(updatingBannerRegion({ bannerId }))
    return BannerService.updateBannerAssetRegion(bannerId, regionId, info)
      .then((regionInfo) => {
        return dispatch(
          updatedBannerRegion({ bannerId, regionId, region: regionInfo })
        )
      })
      .catch((error) => dispatch(errorUpdatingBannerRegion(error)))
  }
}

export function deleteBannerRegion(bannerId, regionId) {
  return (dispatch) => {
    dispatch(deletingBannerRegion({ bannerId, regionId }))
    return BannerService.deleteBannerAssetRegion(bannerId, regionId)
      .then(() => {
        return dispatch(deletedBannerRegion({ bannerId, regionId }))
      })
      .catch((error) => dispatch(errorDeletingBannerRegion(error)))
  }
}
