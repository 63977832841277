import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { clearCache } from '../actions/cache'
import { NOTIFICATIONS } from '../helpers/utils'
import { toast, ToastContainer } from 'react-toastify'

const HOTBOX = 'HOTBOX'
const NEW_RELEASES = 'NEW_RELEASES'
const SONGS = 'SONGS'
const UPDATED = 'UPDATED'
const TOP_DOWNLOADS = 'TOP_DOWNLOADS'
const EXCLUSIVES = 'EXCLUSIVES'
const TRENDS = 'TRENDS'
const RESOURCES = 'RESOURCES'
const TAGS = 'TAGS'
const IP = 'IP'
const AUTH_REQ = 'xForwardedForCount:'

const CACHE_OPTIONS = [
  {
    key: null,
    name: 'Clear Full Cache',
  },
  {
    key: HOTBOX,
    name: 'Hotbox',
  },
  {
    key: NEW_RELEASES,
    name: 'New Releases',
  },
  {
    key: SONGS,
    name: 'Songs',
  },
  {
    key: UPDATED,
    name: 'Updated',
  },
  {
    key: TOP_DOWNLOADS,
    name: 'Top Downloads',
  },
  {
    key: EXCLUSIVES,
    name: 'Exclusives',
  },
  {
    key: TRENDS,
    name: 'Playlists',
  },
  {
    key: RESOURCES,
    name: 'String Resources',
  },
  {
    key: TAGS,
    name: 'Tags',
  },
  {
    key: IP,
    name: 'IP Lookup',
  },
  {
    key: AUTH_REQ,
    name: 'Auth requests',
  },
]

const CacheContainer = (props) => {
  return (
    <>
      <h4>Cache Management</h4>
      <div className={'row'}>
        {CACHE_OPTIONS.map((item) => {
          return (
            <div className={'col-md-4'}>
              <button
                className={'mt-2 mb-2 btn btn-info '}
                onClick={() => props.clearCache(item.key)}
              >
                {item.name}
              </button>
            </div>
          )
        })}
      </div>
      <ToastContainer
        enableMultiContainer
        containerId={NOTIFICATIONS.BANNER_NOTIFICATION}
        autoClose={5000}
        position={toast.POSITION.BOTTOM_RIGHT}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearCache: (key) => {
      return dispatch(clearCache(key))
    },
  }
}

export default withRouter(connect(null, mapDispatchToProps)(CacheContainer))
