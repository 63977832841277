import * as ACTION_CONSTANTS from '../actions/action_constants'

const defaultState = {
  user: '',
  loading: false,
}

const userDetail = (state = defaultState, action) => {
  switch (action.type) {
    case ACTION_CONSTANTS.REQUEST_USER:
    case ACTION_CONSTANTS.REQUEST_REACTIVATE_USER:
    case ACTION_CONSTANTS.REQUEST_DEACTIVATE_USER:
    case ACTION_CONSTANTS.REQUEST_IMPERSONATE_USER:
    case ACTION_CONSTANTS.REQUEST_CHANGE_ON_RENEWAL_STATUS:
    case ACTION_CONSTANTS.REQUEST_DEACTIVATE_IMMEDIATELY:
    case ACTION_CONSTANTS.UPDATING_USER_INFO:
      return {
        ...state,
        loading: true,
      }
    case ACTION_CONSTANTS.RECEIVE_USER:
    case ACTION_CONSTANTS.REACTIVATE_USER:
    case ACTION_CONSTANTS.DEACTIVATE_USER:
    case ACTION_CONSTANTS.IMPERSONATE_USER:
    case ACTION_CONSTANTS.RECEIVE_CHANGE_ON_RENEWAL_STATUS:
    case ACTION_CONSTANTS.DEACTIVATE_IMMEDIATELY:
      return {
        user: action.user,
        loading: false,
      }
    case ACTION_CONSTANTS.UPDATED_USER_INFO:
      return {
        user: {
          ...state.user,
          ...action.user,
        },
        loading: false,
      }
    default:
      return state
  }
}

export default userDetail
