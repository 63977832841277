import { PROMO_CODE_ACTIONS } from '../actions/promoCodes'
import * as _ from 'lodash'

let promoCodeState = {
  data: {},
  metadata: null,
  error: null,
  isFetching: false,
  filters: {},
}

const promoCodeReducer = (state = promoCodeState, action) => {
  switch (action.type) {
    case PROMO_CODE_ACTIONS.REQUEST_PROMO_CODES:
      return {
        ...state,
        isFetching: true,
      }
    case PROMO_CODE_ACTIONS.RECEIVE_PROMO_CODES:
      return {
        ...state,
        isFetching: false,
        data: _.keyBy(action.payload.promoCodes, 'promoCodeId'),
        metadata: action.payload.metadata,
      }
    case PROMO_CODE_ACTIONS.UPDATED_PROMO_CODES:
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          ..._.keyBy([action.payload.promoCode], 'promoCodeId'),
        },
      }
    default:
      return state
  }
}
export default promoCodeReducer
