import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

class UserFeaturesComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      enabledFeatures: this.props.features
        .filter((y) => y.enabled)
        .map((x) => {
          return x.featureuuid
        }),
    }
    this.onUpdateFeatures = this.onUpdateFeatures.bind(this)
  }

  onUpdateFeatures() {
    this.props.updateUserFeatures(this.state.enabledFeatures)
  }

  onChange(e) {
    const enabledFeatures = this.state.enabledFeatures
    if (e.target.checked) {
      if (!enabledFeatures.includes(e.target.value)) {
        enabledFeatures.push(e.target.value)
      }
    } else {
      const index = enabledFeatures.indexOf(e.target.value)
      enabledFeatures.splice(index, 1)
    }
    this.setState({ enabledFeatures: enabledFeatures })
  }

  renderUserFeatureForm() {
    const { features } = this.props
    return _.orderBy(features, ['label'], ['asc']).map((item, key) => {
      return (
        <tr key={key}>
          <td>{item.label}</td>
          <td>{item.feature}</td>
          <td style={{ textAlign: 'center' }}>
            <input
              className="form-check-input"
              type="checkbox"
              value={item.featureuuid}
              defaultChecked={item.enabled ? 'checked' : ''}
              disabled={item.enabledForEveryone ? 'disabled' : ''}
              id={item.featureuuid}
              onChange={this.onChange.bind(this)}
            />
          </td>
        </tr>
      )
    })
  }

  render() {
    const { loading, success, errorMessage } = this.props
    return (
      <div>
        {success && (
          <div className="alert alert-success" role="alert">
            Features updated successfully
          </div>
        )}
        {errorMessage && (
          <div className="alert alert-warning" role="alert">
            Error: {errorMessage}
          </div>
        )}
        {!loading && (
          <div>
            <div className="align-items-end row col-4 mb-3 mt2">
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.onUpdateFeatures}
              >
                Save Changes
              </button>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">App</th>
                  <th scope="col">Feature</th>
                  <th style={{ textAlign: 'center' }} scope="col">
                    Enabled
                  </th>
                </tr>
              </thead>
              <tbody>{this.renderUserFeatureForm()}</tbody>
            </table>
          </div>
        )}
      </div>
    )
  }
}

UserFeaturesComponent.propTypes = {
  features: PropTypes.any,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  errorMessage: PropTypes.string,
  updateUserFeatures: PropTypes.func,
}

export default UserFeaturesComponent
