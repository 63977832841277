import {
  fetchWithHeaders,
  parseResponse,
  handleServerError,
} from '../helpers/utils'
import queryString from 'query-string'

function requestUsers() {
  return {
    type: 'REQUEST_USERS',
  }
}

function receiveUsers(users) {
  return {
    type: 'RECEIVE_USERS',
    users,
  }
}

export function loadUsers(params = {}) {
  return (dispatch) => {
    dispatch(requestUsers())
    return fetchWithHeaders(`users?${queryString.stringify(params)}`, {
      method: 'GET',
    })
      .then(parseResponse)
      .then((data) => dispatch(receiveUsers(data.users)))
      .catch(handleServerError)
  }
}
