import {
  fetchWithHeaders,
  handleServerError,
  parseResponse,
  getQueryParams,
} from '../helpers/utils'

export const getLocalizations = (
  name = 'djcity',
  includeAll = false,
  fullSearch = false,
  cache = false,
  untranslated = false
) => {
  return fetchWithHeaders(
    `v1/resources?${getQueryParams({ name, includeAll, fullSearch, cache, untranslated })}`,
    {
      method: 'GET',
    }
  )
    .then(parseResponse)
    .catch(handleServerError)
}

export const updateLocalization = (
  name,
  resources,
  replaceAll = false,
  isReadyForProd = false
) => {
  return fetchWithHeaders('v1/resources', {
    method: 'PUT',
    body: JSON.stringify({
      name,
      resources,
      replaceAll,
      isReadyForProd,
    }),
  })
    .then(parseResponse)
    .catch(handleServerError)
}
