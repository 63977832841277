import React from 'react'
import PropTypes from 'prop-types'

class FeatureDetail extends React.Component {
  constructor(props) {
    super(props)
    this.onToggleFeature = this.onToggleFeature.bind(this)
  }

  onToggleFeature() {
    const { feature, onFeatureUpdate } = this.props
    if (feature) {
      onFeatureUpdate(feature.featureId, !feature.enabledForEveryone)
    }
  }

  render() {
    const { feature } = this.props
    return (
      <div>
        <div className="card pt-2 mt-5 w75">
          <div className="card-body">
            <h6 className="card-title">Details</h6>
            <ul className="list-group">
              <li className="list-group-item">
                <span className="text-muted">Feature:</span> {feature.name}
              </li>
              <li className="list-group-item">
                <span className="text-muted">App:</span> {feature.appName}
              </li>
            </ul>
            <div className="row ml-1 mt-3">
              <button
                className="btn btn-sm btn-primary"
                data-toggle="modal"
                data-target="#confirm-toggle"
              >
                {`${feature.enabledForEveryone === false ? 'Enable' : 'Disable'}`}{' '}
                for Everyone
              </button>
            </div>
          </div>
        </div>
        <div className="modal" id="confirm-toggle" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Enabling Feature</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure you would like to
                  <span className="font-weight-bold">
                    {`${feature.enabledForEveryone === false ? ' enable ' : ' disable '}`}
                  </span>
                  feature for everyone?
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={this.onToggleFeature}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

FeatureDetail.propTypes = {
  feature: PropTypes.object,
}

export default FeatureDetail
