import * as MaintenanceService from '../services/maintenance'
import { toast } from 'react-toastify'
import { NOTIFICATIONS } from '../helpers/utils'

export const getMaintenanceMode = () => async (dispatch) => {
  try {
    const { enabled } = await MaintenanceService.getMaintenanceMode()
    return enabled
  } catch (error) {
    toast.error(error.message, {
      containerId: NOTIFICATIONS.BANNER_NOTIFICATION,
    })
  }
}

export const setMaintenanceMode = (value) => async (dispatch) => {
  try {
    const maintenance = await MaintenanceService.setMaintenanceMode(value)
    toast.success(`Maintenance Mode: ${maintenance.enabled ? 'ON' : 'OFF'}`, {
      containerId: NOTIFICATIONS.BANNER_NOTIFICATION,
    })
  } catch (error) {
    toast.error(error.message, {
      containerId: NOTIFICATIONS.BANNER_NOTIFICATION,
    })
  }
}
