import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'
import thunk from 'redux-thunk'
import { history } from './history'
import storedReducer from './helpers/storedReducer'
import reducers from './reducers'

const appReducer = combineReducers({
  ...reducers,
  form: formReducer,
  router: connectRouter(history),
})

const initialState = {
  ...JSON.parse(window.localStorage.getItem(storedReducer.storageKey)),
}

const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

export const store = createStore(
  appReducer,
  initialState,
  composeEnhancers(
    applyMiddleware(thunk),
    applyMiddleware(routerMiddleware(history))
  )
)
