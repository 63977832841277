import {
  fetchWithHeaders,
  parseResponse,
  handleServerError,
} from '../helpers/utils'

export const getAllSecurityGroups = async () => {
  try {
    const response = await fetchWithHeaders('v1/security_groups', {
      method: 'GET',
    })
    const data = await parseResponse(response)
    return data
  } catch (err) {
    handleServerError(err)
  }
}

export const getUserSecurityGroups = async (userId) => {
  try {
    const response = await fetchWithHeaders(`users/${userId}/security_groups`, {
      method: 'GET',
    })
    const data = await parseResponse(response)
    return data
  } catch (err) {
    handleServerError(err)
  }
}

export const createUserSecurityGroup = async (userId, securityGroupId) => {
  try {
    const response = await fetchWithHeaders(
      `users/${userId}/security_groups/${securityGroupId}`,
      {
        method: 'POST',
      }
    )
    const data = await parseResponse(response)
    return data
  } catch (err) {
    handleServerError(err)
  }
}

export const removeUserSecurityGroup = async (userId, securityGroupId) => {
  try {
    const response = await fetchWithHeaders(
      `users/${userId}/security_groups/${securityGroupId}`,
      {
        method: 'DELETE',
      }
    )
    const data = await parseResponse(response)
    return data
  } catch (err) {
    handleServerError(err)
  }
}
