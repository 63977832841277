import {
  fetchWithHeaders,
  parseResponse,
  handleServerError,
  defaultErrorMessage,
} from '../helpers/utils'

function requestLogin() {
  return {
    type: 'REQUEST_LOGIN',
  }
}

function receiveLogin(auth) {
  return {
    type: 'LOGIN',
    auth,
  }
}

function checkCSUser(userData) {
  const isCSUser =
    (userData.securityGroups || []).indexOf('customer-service') >= 0
  if (!isCSUser) {
    defaultErrorMessage('Unauthorized', "You don't have permission to log in")
  }
  return isCSUser
}

export function login(username, password) {
  return (dispatch) => {
    dispatch(requestLogin())

    return fetchWithHeaders('v1/auth', {
      method: 'POST',
      body: JSON.stringify({ username, password }),
    })
      .then(parseResponse)
      .then((userData) => {
        if (!checkCSUser(userData)) {
          return null
        }
        return dispatch(receiveLogin(userData))
      })
      .catch(handleServerError)
  }
}

export function logout() {
  localStorage.clear()
  return {
    type: 'LOGOUT',
  }
}

function requestGetUserInfo() {
  return {
    type: 'REQUEST_USER_INFO',
    loading: true,
  }
}

function responseGetUserInfo(auth) {
  return {
    type: 'GET_USER_INFO',
    auth,
  }
}

export function getUserInfo() {
  return (dispatch) => {
    dispatch(requestGetUserInfo())
    return fetchWithHeaders('users/profile', { method: 'GET' })
      .then(parseResponse)
      .then((response) => {
        dispatch(responseGetUserInfo(response))
      })
      .catch((err) => {
        dispatch(logout())
        handleServerError(err)
      })
  }
}
