import {
  fetchWithHeaders,
  getQueryParams,
  parseResponse,
} from '../helpers/utils'

export const getPromoCodes = (filters) => {
  return fetchWithHeaders(`promos/codes?${getQueryParams(filters)}`, {
    method: 'GET',
  }).then(parseResponse)
}

export const updatePromoCode = (promoCodeId, request) => {
  return fetchWithHeaders(`promos/codes/${promoCodeId}`, {
    method: 'PUT',
    body: JSON.stringify(request),
  }).then(parseResponse)
}
