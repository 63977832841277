import React from 'react'
import './audit-logs.css'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.compact.css'
import 'devextreme/data/odata/store'
import DataGrid, {
  Column,
  RemoteOperations,
  Scrolling,
  HeaderFilter,
} from 'devextreme-react/data-grid'
import CustomStore from 'devextreme/data/custom_store'
import 'whatwg-fetch'
import { getAuditLog } from '../../services/audit'
import moment from 'moment'

const cellRender = (data) => `${data.value === null ? '$0.00' : data.value}`

class AuditLogsComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      usersStatus: '',
      submitted: false,
    }

    this.getAuditLogs = this.getAuditLogs.bind(this)
  }

  getAuditLogs() {
    return (this.dataSource = {
      store: new CustomStore({
        load: function (loadOptions) {
          return getAuditLog(loadOptions)
        },
      }),
    })
  }

  render() {
    const { params } = this.state
    return (
      <div className="px-3">
        <h4>Audit Logs</h4>
        <DataGrid
          dataSource={this.getAuditLogs(params)}
          showBorders={true}
          columnAutoWidth={true}
        >
          <HeaderFilter visible={true} />
          <Scrolling mode={'infinite'} />
          <Column
            dataField={'date'}
            dataType={'date'}
            calculateCellValue={(d) => moment.utc(d.date).format('YYYY/MM/DD')}
          />
          <Column dataField={'adminEmail'}>
            <HeaderFilter allowSearch={true} />
          </Column>
          <Column dataField={'userEmail'} />
          <Column dataField={'action'} />
          <Column dataField={'userId'} />
          <Column dataField={'amount'} cellRender={cellRender} />
          <Column dataField={'country'} />
          <RemoteOperations sorting={false} paging={false} />
        </DataGrid>
      </div>
    )
  }
}

export default AuditLogsComponent
