import React, { Component } from 'react'

export default class FieldFileInput extends Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  onChange(e) {
    const {
      input: { onChange },
    } = this.props
    onChange(e.target.files[0])
  }

  render() {
    const { label } = this.props
    return (
      <React.Fragment>
        <label>{label}</label>
        <div>
          <input
            type="file"
            accept=".jpg, .png, .jpeg, .gif"
            onChange={this.onChange}
          />
        </div>
      </React.Fragment>
    )
  }
}
