import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { logout as logoutAction } from '../actions/auth'

import { Nav, Image } from 'react-bootstrap'

function MenuContainer({ children }) {
  const auth = useSelector((state) => state.auth)
  const isSuperAdmin =
    auth.userInfo &&
    auth.userInfo.securityGroups &&
    auth.userInfo.securityGroups.includes('super-admin')
  const [email, setEmail] = useState('')
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = useLocation()

  const handleEmailChange = useCallback(
    (e) => {
      setEmail(e.target.value)
    },
    [setEmail]
  )

  const searchUser = useCallback(
    (e) => {
      e.preventDefault()
      history.push(`/users?email=${encodeURIComponent(email)}`)
    },
    [history, email]
  )

  const logout = useCallback(() => dispatch(logoutAction()), [dispatch])

  const menuItems = [
    ['/users', 'Users'],
    ['/free-accounts', 'Free Accounts'],
    ['/audit', 'Audit'],
    ['/features', 'Features'],
    ['/pages', 'Pages'],
    ['/promos', 'Promos'],
    ['/promo-codes', 'Promo Codes'],
    ['/localizations', 'Localizations'],
    ['/banners', 'Banners'],
    ['/cache', 'Cache'],
  ]

  return (
    <div className="grid">
      <div className="sidebar px-2">
        <Nav className="flex-column">
          <Nav.Link href="/">
            <Image
              src="https://d1udlrob6czrvo.cloudfront.net/logo/3/djcity_1_350.png"
              alt="djcity"
              fluid
            />
          </Nav.Link>
          <hr />
          {menuItems.map(([href, val]) => (
            <Nav.Link key={href} href={href} active={href === pathname}>
              {val}
            </Nav.Link>
          ))}
          {isSuperAdmin && (
            <Nav.Link href="/maintenance" active={pathname === '/maintenance'}>
              Maintenance
            </Nav.Link>
          )}
          <hr />
          <div className="form-inline my-2 left-space">
            <form onSubmit={searchUser}>
              <input
                type="text"
                className="form-control w-100"
                placeholder="Email..."
                onChange={handleEmailChange}
              />
            </form>
          </div>
          <hr />
          <div className="form-inline my-2">
            {auth.loading ? (
              <i className="fa fa-refresh fa-spin fa-2x fa-fw"></i>
            ) : (
              <div className="dropdown">
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="far fa-user"></i> {auth.userInfo.firstName}
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <span
                    className="dropdown-item"
                    onClick={logout}
                    style={{ cursor: 'pointer' }}
                  >
                    Logout
                  </span>
                </div>
              </div>
            )}
          </div>
        </Nav>
      </div>
      <div className="content">{children}</div>
    </div>
  )
}

export default MenuContainer
