import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PageTableComponent from '../components/pages/pageTable'
import { deletePage, getPages, updatePage, createPage } from '../actions/page'
import _ from 'lodash'

class PageContainer extends React.Component {
  constructor(props) {
    super(props)

    this.handleOnCreate = this.handleOnCreate.bind(this)
    this.handleOnDeletePage = this.handleOnDeletePage.bind(this)
    this.handleOnEditPage = this.handleOnEditPage.bind(this)

    this.state = {
      showModal: false,
      deleteConfirmation: false,
      page: {},
    }
  }

  // Click Functions
  handleOnDeletePage(pageId) {
    const {
      pages: { data },
    } = this.props
    const page = _.find(data, ['id', pageId])
    this.setState({ deleteConfirmation: true, page })
  }

  handleOnEditPage(pageId) {
    const {
      pages: { data },
    } = this.props
    const page = _.find(data, ['id', pageId])
    this.setState({ showModal: true, page })
  }

  handleOnCreate() {
    this.setState({
      showModal: true,
      page: { name: '', url: '', external_link: '' },
    })
  }

  // Action Dispatcher
  onUpdate(action) {
    const { updatePage, createPage, deletePage } = this.props
    const { page } = this.state
    this.setState({ showModal: false, deleteConfirmation: false })
    switch (action) {
      case 'update':
        return updatePage(page.id, {
          name: page.name,
          url: page.url,
          externalLink: page.external_link,
        })
      case 'delete':
        return deletePage(page.id)
      case 'create':
        return createPage({
          name: page.name,
          url: page.url,
          externalLink: page.external_link,
        })
      default:
        break
    }
  }

  componentDidMount() {
    this.props.getPages()
  }

  render() {
    const { deleteConfirmation, showModal, page } = this.state
    return (
      <React.Fragment>
        <div style={{ position: 'absolute' }}>
          <PageTableComponent
            pages={this.props.pages.data}
            editPage={this.handleOnEditPage}
            deletePage={this.handleOnDeletePage}
          />
          <button
            className="mt-3 btn btn-sm btn-primary"
            data-toggle="modal"
            onClick={this.handleOnCreate}
          >
            Add Page
          </button>
        </div>
        <div
          className="modal"
          id="remove-toggle"
          tabIndex="-1"
          role="dialog"
          style={{ display: deleteConfirmation ? 'block' : 'none' }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Remove Page</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h6>Are you sure you would like to delete page?</h6>
                <button
                  className="mt-3 btn btn-sm btn-primary"
                  data-toggle="modal"
                  onClick={() => this.onUpdate('delete')}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal"
          id="create-toggle"
          tabIndex="-1"
          role="dialog"
          style={{ display: showModal ? 'block' : 'none' }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create Page</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState({ showModal: false })}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <label>Name</label>
                <input
                  id="name"
                  type="text"
                  placeholder={'DJs Make Beats'}
                  value={page.name}
                  onChange={(e) =>
                    this.setState({ page: { ...page, name: e.target.value } })
                  }
                  className="form-control"
                />
                <label>Url</label>
                <input
                  id="url"
                  type="text"
                  placeholder={'/djsmakebeats'}
                  value={page.url}
                  onChange={(e) =>
                    this.setState({ page: { ...page, url: e.target.value } })
                  }
                  className="form-control"
                />
                <label>External Link</label>
                <input
                  id="external_link"
                  type="text"
                  placeholder={'https://djcity.com'}
                  value={page.external_link}
                  onChange={(e) =>
                    this.setState({
                      page: { ...page, external_link: e.target.value },
                    })
                  }
                  className="form-control"
                />
                <button
                  className="mt-3 btn btn-sm btn-primary"
                  data-toggle="modal"
                  onClick={() => this.onUpdate(page.id ? 'update' : 'create')}
                >
                  {page.id ? 'Update' : 'Create'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    pages: state.pages,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updatePage: (pageId, pageDetails) => {
      return dispatch(updatePage(pageId, pageDetails))
    },
    createPage: (pageDetails) => {
      return dispatch(createPage(pageDetails))
    },
    deletePage: (pageId) => {
      return dispatch(deletePage(pageId))
    },
    getPages: (loadOptions) => {
      return dispatch(getPages())
    },
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PageContainer)
)
