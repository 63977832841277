import { fetchWithHeaders, parseResponse } from '../helpers/utils'

export const getBannerAssets = () => {
  return fetchWithHeaders('v1/banner_assets', {
    method: 'GET',
  })
    .then(parseResponse)
    .then((data) => {
      return {
        banners: data,
      }
    })
}

export const getAllBannerAssets = () => {
  return fetchWithHeaders('v1/banner_assets/all', {
    method: 'GET',
  })
    .then(parseResponse)
    .then((data) => {
      return {
        banners: data,
      }
    })
}

export const createBannerAsset = (bannerAsset) => {
  const formData = new FormData()

  for (const name in bannerAsset) {
    formData.append(name, bannerAsset[name])
  }
  return fetchWithHeaders(
    'v1/banner_assets',
    {
      method: 'POST',
      body: formData,
    },
    true
  )
    .then(parseResponse)
    .then((data) => {
      return {
        banner: data,
      }
    })
}

export const updateBannerAsset = (bannerId, bannerAsset) => {
  const formData = new FormData()

  for (const name in bannerAsset) {
    formData.append(name, bannerAsset[name])
  }
  return fetchWithHeaders(
    `v1/banner_assets/${bannerId}`,
    {
      method: 'PUT',
      body: formData,
    },
    true
  ).then(parseResponse)
}

export const deleteBannerAsset = (bannerId) => {
  return fetchWithHeaders(`v1/banner_assets/${bannerId}`, {
    method: 'DELETE',
  }).then(parseResponse)
}

export const getBannerAssetRegions = (bannerId) => {
  return fetchWithHeaders(`v1/banner_assets/${bannerId}/regions`, {
    method: 'GET',
  }).then(parseResponse)
}

export const deleteBannerAssetRegion = (bannerId, regionId) => {
  return fetchWithHeaders(`v1/banner_assets/${bannerId}/regions/${regionId}`, {
    method: 'DELETE',
  }).then(parseResponse)
}

export const updateBannerAssetRegion = (bannerId, regionId, criteria) => {
  return fetchWithHeaders(`v1/banner_assets/${bannerId}/regions/${regionId}`, {
    method: 'POST',
    body: JSON.stringify(criteria),
  }).then(parseResponse)
}
