import { createAction } from '@reduxjs/toolkit'
import * as CrateService from '../services/crate'

export const CRATE_ACTIONS = {
  // User crate
  REQUEST_CRATE: 'REQUEST_CRATE',
  RECEIVE_CRATE: 'RECEIVE_CRATE',
  ERROR_RECEIVING_CRATE: 'ERROR_RECEIVING_CRATE',
}

// Crate actions
const requestCrate = createAction(CRATE_ACTIONS.REQUEST_CRATE)
const receiveCrate = createAction(CRATE_ACTIONS.RECEIVE_CRATE)
const errorReceivingCrate = createAction(CRATE_ACTIONS.ERROR_RECEIVING_CRATE)

export function getCrate(userId, skipItems, pageSize) {
  return async (dispatch) => {
    dispatch(requestCrate())
    try {
      const result = await CrateService.getCrate(userId, skipItems, pageSize)
      const {
        items,
        metadata: { totalItems },
      } = result
      dispatch(
        receiveCrate({
          items,
          pageSize,
          skipItems,
          totalItems,
          userId,
        })
      )

      return result
    } catch (error) {
      return dispatch(errorReceivingCrate(error))
    }
  }
}

export function restoreCrate(userId, date, itemType) {
  return async (dispatch, getState) => {
    const { skipItems, pageSize } = getState().crate
    dispatch(requestCrate())
    await CrateService.restoreCrate(userId, date, itemType)
    return dispatch(getCrate(userId, skipItems, pageSize))
  }
}

export const restoreDeletedCrate = (userId, date) =>
  restoreCrate(userId, date, 'deleted')
export const restoreQueuedCrate = (userId, date) =>
  restoreCrate(userId, date, 'queued')
