import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  deleteBanner,
  createBanner,
  updateBanner,
  getBannerRegions,
  getBanners,
  updateBannerRegion,
  BANNER_ACTIONS,
} from '../actions/banner'
import _ from 'lodash'
import BannerDetailForm from '../components/banner/bannerDetailForm'
import { BannerRegions } from '../components/banner/bannerRegions'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { NOTIFICATIONS } from '../helpers/utils'

class BannerDetailContainer extends React.Component {
  componentDidMount() {
    const {
      getBannerRegion,
      banners,
      getBanners,
      match: {
        params: { id },
      },
    } = this.props
    if (id) {
      const banner = banners[id]
      if (!banner) {
        getBanners()
      }
      getBannerRegion(id)
    }
  }

  onSubmit = (values) => {
    const {
      match: {
        params: { id },
      },
      updateBanner,
      createBanner,
    } = this.props
    const info = {
      altTag: values.altTag,
      active: values.active || false,
      mobileActive: values.mobileActive || false,
      mobileFile: values.mobileFile || '',
      webFile: values.webFile || '',
      targetBlank: values.targetBlank || false,
    }
    if (values.webAction) {
      info.webAction = values.webAction
    }
    if (values.mobileAction) {
      info.mobileAction = values.mobileAction
    }
    if (id) {
      return updateBanner(id, info).then((action) => {
        if (action && action.type === BANNER_ACTIONS.UPDATED_BANNER) {
          toast.success('Banner Information Updated.', {
            containerId: NOTIFICATIONS.BANNER_NOTIFICATION,
          })
        }
      })
    } else {
      return createBanner(info).then((action) => {
        if (action && action.type === BANNER_ACTIONS.CREATED_BANNER) {
          this.props.history.push(`/banners`)
        }
      })
    }
  }

  onDelete = () => {
    const {
      match: {
        params: { id },
      },
      deleteBanner,
    } = this.props
    deleteBanner(id).then((info) => {
      this.props.history.push(`/banners`)
    })
  }

  render() {
    const { banners } = this.props
    let initialValues = {}
    let banner = {}
    const {
      match: {
        params: { id },
      },
    } = this.props
    if (id) {
      banner = banners[id]
      if (!banner || banner.isLoading) {
        return 'Loading'
      } else {
        initialValues = {
          baid: banner.baid,
          altTag: _.get(banner, 'webAction.altTag', null),
          webFile: _.get(banner, 'webAction.assetUrl', null),
          webAssetUrl: _.get(banner, 'webAction.assetUrl', null),
          active: _.get(banner, 'webAction.active', null),
          targetBlank: _.get(banner, 'webAction.targetBlank', null),
          mobileFile: _.get(banner, 'mobileAction.assetUrl', null),
          mobileAssetUrl: _.get(banner, 'mobileAction.assetUrl', null),
          mobileActive: _.get(banner, 'mobileAction.active', null),
        }
      }
    }
    return (
      <>
        <BannerDetailForm
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          onDelete={this.onDelete}
        />
        {id && (
          <BannerRegions
            regions={_.values(banner.regions)}
            bannerId={id}
            updateBannerRegion={this.props.updateBannerRegion}
          />
        )}
        <ToastContainer
          enableMultiContainer
          containerId={NOTIFICATIONS.BANNER_NOTIFICATION}
          autoClose={5000}
          position={toast.POSITION.BOTTOM_RIGHT}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    banners: state.banner.data,
    isFetching: state.banner.isFetching,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getBannerRegion: (bannerId) => {
      return dispatch(getBannerRegions(bannerId))
    },
    createBanner: (bannerInfo) => {
      return dispatch(createBanner(bannerInfo))
    },
    deleteBanner: (bannerId) => {
      return dispatch(deleteBanner(bannerId))
    },
    updateBanner: (id, bannerInfo) => {
      return dispatch(updateBanner(id, bannerInfo))
    },
    updateBannerRegion: (bannerId, regionId, info) => {
      return dispatch(updateBannerRegion(bannerId, regionId, info))
    },
    deleteBannerRegion: (bannerId, regionId) => {
      return dispatch(deleteBanner(bannerId, regionId))
    },
    getBanners: () => {
      return dispatch(getBanners())
    },
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BannerDetailContainer)
)
