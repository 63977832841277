import * as PageService from '../services/pages'

export const PAGE_ACTIONS = {
  //VIEW
  REQUEST_PAGES: 'REQUEST_PAGES',
  RECEIVE_PAGES_SUCCESS: 'RECEIVE_PAGES_SUCCESS',
  RECEIVE_PAGES_ERROR: 'RECEIVE_PAGES_ERROR',
  // CREATE
  REQUEST_PAGE_CREATE: 'REQUEST_PAGE_CREATE',
  RECEIVE_PAGE_CREATE_SUCCESS: 'RECEIVE_PAGE_CREATE_SUCCESS',
  RECEIVE_PAGE_CREATE_ERROR: 'RECEIVE_PAGE_CREATE_ERROR',
  // DELETE
  REQUEST_PAGE_DELETE: 'REQUEST_PAGE_DELETE',
  RECEIVE_PAGE_DELETE_SUCCESS: 'RECEIVE_PAGE_DELETE_SUCCESS',
  RECEIVE_PAGE_DELETE_ERROR: 'RECEIVE_PAGE_DELETE_ERROR',
  // UPDATE
  REQUEST_PAGE_UPDATE: 'REQUEST_PAGE_UPDATE',
  RECEIVE_PAGE_UPDATE_SUCCESS: 'RECEIVE_PAGE_UPDATE_SUCCESS',
  RECEIVE_PAGE_UPDATE_ERROR: 'RECEIVE_PAGE_UPDATE_ERROR',
}

// VIEW
export function requestPages() {
  return {
    type: PAGE_ACTIONS.REQUEST_PAGES,
  }
}

export function receivePages(pages) {
  return {
    type: PAGE_ACTIONS.RECEIVE_PAGES_SUCCESS,
    pages,
  }
}

export function receivePagesError(error) {
  return {
    type: PAGE_ACTIONS.RECEIVE_PAGES_ERROR,
    error,
  }
}

// CREATE
export function requestCreatePage() {
  return {
    type: PAGE_ACTIONS.REQUEST_PAGE_CREATE,
  }
}

export function receiveCreatePageSuccess(page) {
  return {
    type: PAGE_ACTIONS.RECEIVE_PAGE_CREATE_SUCCESS,
    page,
  }
}

export function receiveCreatePageError(error) {
  return {
    type: PAGE_ACTIONS.RECEIVE_PAGE_CREATE_ERROR,
    error,
  }
}

// DELETE
export function requestDeletePage() {
  return {
    type: PAGE_ACTIONS.REQUEST_PAGE_DELETE,
  }
}

export function receiveDeletePageSuccess(id) {
  return {
    type: PAGE_ACTIONS.RECEIVE_PAGE_DELETE_SUCCESS,
    id,
  }
}

export function receiveDeletePageError(error) {
  return {
    type: PAGE_ACTIONS.RECEIVE_PAGE_DELETE_ERROR,
    error,
  }
}

// UPDATE
export function requestUpdatePage() {
  return {
    type: PAGE_ACTIONS.REQUEST_PAGE_UPDATE,
  }
}

export function receiveUpdatePageSuccess(pageId, page) {
  return {
    type: PAGE_ACTIONS.RECEIVE_PAGE_UPDATE_SUCCESS,
    pageId,
    page,
  }
}

export function receiveUpdatePageError(error) {
  return {
    type: PAGE_ACTIONS.RECEIVE_PAGE_UPDATE_ERROR,
    error,
  }
}

// GET
export function getPages() {
  return (dispatch) => {
    dispatch(requestPages())
    return PageService.getPages()
      .then((pages) => {
        dispatch(receivePages(pages))
      })
      .catch((error) => {
        dispatch(receivePagesError(error))
      })
  }
}

// PUT
export function updatePage(pageId, pageInfo) {
  return (dispatch) => {
    dispatch(requestUpdatePage())
    return PageService.updatePage(pageId, pageInfo)
      .then(() => {
        dispatch(getPages())
      })
      .catch((error) => {
        dispatch(receiveUpdatePageError(error))
      })
  }
}

export function createPage(pageInfo) {
  return (dispatch) => {
    dispatch(requestCreatePage())
    return PageService.createPage(pageInfo)
      .then((id) => {
        dispatch(getPages())
      })
      .catch((error) => {
        dispatch(receiveCreatePageError(error))
      })
  }
}

// DELETE
export function deletePage(pageId) {
  return (dispatch) => {
    dispatch(requestDeletePage())
    return PageService.deletePage(pageId)
      .then(() => {
        dispatch(receiveDeletePageSuccess(pageId))
      })
      .catch((error) => {
        dispatch(receiveDeletePageError(error))
      })
  }
}
