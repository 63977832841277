import { Field, reduxForm } from 'redux-form'
import React from 'react'

let AddressForm = (props) => {
  const { handleSubmit, submitting } = props
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group mt-2">
        <label htmlFor="addressLine1">Address Line 1</label>
        <Field
          name="addressLine1"
          placeholder="Address Line 1"
          component="input"
          type="text"
          className="form-control"
        />
      </div>
      <div className="row mb-2">
        <div className="col-md-9">
          <label htmlFor="addressLine2">Address Line 2</label>
          <Field
            name="addressLine2"
            placeholder="Address Line 2"
            component="input"
            type="text"
            className="form-control"
          />
        </div>
        <div className="col-md-3">
          <label htmlFor="suite">Suite</label>
          <Field
            name="suite"
            placeholder="Suite"
            component="input"
            type="text"
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label htmlFor="city">City</label>
          <Field
            name="city"
            placeholder="City"
            component="input"
            type="text"
            className="form-control"
          />
        </div>
        <div className="col">
          <label htmlFor="STATE">State</label>
          <Field
            name="STATE"
            placeholder="State"
            component="input"
            type="text"
            className="form-control"
          />
        </div>
        <div className="col">
          <label htmlFor="zip">Zip</label>
          <Field
            name="zip"
            placeholder="Suite"
            component="input"
            type="text"
            className="form-control"
          />
        </div>
      </div>
      <div className="form-group mt-2">
        <label htmlFor="Country">Country</label>
        <Field
          name="country"
          placeholder="Country"
          component="input"
          type="text"
          className="form-control"
        />
      </div>
      <button className="btn btn-primary" disabled={submitting}>
        {submitting ? 'Updating' : 'Update'}
      </button>
    </form>
  )
}

AddressForm = reduxForm({
  form: 'addressForm',
})(AddressForm)

export default AddressForm
