import React from 'react'
import PropTypes from 'prop-types'
import DataGrid, { Column, Paging, Pager } from 'devextreme-react/data-grid'
import _ from 'lodash'
import CustomStore from 'devextreme/data/custom_store'
import { getFeatureUsers } from '../../services/feature'

class FeatureUsersComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      usersAdded: [],
    }
    this.timeout = 0
    this.handleChange = this.handleChange.bind(this)
    this.handleOnAdd = this.handleOnAdd.bind(this)
    this.handleOnRemove = this.handleOnRemove.bind(this)
  }

  handleChange(e) {
    const { value } = e.target
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(() => this.props.searchUsers(value), 500)
  }

  handleOnAdd(userId) {
    const { feature } = this.props
    this.props.updateFeatureUsers(feature.featureId, [userId])
  }

  handleOnRemove(userId) {
    const { feature } = this.props
    this.props.removeFeatureFromUser(userId, feature.featureId)
  }

  getFeatureUsers() {
    const { feature } = this.props
    return {
      store: new CustomStore({
        load: function (loadOptions) {
          return getFeatureUsers(feature.featureId, loadOptions)
        },
      }),
    }
  }

  hasFeatureActivated(user, featureId) {
    if (user && user.features && user.features.includes(featureId)) {
      return true
    }
    return false
  }

  renderUsersAdded() {
    const {
      searchResults: { users },
      feature,
    } = this.props
    if (!users || users.length <= 0) {
      return (
        <div className="mb-5 mt-5 text-center">
          <h6 className="text-muted">No users found</h6>
        </div>
      )
    }
    const topUsers = _.take(users, 5)
    return (
      <div>
        <table className="table table-sm">
          <thead>
            <th>Name</th>
            <th>Email</th>
            <th></th>
          </thead>
          <tbody>
            {topUsers.map((user) => {
              return (
                <tr>
                  <td>
                    {user.firstName} {user.lastName}
                  </td>
                  <td>{user.email}</td>
                  <td className="text-center">
                    {!this.hasFeatureActivated(user, feature.featureId) && (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => this.handleOnAdd(user.userId)}
                      >
                        Add
                      </button>
                    )}
                    {this.hasFeatureActivated(user, feature.featureId) && (
                      <button
                        type="button"
                        className="btn btn-danger ml-2"
                        onClick={() => this.handleOnRemove(user.userId)}
                      >
                        Remove
                      </button>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  render() {
    return (
      <div>
        <div>
          <div className="card pt-2 mt-5 w75">
            <div className="card-body">
              <h6 className="card-title">Testing Users</h6>
              <div className="row ml-1 mt-3 mb-3">
                <button
                  className="btn btn-sm btn-primary"
                  data-toggle="modal"
                  data-target="#add-users"
                >
                  Add Users
                </button>
              </div>
              <DataGrid
                remoteOperations={{
                  paging: true,
                  grouping: true,
                }}
                dataSource={this.getFeatureUsers()}
                repaintChangesOnly={true}
                columnAutoWidth={true}
                showBorders={true}
              >
                >
                <Paging defaultPageSize={25} />
                <Pager showPageSizeSelector={false} showInfo={true} />
                <Column dataField={'email'} dataType={'string'} />
                <Column dataField={'firstName'} dataType={'string'} />
                <Column dataField={'firstName'} dataType={'string'} />
                <Column
                  type={'buttons'}
                  buttons={[
                    {
                      hint: 'View',
                      text: 'View',
                      onClick: this.props.onNavigateToUser,
                    },
                  ]}
                />
              </DataGrid>
            </div>
          </div>
        </div>
        <div className="modal" id="add-users" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Users</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div>
                  <input
                    className="form-control mr-sm-2"
                    type="search"
                    placeholder="Search By Email"
                    aria-label="Search"
                    onChange={this.handleChange}
                  />
                </div>
                {this.renderUsersAdded()}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Exit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

FeatureUsersComponent.propTypes = {
  feature: PropTypes.any,
  users: PropTypes.any,
  updateFeatureUsers: PropTypes.func,
  onNavigateToUser: PropTypes.func,
  removeFeatureFromUser: PropTypes.func,
  searchUsers: PropTypes.func,
  searchResults: PropTypes.any,
}

export default FeatureUsersComponent
