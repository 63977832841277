import React, { useState, useEffect } from 'react'
import * as _ from 'lodash'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import { Modal, DropdownButton, Dropdown, Form, Button } from 'react-bootstrap'
import {
  FREE_ACCOUNT_ACTIONS,
  createFreeAccount,
  getFreeAccounts,
  updateFreeAccount,
} from '../actions/freeAccounts'
import { NOTIFICATIONS } from '../helpers/utils'
import { FreeAccountsTable } from '../components/freeAccounts/freeAccountsTable'

const ACCOUNT_STATE_FILTERS = {
  ALL: 'ALL',
  1: 'Cancel',
  0: 'Keep',
}

function FreeAccountsContainer() {
  // GLOBAL STATE
  const dispatch = useDispatch()
  const { data: freeAccounts, isFetching } = useSelector((s) => s.freeAccounts)

  // DATA
  const data = Object.values(freeAccounts)
  const [accounts, setAccounts] = useState(data)
  const [types, setTypes] = useState({ ALL: 'ALL' })

  // FILTERS
  const [accountState, setAccountState] = useState(types.ALL)
  const [accountType, setAccountType] = useState(types.ALL)
  const [stringSearch, setStringSearch] = useState(null)

  const setSearch = _.debounce((val) => {
    if (!val) return setStringSearch(null)
    setStringSearch(val)
  }, 500)

  // MODAL FORM
  const [isCreate, setIsCreate] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [form, setForm] = useState({})

  const formatName = (name) =>
    !!name && (name.includes('(') || name.includes(')'))
      ? name.substring(name.indexOf('(') + 2, name.lastIndexOf(' )'))
      : name

  const setFormValue = _.debounce((val) => {
    if (!val) return
    setForm(val)
  }, 500)

  // EVENT HANDLERS
  const handleCreate = () => {
    setForm({})
    setIsCreate(true)
    setIsOpen(true)
  }

  const handleEdit = ({ row }) => {
    setForm(row.data)
    setIsCreate(false)
    setIsOpen(true)
  }

  const handleSearch = (e) => {
    e.persist()
    e.stopPropagation()
    setSearch(e.target.value)
  }

  const handleForm = (e) => {
    const { id, value, type } = e.target
    if (id === 'cancel' && type === 'checkbox') {
      setFormValue({ ...form, [id]: !form.cancel })
    } else {
      setFormValue({ ...form, [id]: value })
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    form.customerName = formatName(form.customerName)
    delete form.staffName
    delete form.staffEmail
    let action
    if (isCreate) {
      action = await createFreeAccount(form)(dispatch)
      if (action.type === FREE_ACCOUNT_ACTIONS.ERROR_CREATING_FREE_ACCOUNT) {
        toast.error(action.payload.error.message, {
          containerId: NOTIFICATIONS.FREE_ACCOUNT_NOTIFICATIONS,
        })
      } else {
        toast.success('Free account created', {
          containerId: NOTIFICATIONS.FREE_ACCOUNT_NOTIFICATIONS,
        })
      }
    } else {
      action = await dispatch(updateFreeAccount(form))
      if (action.type === FREE_ACCOUNT_ACTIONS.ERROR_UPDATING_FREE_ACCOUNT) {
        toast.error(action.payload.error.message, {
          containerId: NOTIFICATIONS.FREE_ACCOUNT_NOTIFICATIONS,
        })
      } else {
        toast.success('Free account updated', {
          containerId: NOTIFICATIONS.FREE_ACCOUNT_NOTIFICATIONS,
        })
      }
    }
    setIsCreate(false)
    setIsOpen(false)
    dispatch(getFreeAccounts())
  }

  // EVENT LISTENERS
  useEffect(() => {
    dispatch(getFreeAccounts())
  }, [dispatch])

  useEffect(() => {
    const typesData = { ALL: 'ALL' }
    data.forEach((acc) => {
      if (acc.customerType) {
        typesData[acc.customerType.toUpperCase()] = acc.customerType
      }
    })
    setTypes(typesData)
    setAccounts(data)
  }, [freeAccounts])

  useEffect(() => {
    let accounts = data
    if (accountType !== 'ALL') {
      accounts = accounts.filter(
        (a) =>
          a.customerType &&
          a.customerType.toLowerCase() === accountType.toLowerCase()
      )
    }
    if (accountState !== 'ALL') {
      accounts = accounts.filter((a) => a.cancel === !!parseInt(accountState))
    }
    if (![null, ''].includes(stringSearch)) {
      const match = (val) => {
        if (!!val) {
          if (
            typeof val === 'string' &&
            val.toLowerCase().includes(stringSearch.toLowerCase())
          )
            return true
          // eslint-disable-next-line
          if (typeof val === 'number' || parseInt(val, 10) !== NaN)
            return parseInt(val, 10) === parseInt(stringSearch, 10)
        }
        return false
      }
      const filterAccount = (a) => Object.values(a).some(match)
      accounts = accounts.filter(filterAccount)
    }
    setAccounts(accounts)
  }, [accountType, accountState, stringSearch])

  return isFetching ? (
    <h6>Loading</h6>
  ) : (
    <React.Fragment>
      <div className="container">
        <div className="row px-3">
          <div className="col-xs-2">
            <Button onClick={handleCreate}>Add Account</Button>
          </div>
          <div className="col-xs-2 offset-4">Filter by State</div>
          <div className="col mb-1">
            <DropdownButton
              id={'filter-button'}
              title={ACCOUNT_STATE_FILTERS[accountState]}
              style={{ display: 'inline' }}
            >
              {Object.entries(ACCOUNT_STATE_FILTERS).map(([k, v]) => (
                <Dropdown.Item key={k} onClick={() => setAccountState(k)}>
                  {v}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
          <div className="col-xs-2">Filter by Type</div>
          <div className="col mb-1">
            <DropdownButton
              id={'filter-button'}
              title={types[accountType]}
              style={{ display: 'inline' }}
            >
              {Object.entries(types).map(([k, v]) => (
                <Dropdown.Item key={k} onClick={() => setAccountType(k)}>
                  {v}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Form.Group controlId="stringSearch">
              <Form.Label>Search</Form.Label>
              <Form.Control
                type="email"
                placeholder="Search by string..."
                onChange={handleSearch}
              />
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <FreeAccountsTable freeAccounts={accounts} onEdit={handleEdit} />
          </div>
        </div>
      </div>
      <Modal show={isOpen}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {isCreate ? 'Add' : 'Update'} Account
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => setIsOpen(false)}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form onSubmit={handleSubmit}>
              {isCreate && (
                <Form.Group controlId="customerId">
                  <Form.Label>Customer ID</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={form.customerId}
                    onChange={handleForm}
                  />
                </Form.Group>
              )}
              <Form.Group controlId="customerName">
                <Form.Label>Customer (DJ) Name</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={formatName(form.customerName)}
                  onChange={handleForm}
                />
              </Form.Group>
              <Form.Group controlId="customerType">
                <Form.Label>Customer Type</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={form.customerType}
                  onChange={handleForm}
                />
              </Form.Group>
              <Form.Group controlId="customerDescription">
                <Form.Label>Customer Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  defaultValue={form.customerDescription}
                  onChange={handleForm}
                />
              </Form.Group>
              <Form.Group controlId="staffId">
                <Form.Label>Owner ID</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={form.staffId}
                  onChange={handleForm}
                />
              </Form.Group>
              <Form.Group controlId="cancel">
                <Form.Label>Set to Cancel</Form.Label>
                <Form.Check
                  type="checkbox"
                  defaultChecked={form.cancel}
                  onChange={handleForm}
                />
              </Form.Group>
              <Form.Group controlId="expiration">
                <Form.Label>Expiration Date</Form.Label>
                <Form.Control
                  type="date"
                  defaultValue={form.expiration}
                  onChange={handleForm}
                />
              </Form.Group>
              <button className="mt-2 mb-2 btn btn-info btn-sm btn">
                Submit
              </button>
            </Form>
          </div>
        </div>
      </Modal>
      <ToastContainer
        enableMultiContainer
        containerId={NOTIFICATIONS.FREE_ACCOUNT_NOTIFICATIONS}
        autoClose={5000}
        position={toast.POSITION.BOTTOM_RIGHT}
      />
    </React.Fragment>
  )
}

export default withRouter(FreeAccountsContainer)
