import { fetchWithHeaders, parseResponse } from '../helpers/utils'

export const getPromos = async () => {
  const response = await fetchWithHeaders(`promos`, {
    method: 'GET',
  })
  return parseResponse(response)
}

export const upsertPromos = async (data) => {
  const response = await fetchWithHeaders(`promos`, {
    method: 'POST',
    body: JSON.stringify(data),
  })
  return parseResponse(response)
}

export const deletePromos = async (data) => {
  const response = await fetchWithHeaders(`promos`, {
    method: 'DELETE',
    body: JSON.stringify(data),
  })
  return parseResponse(response)
}
