import { PAGE_ACTIONS } from '../actions/page'
import _ from 'lodash'

let pageState = {
  data: [],
  loading: false,
  error: null,
}

const pageReducer = (state = pageState, action) => {
  switch (action.type) {
    case PAGE_ACTIONS.REQUEST_PAGES:
      return {
        ...state,
        loading: true,
      }
    case PAGE_ACTIONS.RECEIVE_PAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.pages,
      }
    case PAGE_ACTIONS.RECEIVE_PAGES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case PAGE_ACTIONS.RECEIVE_PAGE_DELETE_SUCCESS:
      return {
        ...state,
        data: _.filter(state.data, (item) => {
          return item.id !== action.id
        }),
        loading: false,
      }
    default:
      return state
  }
}

export default pageReducer
