import * as LocalizationService from '../services/localization'
import {
  errorUpdatingUserInfo,
  updatedUserInfo,
  updatingUserInfo,
} from './user'
import * as UserService from '../services/user'

export const DEFAULT_LOCALIZATION_DOMAIN = ''

export const LOCALIZATION_ACTIONS = {
  // GET
  REQUEST_LOCALIZATIONS: 'REQUEST_LOCALIZATIONS',
  RECEIVE_LOCALIZATIONS: 'RECEIVE_LOCALIZATIONS',
  ERROR_RECEIVING_LOCALIZATION: 'ERROR_RECEIVING_LOCALIZATION',
  // UPDATE
  UPDATING_LOCALIZATION: 'UPDATING_LOCALIZATION',
  UPDATED_LOCALIZATION: 'UPDATED_LOCALIZATION',
  ERROR_UPDATING_LOCALIZATION: 'ERROR_UPDATING_LOCALIZATION',
}

export function requestLocalizations(
  prefix = DEFAULT_LOCALIZATION_DOMAIN,
  forEdit = false
) {
  return {
    type: LOCALIZATION_ACTIONS.REQUEST_LOCALIZATIONS,
    prefix,
    forEdit,
  }
}

export function receiveLocalizations(prefix, localizations, forEdit) {
  return {
    type: LOCALIZATION_ACTIONS.RECEIVE_LOCALIZATIONS,
    localizations,
    prefix,
    forEdit,
  }
}

export function errorRetrievingLocalizations(prefix, error) {
  return {
    type: LOCALIZATION_ACTIONS.ERROR_RECEIVING_LOCALIZATION,
    prefix,
    error,
  }
}

// GET
export function getLocalizations(
  prefix,
  includeAll = false,
  fullSearch = false,
  untranslated = false
) {
  return (dispatch) => {
    dispatch(requestLocalizations(prefix, includeAll))
    return LocalizationService.getLocalizations(
      prefix,
      includeAll,
      fullSearch,
      false,
      untranslated
    )
      .then((localizations) => {
        dispatch(
          receiveLocalizations(prefix, localizations, includeAll && !fullSearch)
        )
      })
      .catch((error) => {
        dispatch(errorRetrievingLocalizations(error))
      })
  }
}

function updatingLocalization(prefix) {
  return {
    type: LOCALIZATION_ACTIONS.UPDATING_LOCALIZATION,
    prefix,
  }
}

function updatedLocalization(prefix, values) {
  return {
    type: LOCALIZATION_ACTIONS.UPDATED_LOCALIZATION,
    prefix,
    values,
  }
}

function errorUpdatingLocalization(prefix, error) {
  return {
    type: LOCALIZATION_ACTIONS.ERROR_UPDATING_LOCALIZATION,
    prefix,
    error,
  }
}

export function updateLocalization(prefix, values, replaceAll, isReadyForProd) {
  return (dispatch) => {
    dispatch(updatingLocalization(prefix))
    return LocalizationService.updateLocalization(
      prefix,
      values,
      replaceAll,
      isReadyForProd
    )
      .then(() => {
        dispatch(updatedLocalization(prefix, values))
      })
      .catch((error) => {
        dispatch(errorUpdatingLocalization(prefix, error))
      })
  }
}

export function updateUserInfo(userInfo) {
  return (dispatch) => {
    dispatch(updatingUserInfo(userInfo))
    return UserService.updateUser(userInfo)
      .then((user) => {
        dispatch(updatedUserInfo(user))
      })
      .catch((error) => {
        dispatch(errorUpdatingUserInfo(userInfo, error))
      })
  }
}
