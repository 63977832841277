import React from 'react'
import DataGrid, { Column, Scrolling } from 'devextreme-react/data-grid'
import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import { Modal } from 'react-bootstrap'
import BannerRegionForm from './bannerRegionForm'
import { toast } from 'react-toastify'
import { AVAILABLE_REGIONS, NOTIFICATIONS } from '../../helpers/utils'
import moment from 'moment'

export class BannerRegions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      selectedRegion: {},
    }
  }

  getDataSource() {
    const { regions } = this.props
    return new DataSource({
      store: new ArrayStore({
        type: 'array',
        key: 'regionId',
        data: regions,
      }),
      reshapeOnPush: true,
    })
  }

  handleOnEdit = (event) => {
    const region = event.row.data
    const parsedRegion = {
      regionId: region.regionId,
      order: region.order,
      active: region.active,
      startDate: moment.utc(region.startDate).toDate(),
      endDate: moment.utc(region.endDate).toDate(),
      webAction: region.webAction,
      mobileAction: region.mobileAction,
    }
    this.setState({ selectedRegion: parsedRegion }, () => {
      this.setState({ showModal: true })
    })
  }

  handleAddRegion = () => {
    this.setState({ showModal: true, selectedRegion: {} })
  }

  handleOnRegionUpdate = (values) => {
    const { bannerId } = this.props
    const { regionId } = values
    if (!values.startDate || !values.endDate) {
      toast.error('Please select a start and end date', {
        containerId: NOTIFICATIONS.BANNER_NOTIFICATION,
      })
      return
    }
    const info = {
      order: values.order,
      active: values.active ? values.active : false,
      startDate: new Date(values.startDate).toISOString(),
      endDate: new Date(values.endDate).toISOString(),
      webAction: values.webAction,
      mobileAction: values.mobileAction,
    }
    return this.props.updateBannerRegion(bannerId, regionId, info).then(() => {
      this.setState({ showModal: false })
    })
  }

  regionCellRender = (data) => {
    return AVAILABLE_REGIONS[data.value]
  }

  render() {
    return (
      <>
        <div className="mt-4">
          <h5>Regions</h5>
          <button
            className="btn mb-2 btn-outline-info btn-sm"
            onClick={this.handleAddRegion}
          >
            Add Region
          </button>
        </div>
        <DataGrid
          className="mt-2 mb-3"
          dataSource={this.getDataSource()}
          repaintChangesOnly={true}
          columnAutoWidth={true}
          showBorders={true}
        >
          <Scrolling mode={'infinite'} />
          <Column
            dataField={'regionId'}
            cellRender={this.regionCellRender}
            caption={'Region'}
          />
          <Column
            dataField={'startDate'}
            dataType={'date'}
            caption={'Start Date'}
          />
          <Column
            dataField={'endDate'}
            dataType={'date'}
            caption={'End Date'}
          />
          <Column dataField={'order'} caption={'Order'} />
          <Column
            dataField={'webAction'}
            caption={'Web Action URL'}
            width="20%"
          />
          <Column
            dataField={'mobileAction'}
            caption={'Mobile Action URL'}
            width="20%"
          />
          <Column dataField={'active'} caption={'Active'} />
          <Column
            type={'buttons'}
            buttons={[
              {
                hint: 'Edit',
                text: 'Edit',
                onClick: this.handleOnEdit,
              },
            ]}
          />
        </DataGrid>
        <Modal show={this.state.showModal}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Region</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={() => this.setState({ showModal: false })}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <BannerRegionForm
                onSubmit={this.handleOnRegionUpdate}
                initialValues={this.state.selectedRegion}
              />
            </div>
          </div>
        </Modal>
      </>
    )
  }
}
