import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getFeatures } from '../actions/feature'
import FeatureTableComponent from '../components/feature/featureTable'

class FeatureContainer extends React.Component {
  constructor(props) {
    super(props)

    this.onChangeFeature = this.onChangeFeature.bind(this)
  }

  componentDidMount() {
    const { getFeatures } = this.props
    getFeatures()
  }

  onChangeFeature(event) {
    const { history } = this.props
    history.push(`features/${event.row.data.featureId}`)
  }

  render() {
    const { feature } = this.props
    return (
      <React.Fragment>
        <div style={{ position: 'absolute' }}>
          <FeatureTableComponent
            features={feature.features}
            loading={feature.loading}
            editFeature={this.onChangeFeature}
          />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    feature: state.feature,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    getFeatures: () => {
      return dispatch(getFeatures())
    },
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeatureContainer)
)
