import { BANNER_ACTIONS } from '../actions/banner'
import * as _ from 'lodash'
let bannerState = {
  data: {},
  error: null,
  isFetching: false,
}

const bannerReducer = (state = bannerState, action) => {
  switch (action.type) {
    case BANNER_ACTIONS.REQUEST_BANNERS:
      return {
        ...state,
        isFetching: true,
      }
    case BANNER_ACTIONS.RECEIVE_BANNERS:
      return {
        ...state,
        isFetching: false,
        data: _.keyBy(action.payload.banners, 'baid'),
      }
    case BANNER_ACTIONS.RECEIVE_BANNER_REGIONS:
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          [action.payload.bannerId]: {
            ...state.data[action.payload.bannerId],
            regions: _.keyBy(action.payload.regions, 'regionId'),
          },
        },
      }
    case BANNER_ACTIONS.UPDATED_BANNER_REGIONS:
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          [action.payload.bannerId]: {
            ...state.data[action.payload.bannerId],
            regions: {
              ...state.data[action.payload.bannerId].regions,
              [action.payload.regionId]: action.payload.region,
            },
          },
        },
      }
    default:
      return state
  }
}

export default bannerReducer
