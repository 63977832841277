import { LOCALIZATION_ACTIONS } from '../actions/localization'

let localizationState = {
  localizations: [],
  currentLocalization: null,
  loading: false,
  error: null,
}

const localizationReducer = (state = localizationState, action) => {
  switch (action.type) {
    case LOCALIZATION_ACTIONS.REQUEST_LOCALIZATIONS:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case LOCALIZATION_ACTIONS.RECEIVE_LOCALIZATIONS:
      let nextState = {
        ...state,
        loading: false,
        error: null,
      }
      if (action.forEdit) {
        nextState.currentLocalization = action.localizations
      } else {
        nextState.localizations = action.localizations
        nextState.currentLocalization = null
      }
      return nextState
    case LOCALIZATION_ACTIONS.ERROR_RECEIVING_LOCALIZATION:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case LOCALIZATION_ACTIONS.UPDATING_LOCALIZATION:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case LOCALIZATION_ACTIONS.UPDATED_LOCALIZATION:
      return {
        ...state,
        loading: false,
        error: null,
        currentLocalization: null,
      }
    default:
      return state
  }
}

export default localizationReducer
