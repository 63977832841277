import { fetchWithHeaders, parseResponse } from '../helpers/utils'
import swal from 'sweetalert'

export const getAuditLog = () => {
  return fetchWithHeaders('audit', { method: 'GET' })
    .then(parseResponse)
    .then((data) => {
      if (data.error) {
        swal(data.error.userTitle, data.error.userDescription, 'error')
      } else
        return {
          data: data.log,
        }
    })
    .catch(() => {
      throw new Error('Error loading logs.')
    })
}
