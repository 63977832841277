import React from 'react'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.compact.css'
import 'devextreme/data/odata/store'
import DataGrid, {
  Column,
  Scrolling,
  LoadPanel,
  Selection,
} from 'devextreme-react/data-grid'
import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import 'whatwg-fetch'
import { fetchWithHeaders, handleServerError } from '../../helpers/utils'
import swal from 'sweetalert'
import './user-details.css'
import PropTypes from 'prop-types'
import { getPayments } from '../../services/payment'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import moment from 'moment'

class PaymentHistoryComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      textCopied: 'test',
      paymentData: [{}],
    }

    this.getPaymentHistory = this.getPaymentHistory.bind(this)
    this.refundPayment = this.refundPayment.bind(this)
    this.copyText = this.copyText.bind(this)
  }

  copyText(e) {
    let data = ''
    if (e.selectedRowKeys.length > 0)
      e.selectedRowKeys.forEach((item, index) => {
        data +=
          moment.utc(item.date).format('YYYY/MM/DD') +
          ' ' +
          item.amount +
          ' ' +
          item.currency +
          '\r\n'
      })

    this.setState({ textCopied: data })
  }

  getPaymentHistory() {
    const { paymentData } = this.state
    return new DataSource({
      store: new ArrayStore({
        type: 'array',
        key: 'paymentId',
        data: paymentData,
      }),
      reshapeOnPush: true,
    })
  }

  async refundPayment(e) {
    const { user } = this.props.user
    const { canCancelSub } = this.props
    const isLatestPayment = canCancelSub && e.row.dataIndex === 0
    try {
      const swalParams = {
        title: 'Are you sure you want to refund this payment?',
        text: 'To proceed with the payment refund push the "Refund" button.'.concat(
          isLatestPayment
            ? 'If you want to also cancel the user subscription on the payment gateway push the "Refund and Deactivate Immediately" button instead.'
            : ''
        ),
        icon: 'warning',
        buttons: {
          cancel: 'Close',
          refund: isLatestPayment ? 'Refund only' : 'Refund',
          refundAndDeactivate: isLatestPayment
            ? 'Refund and Deactivate Immediately'
            : undefined,
        },
      }
      const action = await swal(swalParams)
      if (action) {
        const cancelSubscription = action === 'refundAndDeactivate'
        const res = await fetchWithHeaders(
          `payments/${e.row.data.paymentId}/refund`,
          {
            method: 'POST',
            body: JSON.stringify({ user, cancelSubscription }),
          }
        )
        if (res.ok) {
          const title =
            'REFUNDED' + (cancelSubscription ? ' AND ACCOUNT DEACTIVATED' : '')
          const text =
            'This payment was refunded' +
            (cancelSubscription ? ' and the account was deactivated.' : '')
          return swal({ title, text, icon: 'success' })
        } else {
          return swal({
            title: 'ERROR',
            text: 'There was an error while processing this request',
            icon: 'error',
          })
        }
      }
    } catch (error) {
      return handleServerError(error)
    }
  }

  componentDidMount() {
    const {
      user: { user },
    } = this.props
    if (user.userId) {
      getPayments(user.userId).then((res) =>
        this.setState({ ...this.state, paymentData: res.data })
      )
    }
  }

  componentDidUpdate(prevProps) {
    const {
      user: { user },
    } = this.props
    if (user.userId && prevProps.user.user.userId !== user.userId) {
      getPayments(user.userId).then((res) =>
        this.setState({ ...this.state, paymentData: res.data })
      )
    }
  }

  render() {
    let { user } = this.props
    let { textCopied } = this.state
    return (
      <div>
        {user.user.userId && (
          <div>
            <DataGrid
              dataSource={this.getPaymentHistory()}
              showBorders={true}
              onSelectionChanged={this.copyText}
            >
              <Selection
                mode={'multiple'}
                selectAllMode={'allPages'}
                showCheckBoxesMode={'onClick'}
              />
              <Column
                dataField={'date'}
                dataType={'date'}
                // format={'yyyy/MM/dd'}
                calculateDisplayValue={(p) =>
                  moment.utc(p.date).format('YYYY/MM/DD')
                }
              />
              <Column dataField={'currency'} />
              <Column dataField={'amount'} />
              <Column dataField={'paymentMethod'} />
              <Column
                type={'buttons'}
                buttons={[
                  {
                    hint: 'Refund',
                    text: 'Refund',
                    onClick: this.refundPayment,
                  },
                ]}
              />
              <Scrolling mode={'virtual'} />
              <LoadPanel enabled={true} />
            </DataGrid>
            <br />

            <CopyToClipboard text={textCopied}>
              <button className={'btn-danger'}>Copy</button>
            </CopyToClipboard>
          </div>
        )}
      </div>
    )
  }
}

PaymentHistoryComponent.propTypes = {
  user: PropTypes.object,
  canCancelSub: PropTypes.bool,
}

export default PaymentHistoryComponent
