import * as React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { setMaintenanceMode, getMaintenanceMode } from '../actions/maintenance'
import { useEffect, useState } from 'react'
import { NOTIFICATIONS } from '../helpers/utils'
import { toast, ToastContainer } from 'react-toastify'

const MaintenanceContainer = (props) => {
  const [maintenanceMode, setMaintenanceMode] = useState(false)

  useEffect(() => {
    const fetchMaintenance = async () => {
      const isEnabled = await getMaintenanceMode()
      if (maintenanceMode !== isEnabled) {
        setMaintenanceMode()
      }
    }
    fetchMaintenance()
  }, [maintenanceMode])

  return (
    <>
      <h4>Maintenance Mode</h4>
      <div className={'row'}>
        <div className={'col-md-8'}>
          Current maintenance status: {maintenanceMode ? 'ON' : 'OFF'}
        </div>
      </div>
      <div className={'row'}>
        <div className={'col-md-2'}>
          <button
            className={'mt-2 mb-2 btn btn-info '}
            onClick={() =>
              props.setMaintenanceMode(false) && setMaintenanceMode(false)
            }
          >
            OFF
          </button>
        </div>
        <div className={'col-md-2'}>
          <button
            className={'mt-2 mb-2 btn btn-info '}
            onClick={() =>
              props.setMaintenanceMode(true) && setMaintenanceMode(true)
            }
          >
            ON
          </button>
        </div>
      </div>
      <ToastContainer
        enableMultiContainer
        containerId={NOTIFICATIONS.BANNER_NOTIFICATION}
        autoClose={5000}
        position={toast.POSITION.BOTTOM_RIGHT}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setMaintenanceMode: (value) => dispatch(setMaintenanceMode(value)),
  getMaintenanceMode: (value) => dispatch(getMaintenanceMode(value)),
})

export default withRouter(
  connect(null, mapDispatchToProps)(MaintenanceContainer)
)
