import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getPromoCodes } from '../actions/promoCodes'
import { getPromos } from '../actions/promos'
import { PromoCodeTable } from '../components/promoCodes/promoCodeTable'

import { DropdownButton, Dropdown } from 'react-bootstrap'

function PromoCodesContainer() {
  const dispatch = useDispatch()
  // Global State
  const { data: promos, isFetching: fetchingPromos } = useSelector(
    (s) => s.promos
  )
  const { data: codes, isFetching: fetchingCodes } = useSelector(
    (s) => s.promoCodes
  )

  // Local State
  const [state, setState] = useState({
    promoId: NaN,
    activeCodes: null,
    assignedCodes: null,
  })

  // Event handlers
  const handlePromoSelect = (e) => {
    const promoId = parseInt(e.target.value)
    setState({ ...state, promoId })
  }

  // Event listeners
  useEffect(() => {
    dispatch(getPromos())
  }, [dispatch])

  useEffect(() => {
    if (!isNaN(state.promoId)) {
      const { promoId, activeCodes, assignedCodes } = state
      dispatch(
        getPromoCodes({ promoId, active: activeCodes, assigned: assignedCodes })
      )
    }
  }, [dispatch, state.promoId])

  const filterCodes = (codes) => {
    return Object.values(codes)
      .filter((c) => c.promoId === state.promoId)
      .filter((c) =>
        state.activeCodes === null ? true : c.active === state.activeCodes
      )
      .filter((c) =>
        state.assignedCodes === null
          ? true
          : !!c.customerId === state.assignedCodes
      )
  }

  return fetchingPromos || fetchingCodes ? (
    <h6>Loading</h6>
  ) : (
    <div className="container">
      <div className="row px-3">
        <div className="col">
          <h4>Promo Codes</h4>
        </div>
      </div>
      <div className="row px-3">
        <div className="col d-flex justify-content-between mt-3 mb-3">
          <select
            id="promo"
            className="custom-select"
            onChange={handlePromoSelect}
          >
            <option value={null} selected={isNaN(state.promoId)}>
              Select a promo to show the codes
            </option>
            {Object.values(promos)
              .filter((p) => p.id !== 0 && p.isUserSpecific)
              .map((p) => (
                <option selected={state.promoId === p.id} value={p.id}>
                  ({p.id}) {p.name}
                </option>
              ))}
          </select>
          <DropdownButton id="codeState" title="Filter Status">
            <Dropdown.Item
              onClick={() => setState({ ...state, activeCodes: null })}
            >
              ALL
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => setState({ ...state, activeCodes: false })}
            >
              INACTIVE
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => setState({ ...state, activeCodes: true })}
            >
              ACTIVE
            </Dropdown.Item>
          </DropdownButton>
          <DropdownButton id="assignedState" title="Filter Assigned">
            <Dropdown.Item
              onClick={() => setState({ ...state, assignedCodes: null })}
            >
              ALL
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => setState({ ...state, assignedCodes: false })}
            >
              UNASSIGNED
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => setState({ ...state, assignedCodes: true })}
            >
              ASSIGNED
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </div>
      <div className="row px-3">
        <PromoCodeTable promoCodes={filterCodes(codes)} />
      </div>
    </div>
  )
}

export default withRouter(PromoCodesContainer)
