import { fetchWithHeaders, parseResponse } from '../helpers/utils'
import swal from 'sweetalert'

export const getPages = () => {
  return fetchWithHeaders('v1/pages', {
    method: 'GET',
  })
    .then(parseResponse)
    .then((data) => {
      if (data.error) {
        swal(data.error.userTitle, data.error.userDescription, 'error')
      } else return data
    })
    .catch(() => {
      throw new Error('Error loading pages.')
    })
}

export const deletePage = (id) => {
  return fetchWithHeaders(`v1/pages/${id}`, { method: 'DELETE' })
    .then(parseResponse)
    .then((data) => {
      if (data.error) {
        swal(data.error.userTitle, data.error.userDescription, 'error')
      } else return data
    })
    .catch(() => {
      throw new Error('Error deleting page.')
    })
}

export const updatePage = (id, pageInfo) => {
  return fetchWithHeaders(`v1/pages/${id}`, {
    method: 'PUT',
    body: JSON.stringify(pageInfo),
  })
    .then(parseResponse)
    .then((data) => {
      if (data.error) {
        swal(data.error.userTitle, data.error.userDescription, 'error')
      } else return data
    })
    .catch(() => {
      throw new Error('Error updating page.')
    })
}

export const createPage = (pageInfo) => {
  return fetchWithHeaders('v1/pages', {
    method: 'POST',
    body: JSON.stringify(pageInfo),
  })
    .then(parseResponse)
    .then((data) => {
      if (data.error) {
        swal(data.error.userTitle, data.error.userDescription, 'error')
      } else return data
    })
    .catch(() => {
      throw new Error('Error updating page.')
    })
}
