import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { FormGroup, FormLabel } from 'react-bootstrap'

export const DatePickerInput = ({
  input,
  id,
  label,
  disabled,
  popoverAttachment,
  popoverTargetAttachment,
  popoverTargetOffset,
  todayButton,
  meta: { touched, error },
}) => (
  <FormGroup>
    {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
    <DatePicker
      className="form-control"
      {...input}
      fixedHeight
      todayButton={todayButton}
      label={label}
      id={id}
      dateFormat="MM-dd-yyyy"
      selected={input.value ? Date.parse(input.value) : null}
      disabled={disabled}
      utcOffset={0}
      popoverAttachment={popoverAttachment}
      popoverTargetAttachment={popoverTargetAttachment}
      popoverTargetOffset={popoverTargetOffset}
    />
    {touched && error && <span>{error}</span>}
  </FormGroup>
)
