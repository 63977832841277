import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import FeatureDetail from '../components/feature/featureDetail'
import _ from 'lodash'
import {
  getFeatures,
  getFeatureUsers,
  updateFeatures,
  updateFeatureUsers,
  toggleFeatureForUser,
} from '../actions/feature'
import FeatureUsersComponent from '../components/feature/featureUsers'
import { loadUsers } from '../actions/users'

class FeatureDetailContainer extends React.Component {
  constructor(props) {
    super(props)
    this.featureUpdate = this.featureUpdate.bind(this)
    this.updateFeatureUsers = this.updateFeatureUsers.bind(this)
    this.searchUsers = this.searchUsers.bind(this)
    this.onNavigateToUser = this.onNavigateToUser.bind(this)
    this.removeUserFromFeature = this.removeUserFromFeature.bind(this)
  }

  componentDidMount() {
    const {
      getFeatures,
      match: {
        params: { id },
      },
    } = this.props
    if (!this.getFeatureById(id)) {
      getFeatures()
    }
  }

  getFeatureById(featureId) {
    const { features } = this.props
    return _.find(features, ['featureId', featureId])
  }

  featureUpdate(featureId, enableForEveryone) {
    const { history } = this.props
    this.props.updateFeature(featureId, enableForEveryone).then(() => {
      history.push('/features')
    })
  }

  updateFeatureUsers(featureId, users) {
    this.props.updateUsers(featureId, users)
  }

  removeUserFromFeature(userId, featureId) {
    this.props.removeFeature(userId, featureId)
  }

  onNavigateToUser(row) {
    const {
      row: { key },
    } = row
    const { history } = this.props
    history.push(`/user/${key.customerId}`)
  }

  searchUsers(email) {
    const { getUsers } = this.props
    getUsers({ email, includeFeatures: true })
  }

  render() {
    const {
      match: {
        params: { id },
      },
      featureUsers,
      users,
    } = this.props
    const feature = this.getFeatureById(id)
    if (!feature) {
      return (
        <React.Fragment>
          <p>Loading</p>
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <FeatureDetail feature={feature} onFeatureUpdate={this.featureUpdate} />
        <FeatureUsersComponent
          feature={feature}
          users={featureUsers}
          searchResults={users}
          searchUsers={this.searchUsers}
          updateFeatureUsers={this.updateFeatureUsers}
          onNavigateToUser={this.onNavigateToUser}
          removeFeatureFromUser={this.removeUserFromFeature}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    features: state.feature.features,
    featureUsers: state.feature.users,
    users: state.users,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    getFeatures: () => {
      return dispatch(getFeatures())
    },
    updateFeature: (featureId, enableForEveryone) => {
      return dispatch(updateFeatures(featureId, enableForEveryone))
    },
    getCurrentFeatureUsers: (featureId) => {
      return dispatch(getFeatureUsers(featureId))
    },
    getUsers: (params) => {
      return dispatch(loadUsers(params))
    },
    updateUsers: (featureId, users) => {
      return dispatch(updateFeatureUsers(featureId, users, false))
    },
    removeFeature: (userId, featureId) => {
      return dispatch(toggleFeatureForUser(userId, featureId, false))
    },
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FeatureDetailContainer)
)
