import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import React from 'react'
import { Container } from 'react-bootstrap'
import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid'

export function PromoTable({ promos, onDelete, onClone, onEdit }) {
  const dataSource = new DataSource({
    store: new ArrayStore({
      type: 'array',
      key: 'id',
      data: Object.values(promos) || [],
    }),
    reshapeOnPush: true,
  })

  return (
    <Container fluid>
      <DataGrid
        dataSource={dataSource}
        repaintChangesOnly={true}
        columnAutoWidth={false}
        showBorders={true}
        remoteOperations={{
          paging: true,
        }}
      >
        <Column dataField={'id'} minWidth={40} />
        <Column dataField={'name'} minWidth={130} />
        <Column dataField={'isPaid'} minWidth={60} />
        <Column dataField={'isUserSpecific'} minWidth={100} />
        <Column
          dataField={'startDate'}
          dataType={'date'}
          format={'yyyy-MM-dd'}
          minWidth={80}
          calculateDisplayValue={(r) => r.startDate.split('T')[0]}
        />
        <Column
          dataField={'endDate'}
          dataType={'date'}
          format={'yyyy-MM-dd'}
          minWidth={80}
          calculateDisplayValue={(r) => r.endDate.split('T')[0]}
        />
        <Column dataField={'duration'} minWidth={60} />
        <Column dataField={'durationType'} minWidth={40} />
        <Column
          type={'buttons'}
          buttons={[
            { hint: 'Edit', text: 'Edit', onClick: onEdit },
            { hint: 'Clone', text: 'Clone', onClick: onClone },
            { hint: 'Delete', text: 'Delete', onClick: onDelete },
          ]}
          minWidth={75}
        />
        <Paging defaultPageSize={50} />
        <Pager
          tot
          visible
          allowedPageSizes={[5, 10, 15, 25, 50, 75, 100]}
          showPageSizeSelector={true}
          showInfo={true}
        />
      </DataGrid>
    </Container>
  )
}
