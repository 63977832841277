import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import LocalizationForm from '../components/localizations/localizationForm'
import { getLocalizations, updateLocalization } from '../actions/localization'
import { confirmationMessage } from '../helpers/utils'
import { omit } from 'lodash'

const EN_US = 'en-US'
const availableLocales = [EN_US, 'en-GB', 'ja-JP', 'es-ES', 'de-DE']
const requiredLocales = [EN_US]

class LocalizationDetailContainer extends React.Component {
  componentDidMount() {
    const {
      getLocalizations,
      match: {
        params: { id },
      },
    } = this.props
    if (id) {
      getLocalizations(id)
    }
  }

  createConfirmation = async (prodCheck, title, subtitle, action) => {
    if (prodCheck) {
      const response = await confirmationMessage(title, subtitle)
      if (response) {
        action()
      }
    } else {
      action()
    }
  }

  onSubmit = (values) => {
    const locales = []
    Object.keys(omit(values, 'name', 'isReadyForProd')).forEach((key) => {
      const value = values[key]
      if (value && value !== '') {
        locales.push({
          locale: key,
          value: values[key],
        })
      }
    })

    return this.createConfirmation(
      values.isReadyForProd,
      'STRING SYNCHRONIZATION',
      'This string is marked as ready for prod and will be synchronized. Are you sure you want to continue?',
      () => {
        return this.props
          .updateLocalization(values.name, locales, true, values.isReadyForProd)
          .then(() => {
            this.onBack()
          })
      }
    )
  }

  getInitialValues = (currentLocalization) => {
    if (currentLocalization) {
      const {
        match: {
          params: { id },
        },
      } = this.props
      const values = {}
      currentLocalization
        .filter((x) => x.name === id)
        .forEach((item) => {
          values[item.locale] = item.value
        })
      return {
        name: id,
        isReadyForProd: currentLocalization.every((x) => x.isReadyForProd),
        ...values,
      }
    }
    return {}
  }

  onBack = () => {
    this.props.history.push('/localizations')
  }

  render() {
    const {
      currentLocalization,
      loading,
      match: {
        params: { id },
      },
    } = this.props
    const initialValues = this.getInitialValues(currentLocalization)
    if (loading) {
      return 'Loading...'
    }
    return (
      <React.Fragment>
        <div className="row ml-1 mt-3 mb-3 ">
          <button className="btn btn-sm btn-secondary" onClick={this.onBack}>
            Back
          </button>
        </div>
        <LocalizationForm
          nameLocked={id != null}
          isReadyForProd={initialValues.isReadyForProd}
          initialValues={initialValues}
          locales={availableLocales}
          requiredLocales={requiredLocales}
          onSubmit={this.onSubmit}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentLocalization: state.localization.currentLocalization,
    loading: state.localization.loading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLocalizations: (id) => {
      return dispatch(getLocalizations(id, true))
    },
    updateLocalization: (name, values, replaceAll, isReadyForProd) => {
      return dispatch(
        updateLocalization(name, values, replaceAll, isReadyForProd)
      )
    },
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LocalizationDetailContainer)
)
