import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import './user-details.css'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.compact.css'
import 'devextreme/data/odata/store'
import 'whatwg-fetch'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Table } from '../table'
import { Pagination } from '../pagination'
import {
  getDownloadHistory,
  getDownloadHistoryXLSX,
  resetDownloadCount,
} from '../../actions/download-history'
import { useDebounce } from '../../hooks/use_debounce'

import styles from './download_history.module.sass'

function DownloadHistoryComponent({ user }) {
  const dispatch = useDispatch()
  const tracks = useSelector((state) => state.downloadHistory.tracks)
  const pagination = useSelector((state) => state.downloadHistory.pagination)
  const isFetching = useSelector((state) => state.downloadHistory.isFetching)
  const totalPages = Math.ceil(pagination.totalItems / pagination.pageSize)
  const [page, setPage] = useState(pagination.page)
  const [nameFilter, setNameFilter] = useState('')
  const debouncedNameFilter = useDebounce(nameFilter)
  const userId = user ? user.user.userId : null

  useEffect(() => {
    if (!userId) {
      return
    }

    dispatch(
      getDownloadHistory(userId, page, pagination.pageSize, debouncedNameFilter)
    )
  }, [userId, page, pagination.pageSize, debouncedNameFilter, dispatch])

  const onResetDownloadCountClick = useCallback(
    async (rit, ttid) => {
      if (isFetching || !userId) {
        return
      }

      await dispatch(resetDownloadCount(userId, rit, ttid))
      dispatch(
        getDownloadHistory(
          userId,
          page,
          pagination.pageSize,
          debouncedNameFilter
        )
      )
    },
    [
      isFetching,
      userId,
      page,
      pagination.pageSize,
      debouncedNameFilter,
      dispatch,
    ]
  )

  const onExportCSVClick = useCallback(() => {
    if (!userId) {
      return
    }

    dispatch(getDownloadHistoryXLSX(userId))
  }, [userId, dispatch])

  const onChangeNameFilter = useCallback(
    (e) => setNameFilter(e.target.value),
    [setNameFilter]
  )

  if (!userId) {
    return null
  }

  return (
    <>
      <button
        className={styles.csvButton}
        variant="warning"
        onClick={onExportCSVClick}
      >
        <i className="fa fa-file-excel-o" aria-hidden="true"></i> Export All
      </button>
      <Table
        className={classNames({ [styles.loading]: isFetching })}
        columns={[
          <>
            File Name
            <input
              value={nameFilter}
              onChange={onChangeNameFilter}
              type="text"
              className={styles.filterField}
              placeholder="Filter..."
            />
          </>,
          'Last Download Date',
          'Download Count',
          'Reset Download Count',
        ]}
      >
        {tracks.map((track) => [
          <div key="File Name">{track.friendlyFileName}</div>,
          <div key="Last Download Date">
            {moment.utc(track.downloadDate).format('YYYY/MM/DD')}
          </div>,
          <div key="Download Count">{track.downloadCount}</div>,
          <div key="Reset Count">
            <button
              onClick={() =>
                onResetDownloadCountClick(track.record.rid, track.ttid)
              }
            >
              Reset Download Count
            </button>
          </div>,
        ])}
      </Table>
      <Pagination
        className={classNames(styles.pagination, {
          [styles.loading]: isFetching,
        })}
        label={`Page ${pagination.page} of ${totalPages} (${pagination.totalItems} items)`}
        onPageSelect={setPage}
        page={pagination.page}
        totalPages={totalPages}
      />
    </>
  )
}

DownloadHistoryComponent.propTypes = {
  user: PropTypes.object,
}

DownloadHistoryComponent.defaultProps = {
  user: null,
}

export default DownloadHistoryComponent
