import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { LocalizationTableComponent } from '../components/localizations/localizationTable'
import { getLocalizations } from '../actions/localization'
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap'
import classNames from 'classnames'
import _ from 'lodash'
import { isProd } from '../helpers/utils'

class LocalizationContainer extends React.Component {
  constructor(props) {
    super(props)
    this.onEdit = this.onEdit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      untranslated: false,
      pending: false,
      edited: false,
      currentSearch: 'djcity',
    }
    this.searchLocalizations = _.debounce(this.props.getLocalizations, 500)
  }

  componentDidMount() {
    this.props.getLocalizations('djcity')
  }

  onEdit(id) {
    window.open(`localization/${id}`, '_blank')
  }

  onCreate = () => {
    this.props.history.push(`localization`)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.currentSearch !== prevState.currentSearch) {
      this.searchLocalizations(
        this.state.currentSearch,
        this.state.untranslated
      )
    }
  }

  handleOnFilter = (event) => {
    const { value } = event.target
    const untranslated = value === 'untranslated'
    const pending = value === 'pending'
    const edited = value === 'edited'
    this.setState(
      {
        untranslated,
        pending,
        edited,
      },
      () => this.props.getLocalizations(this.state.currentSearch, untranslated)
    )
  }

  handleChange(e) {
    const { value } = e.target
    this.setState({
      currentSearch: value !== '' ? value : 'djcity',
    })
  }

  filterPending(strings) {
    const lastSync = new Date()
    lastSync.setUTCHours(0, 0, 0, 0)

    if (this.state.pending) {
      lastSync.setDate(lastSync.getDate() - 1)
      return strings.filter(
        (s) =>
          s.isReadyForProd &&
          Date.parse(s.lastModified).valueOf() >= lastSync.valueOf()
      )
    } else if (this.state.edited) {
      lastSync.setDate(lastSync.getDate() - 7)
      return strings.filter(
        (s) =>
          !s.isReadyForProd &&
          Date.parse(s.lastModified).valueOf() >= lastSync.valueOf()
      )
    } else {
      return strings
    }
  }

  render() {
    const {
      loading,
      localization: { localizations },
    } = this.props

    return (
      <React.Fragment>
        <div>
          <input
            className="form-control mr-sm-2"
            type="search"
            placeholder="Search for additional resources (example: djcity.footer)"
            aria-label="Search"
            onChange={this.handleChange}
          />
        </div>
        {loading ? (
          <h6>Loading</h6>
        ) : (
          <div>
            <div>
              <ButtonToolbar className="mt-4 mb-3 d-inline-block">
                <ButtonGroup onClick={this.handleOnFilter.bind(this)}>
                  <Button
                    className={classNames({
                      active: !this.state.untranslated && !this.state.pending,
                    })}
                    value="all"
                  >
                    All
                  </Button>
                  <Button
                    className={classNames({ active: this.state.untranslated })}
                    value="untranslated"
                  >
                    Untranslated
                  </Button>
                  <Button
                    className={classNames({ active: this.state.pending })}
                    value="pending"
                  >
                    Pending Sync
                  </Button>
                  <Button
                    className={classNames({ active: this.state.edited })}
                    value="edited"
                  >
                    Edited
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
              {!isProd() && (
                <div className="d-inline-block ml-1 mt-3 mb-3 ">
                  <button className="btn btn-primary" onClick={this.onCreate}>
                    Add New
                  </button>
                </div>
              )}
              <LocalizationTableComponent
                onEdit={this.onEdit}
                localizations={this.filterPending(localizations)}
              />
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    localization: state.localization,
    loading: state.localization.loading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLocalizations: (val, untranslated) => {
      return dispatch(getLocalizations(val, true, true, untranslated))
    },
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LocalizationContainer)
)
