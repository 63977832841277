import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserInfo as getUserInfoAction } from './actions/auth'
import { Route, Switch } from 'react-router-dom'
import AuthContainer from './containers/auth-container'
import UsersComponent from './components/users/users'
import AuditLogsComponent from './components/audit-logs/audit-logs'
import UserDetailsContainer from './containers/user-details-container'
import FeatureContainer from './containers/featureContainer'
import FeatureDetailContainer from './containers/featureDetailContainer'
import PageContainer from './containers/pageContainer'
import LocalizationContainer from './containers/localizationContainer'
import LocalizationDetailContainer from './containers/localizationDetailContainer'
import BannerContainer from './containers/bannerContainer'
import BannerDetailContainer from './containers/bannerDetailContainer'
import PromoCodesContainer from './containers/promoCodesContainer'
import CacheContainer from './containers/cacheContainer'
import MaintenanceContainer from './containers/maintenanceContainer'
import MenuContainer from './containers/menu-container'
import FreeAccountsContainer from './containers/freeAccountsContainer'
import PromosContainer from './containers/promoContainer'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'

function App() {
  const dispatch = useDispatch()
  const authToken = useSelector((state) => state.authToken)
  const userId = useSelector((state) => state.auth.userInfo.userId)

  useEffect(() => {
    if (authToken) {
      dispatch(getUserInfoAction(userId))
    }
  }, [authToken, dispatch, userId])

  if (!authToken) {
    return <AuthContainer />
  }

  return (
    <MenuContainer>
      <Switch>
        <Route exact path="/audit" component={AuditLogsComponent} />
        <Route exact path="/user/:id?" component={UserDetailsContainer} />
        <Route exact path="/features" component={FeatureContainer} />
        <Route exact path="/features/:id" component={FeatureDetailContainer} />
        <Route exact path="/pages" component={PageContainer} />
        <Route exact path="/localizations" component={LocalizationContainer} />
        <Route
          exact
          path="/localization/:id?"
          component={LocalizationDetailContainer}
        />
        <Route exact path="/banners" component={BannerContainer} />
        <Route exact path="/banner/:id?" component={BannerDetailContainer} />
        <Route exact path="/promo-codes" component={PromoCodesContainer} />
        <Route exact path="/cache" component={CacheContainer} />
        <Route exact path="/maintenance" component={MaintenanceContainer} />
        <Route exact path="/free-accounts" component={FreeAccountsContainer} />
        <Route exact path="/promos" component={PromosContainer} />
        <Route component={UsersComponent} />
      </Switch>
    </MenuContainer>
  )
}

export default App
