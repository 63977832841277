import { FEATURE_ACTIONS } from '../actions/feature'

let featureState = {
  features: [],
  users: [],
  loading: false,
  error: null,
}

const featureReducer = (state = featureState, action) => {
  switch (action.type) {
    case FEATURE_ACTIONS.RECEIVE_FEATURES:
    case FEATURE_ACTIONS.RECEIVE_USER_FEATURES:
      return {
        ...state,
        features: action.features,
        loading: false,
      }
    case FEATURE_ACTIONS.REQUEST_FEATURES:
    case FEATURE_ACTIONS.REQUEST_USER_FEATURES:
      return {
        ...state,
        features: [],
        loading: true,
      }
    case FEATURE_ACTIONS.ERROR_RECEIVING_FEATURES:
    case FEATURE_ACTIONS.ERROR_RECEIVING_USER_FEATURES:
      return {
        ...state,
        features: [],
        loading: false,
        error: action.error,
      }
    case FEATURE_ACTIONS.UPDATING_FEATURE:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case FEATURE_ACTIONS.ERROR_UPDATING_FEATURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    case FEATURE_ACTIONS.REQUEST_FEATURE_USERS:
    case FEATURE_ACTIONS.UPDATING_FEATURE_USERS:
      return {
        ...state,
        users: [],
        loading: true,
      }
    case FEATURE_ACTIONS.RECEIVE_FEATURE_USERS:
    case FEATURE_ACTIONS.UPDATED_FEATURE_USERS:
      return {
        ...state,
        users: action.users,
        loading: false,
      }
    case FEATURE_ACTIONS.ERROR_RECEIVING_FEATURE_USERS:
      return {
        ...state,
        users: [],
        loading: false,
        error: action.error,
      }
    case FEATURE_ACTIONS.ERROR_UPDATING_FEATURE_USERS:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}

export default featureReducer
