import storedReducer from '../helpers/storedReducer'

const authToken = (state = null, action) => {
  switch (action.type) {
    case 'LOGIN':
      return action.auth.token

    case 'LOGOUT':
      return null

    default:
      return state
  }
}

export default storedReducer(authToken, 'authToken')
