import React, { useCallback } from 'react'
import DataGrid, { Column, Scrolling } from 'devextreme-react/data-grid'
import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import { AVAILABLE_REGIONS_SHORT } from '../../helpers/utils'

const renderBannerImage = (data) => (
  <img alt={data.value} src={data.value} height={50} width={'auto'} />
)

const regionColumns = Object.keys(AVAILABLE_REGIONS_SHORT).map((region) => (
  <Column
    alignment="center"
    calculateCellValue={(d) =>
      d.regions && d.regions.indexOf(parseInt(region, 10)) >= 0
        ? AVAILABLE_REGIONS_SHORT[region]
        : ''
    }
    key={region}
    width="3%"
  />
))

export function BannerTable({ banners, onEdit }) {
  const getDataSource = useCallback(() => {
    return new DataSource({
      store: new ArrayStore({
        type: 'array',
        key: 'baid',
        data: banners,
      }),
      reshapeOnPush: true,
    })
  }, [banners])

  const handleOnEdit = useCallback(
    (event) => {
      onEdit(event.row.data.baid)
    },
    [onEdit]
  )

  return (
    <>
      <h4>Banners</h4>
      <DataGrid
        dataSource={getDataSource()}
        repaintChangesOnly
        columnAutoWidth={false}
        showBorders
      >
        <Scrolling mode={'infinite'} />
        <Column dataField={'baid'} caption={'ID'} width="7%" sortOrder="desc" />
        <Column
          alignment="center"
          dataField={'webAction.assetUrl'}
          caption={'Web Image'}
          cellRender={renderBannerImage}
        />
        <Column
          dataField={'webAction.active'}
          caption={'Web Active'}
          width="10%"
        />
        <Column
          alignment="center"
          dataField={'mobileAction.assetUrl'}
          caption={'Mobile Image'}
          cellRender={renderBannerImage}
        />
        <Column
          dataField={'mobileAction.active'}
          caption={'Mobile Active'}
          width="10%"
        />
        <Column
          alignment="center"
          dataField={'webAction.altTag'}
          caption={'Alt tag'}
          width="10%"
        />
        {regionColumns}

        <Column
          type={'buttons'}
          buttons={[
            {
              hint: 'Edit',
              text: 'Edit',
              onClick: handleOnEdit,
            },
          ]}
        />
      </DataGrid>
    </>
  )
}
