import uniqBy from 'lodash/uniqBy'
import { SECURITY_GROUP_ACTIONS } from '../actions/security_groups'

const securityGroupState = {
  all: [],
  byUser: {},
  loading: false,
  error: null,
}

const securityGroupReducer = (state = securityGroupState, action) => {
  switch (action.type) {
    case SECURITY_GROUP_ACTIONS.REQUEST_SECURITY_GROUPS:
    case SECURITY_GROUP_ACTIONS.REQUEST_USER_SECURITY_GROUPS:
    case SECURITY_GROUP_ACTIONS.REQUEST_USER_SECURITY_GROUP_CREATE:
    case SECURITY_GROUP_ACTIONS.REQUEST_USER_SECURITY_GROUP_DELETE:
      return {
        ...state,
        loading: true,
      }

    case SECURITY_GROUP_ACTIONS.RECEIVE_SECURITY_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        all: action.securityGroups,
      }

    case SECURITY_GROUP_ACTIONS.RECEIVE_USER_SECURITY_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        byUser: {
          ...state.byUser,
          [action.userId]: action.userSecurityGroups,
        },
      }

    case SECURITY_GROUP_ACTIONS.RECEIVE_USER_SECURITY_GROUP_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        byUser: {
          ...state.byUser,
          [action.userId]:
            state.byUser[action.userId] &&
            uniqBy(
              [...state.byUser[action.userId], ...action.securityGroup],
              'sgid'
            ),
        },
      }

    case SECURITY_GROUP_ACTIONS.RECEIVE_USER_SECURITY_GROUP_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        byUser: {
          ...state.byUser,
          [action.userId]:
            state.byUser[action.userId] &&
            state.byUser[action.userId].filter(
              ({ sgid }) => sgid !== action.securityGroupId
            ),
        },
      }

    case SECURITY_GROUP_ACTIONS.RECEIVE_SECURITY_GROUPS_ERROR:
    case SECURITY_GROUP_ACTIONS.RECEIVE_USER_SECURITY_GROUPS_ERROR:
    case SECURITY_GROUP_ACTIONS.REQUEST_USER_SECURITY_GROUP_CREATE_ERROR:
    case SECURITY_GROUP_ACTIONS.REQUEST_USER_SECURITY_GROUP_DELETE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      }

    default:
      return state
  }
}

export default securityGroupReducer
