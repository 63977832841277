import { FREE_ACCOUNT_ACTIONS } from '../actions/freeAccounts'
import * as _ from 'lodash'

let freeAccountsState = {
  data: {},
  metadata: null,
  error: null,
  isFetching: false,
  filters: {},
}

const freeAccountReducer = (state = freeAccountsState, action) => {
  switch (action.type) {
    case FREE_ACCOUNT_ACTIONS.REQUEST_FREE_ACCOUNTS:
      return {
        ...state,
        isFetching: true,
      }
    case FREE_ACCOUNT_ACTIONS.RECEIVE_FREE_ACCOUNTS:
      return {
        ...state,
        isFetching: false,
        data: _.keyBy(action.payload, 'customerId'),
      }
    case FREE_ACCOUNT_ACTIONS.UPDATED_FREE_ACCOUNT:
      return {
        ...state,
        isFetching: false,
        data: _.keyBy(action.payload, 'customerId'),
      }
    default:
      return state
  }
}
export default freeAccountReducer
