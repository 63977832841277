import {
  fetchWithHeaders,
  getLoadOptionParameters,
  parseResponse,
} from '../helpers/utils'

export const getFeatures = () => {
  return fetchWithHeaders('features', {
    method: 'GET',
  }).then(parseResponse)
}

export const updateFeature = (featureId, enabledForEveryone) => {
  return fetchWithHeaders(`features/${featureId}`, {
    method: 'PUT',
    body: JSON.stringify({
      enabledForEveryone,
    }),
  }).then(parseResponse)
}

export const getFeatureUsers = (featureId, params) => {
  return fetchWithHeaders(
    `features/${featureId}/users?${getLoadOptionParameters(params)}`,
    {
      method: 'GET',
    }
  )
    .then(parseResponse)
    .then((data) => {
      return {
        data: data.users,
        totalCount: data.metadata[0].totalCount,
      }
    })
}

export const updateFeatureUsers = (featureId, userIds, replaceAll) => {
  return fetchWithHeaders(`features/${featureId}/users`, {
    method: 'PUT',
    body: JSON.stringify({
      userIds,
      replaceAll,
    }),
  }).then(parseResponse)
}

export const getUsersFeatures = (userId) => {
  return fetchWithHeaders(`users/${userId}/features`, {
    method: 'GET',
  }).then(parseResponse)
}

export const updateUserFeatures = (userId, featureIds) => {
  return fetchWithHeaders(`users/${userId}/features`, {
    method: 'PUT',
    body: JSON.stringify({
      features: featureIds,
    }),
  }).then(parseResponse)
}

export const toggleUserFeature = (userId, featureId, enabled) => {
  return fetchWithHeaders(`users/${userId}/features/${featureId}`, {
    method: 'PUT',
    body: JSON.stringify({
      enabled,
    }),
  })
}
