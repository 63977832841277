import { DOWNLOAD_HISTORY_ACTIONS } from '../actions/download-history'

const defaultState = {
  tracks: [],
  pagination: {
    page: 1,
    pageSize: 30,
    totalItems: 0,
  },
  error: null,
  isFetching: false,
}

const downloadHistoryReducer = (state = defaultState, action) => {
  switch (action.type) {
    case DOWNLOAD_HISTORY_ACTIONS.REQUEST_DOWNLOAD_HISTORY:
    case DOWNLOAD_HISTORY_ACTIONS.REQUEST_RESET_DOWNLOAD_COUNT:
      return {
        ...state,
        isFetching: true,
      }

    case DOWNLOAD_HISTORY_ACTIONS.RECEIVE_DOWNLOAD_HISTORY:
      return {
        ...defaultState,
        tracks: action.payload.tracks,
        pagination: action.payload.metadata,
      }

    case DOWNLOAD_HISTORY_ACTIONS.ERROR_RECEIVING_DOWNLOAD_HISTORY:
    case DOWNLOAD_HISTORY_ACTIONS.ERROR_RESETTING_DOWNLOAD_COUNT:
      return {
        ...defaultState,
        error: action.payload,
      }

    case DOWNLOAD_HISTORY_ACTIONS.RECEIVE_RESET_DOWNLOAD_COUNT:
      return {
        ...state,
        isFetching: false,
      }

    default:
      return state
  }
}

export default downloadHistoryReducer
