import React from 'react'
import './user-details.css'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.compact.css'
import 'devextreme/data/odata/store'
import DataGrid, {
  Column,
  Scrolling,
  LoadPanel,
} from 'devextreme-react/data-grid'
import CustomStore from 'devextreme/data/custom_store'
import 'whatwg-fetch'
import { fetchWithHeaders, parseResponse } from '../../helpers/utils'
import PropTypes from 'prop-types'
import moment from 'moment'

const cellRender = (data) => `${data.value === null ? '$0.00' : data.value}`

class AuditHistoryComponent extends React.Component {
  constructor(props) {
    super(props)
    this.getAuditHistory = this.getAuditHistory.bind(this)
  }

  getAuditHistory(user) {
    return {
      store: new CustomStore({
        load: function () {
          return fetchWithHeaders(`audit?user=${user}`, {
            method: 'GET',
          })
            .then(parseResponse)
            .then((data) => {
              return {
                data: data.log,
              }
            })
            .catch((e) => {
              throw new Error(`Data Loading Error: ${e.message}`)
            })
        },
      }),
    }
  }

  render() {
    let { user } = this.props
    return (
      <div>
        {user.user.userId && (
          <div>
            <DataGrid
              dataSource={this.getAuditHistory(user.user.userId)}
              showBorders={true}
            >
              <Column
                dataField={'date'}
                dataType={'date'}
                calculateDisplayValue={(p) =>
                  moment.utc(p.date).format('YYYY/MM/DD')
                }
              />
              <Column dataField={'adminEmail'} />
              <Column dataField={'action'} />
              <Column
                dataField={'amount'}
                dataType={'currency'}
                cellRender={cellRender}
              />
              <Scrolling mode={'virtual'} />
              <LoadPanel enabled={true} />
            </DataGrid>
          </div>
        )}
      </div>
    )
  }
}

AuditHistoryComponent.propTypes = {
  user: PropTypes.object,
}

export default AuditHistoryComponent
